/**********************************************************************************************/
/***** SLIDERS ********************************************************************************/
/**********************************************************************************************/
#FeaturedInsights {
    overflow:hidden;
    
    .content_section_inner {
        padding:0;
    }
}

#hero .hero_slider {
    position:relative;
    opacity:0;
    visibility:hidden;
    transition:opacity 0.3s;
    
    &.slick-initialized {
        visibility:visible;
        opacity:1;
    }
    
    .slide_inner {
        max-width:100% !important;
        background-color:$gray;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;
    }
    
    * {
        box-sizing:border-box;
    }
    
    .slick-list {
        margin:0;
        padding:0 !important;
        overflow:hidden !important;
    }
    
    .slick-slide {
        position:relative;
        z-index:1000;
        margin:0;
        padding:0;
        width:100%;
        //background-color:$gray;
        overflow:hidden;
    }
    
    .overlay {
        z-index:1040;
    }
    
    #vid_wrap {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        overflow:hidden;
        z-index:1020;
    }
    
    iframe {
        width: 100vw !important;
        height: 56.25vw;
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
      
    .bg_img {
        z-index:1010;
        
        img {
            object-fit:cover;
            max-width:none;
            height:100%;
        }
    }
    
    .inner_content {
        padding-top:15em;
        padding-bottom:6em;
        z-index:1050;
    }
}

.img_slider {
    margin:0 !important;
    padding:0 !important;
    list-style:none !important;
    visibility:hidden;
    transition:opacity 0.3s;

    &.slick-initialized {
        visibility:visible;
        opacity:1;
    }
        
    * {
        box-sizing:border-box;
    }

    .slick-list {
        margin:0;
        padding:0 !important;
        overflow:hidden !important;
        clip-path: none;
    }

    .slick-slide {
        margin:0;
        height:100%;
    }

    .slick-prev {
        left:0;
        display:block !important;
        margin-top:-30px;
    }

    .slick-next {
        right:0;
        background:rgba(255,255,255,0.1);
        margin-top:-30px;
        
        &:hover {
            background:rgba(255,255,255,1);
            
            &:before {
                filter: none;
            }
        }
    
        &:before {
            filter: brightness(0) invert(1);
        }
    }
    
    li {
        
    }
    
    .slide_inner {
        position:relative;
        margin:0 0 60px;
        /*background-color:transparent;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;*/
        z-index:10;
        
        img {
            display:block;
            width:100%;
        }
    }
    
    .caption {
        position:absolute;
        left:0;
        top:100%;
        display:inline-block;
        margin:0;
    }
}

.card_slider {
    margin:0 0 0 -20px;
    opacity:0;
    visibility:hidden;
    transition:opacity 0.3s;

    &.slick-initialized {
        visibility:visible;
        opacity:1;
    }
    
    * {
        box-sizing:border-box;
    }
    
    .slick-list {
        margin:0 -15px;
        padding:0 15px !important;
        overflow:visible !important;
        clip-path: inset( -100vw -100vw -100vw 20px );
    }
    
    .slick-slide {
        position:relative;
        z-index:1000;
        margin:0 20px 0;
        padding:0;
        width:100%;
        box-sizing:border-box;
        opacity:0.3;
        filter:grayscale(100%);
        transition:opacity 0.3s, filter 0.3s;
        
        &.slick-active {
            
        }
        
        &.slick-active,
        &.slick-current {
            opacity:1;
            filter:grayscale(0);
        }
    }
    
    .slick-prev {
        left:-78px;
        display:none !important;
    }
    
    .slick-next {
        right:-78px;
        background:rgba(255,255,255,1);
        
        &:before {
            filter: none;
        }
    }
    
    &.ins {
        
        
        li {
            
        }
        
        .wrap_link {
            display:block;
            height:100%;
            text-decoration:none;
            
            &:hover {
                
                .heading {
                    color:$green_dark;
                }
                
                .img > div {
                    transform:scale(1.05);
                }
                
                span.btn {
                    background:$green_dark !important;
                    color:$white !important;
                    
                    &:before {
                        filter:brightness(0) invert(1);
                    }
                }
            }
        }
        
        .slide_inner {
            display:flex;
            justify-content:flex-start;
            align-items:flex-start;
            height:100%;
        }
        
        .img {
            align-self:stretch;
            flex:0 0 31.6%;
            overflow:hidden;
            
            > div {       
                height:100%;     
                background-color:$green;
                background-repeat:no-repeat;
                background-position:center top;
                background-size:cover;
                transition:all 0.3s;
            }
        }
        
        .cont {
            flex:0 0 68.4%;
            align-self:stretch;
            background:$gray_light;
            padding:76px 40px 40px;
        }
        
        .heading {
            margin:0 0 1.5em;
            font-family:$roboto_cond;
            font-weight:$bold;
            font-size:1.58em;
            line-height:1.17em;
            color:$gray;
            transition:all 0.3s;
        }
        
        .meta {
            margin:0 0 1.5em;
            color:$gray;
            font-family:$roboto_cond;
            font-size:1.05em;
        }
        
        span.btn {
            margin:0;
            color:$gray !important;
            background:none !important;
            
            &:before {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23323e48' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
            }
            
            &:hover {
                color:$gray !important;
                background:none !important;
            }
        }
    }
    
    &.cs {
        //margin:0;
    
        * {
            box-sizing:border-box;
        }
    
        .slick-list {
            margin:0;
            padding:0 10px !important;
            overflow:visible !important;
            clip-path: none;
        }
    
        .slick-slide {
            margin:0 10px 0;
        }
    
        .slick-prev {
            left:20px;
            display:block !important;
            margin-top:-30px;
        }
    
        .slick-next {
            right:20px;
            background:rgba(255,255,255,0.1);
            margin-top:-30px;
            
            &:hover {
                background:rgba(255,255,255,1);
                
                &:before {
                    filter: none;
                }
            }
        
            &:before {
                filter: brightness(0) invert(1);
            }
        }
        
        li {
            
        }
        
        .wrap_link {
            display:block;
            height:100%;
            text-decoration:none;
            
            &:hover {
                
                
                
                span.btn {
                    background:$green !important;
                    color:$white !important;
                    
                    &:before {
                        filter:brightness(0) invert(1);
                    }
                }
            }
        }
        
        .slide_inner {
            position:relative;
            margin:0 0 60px;
            background-color:transparent;
            background-repeat:no-repeat;
            background-position:center center;
            background-size:cover;
            z-index:10;
        }
        
        .overlay {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            background: -moz-linear-gradient(bottom,  rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.05) 100%);
            background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0.05) 100%);
            background: linear-gradient(to top,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0.05) 100%);
            z-index:-1;
        }
        
        .inner_content {
            padding:20em 48px 40px;
        }
        
        .heading {
            margin:0 0 0.25em;
            font-family:$roboto_cond;
            font-weight:$bold;
            font-size:1.05em;
            line-height:1.17em;
            color:$white;
        }
        
        p {
            margin:0;
            font-family:$roboto_cond;
            font-weight:$normal;
            font-size:0.95em;
            color:$white;
        }
        
        span.btn {
            position:absolute;
            right:0;
            top:100%;
            margin:0;
            
            color:$green !important;
            background:none !important;
            
            &:before {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e") !important;
                filter:none;
            }
            
            &:hover {
                color:$green !important;
                background:none !important;
            }
        }
    }
    
    &.ins_landing {
        margin:0;
    
        * {
            box-sizing:border-box;
        }
    
        .slick-list {
            margin:0;
            padding:0 10px !important;
            overflow:visible !important;
            clip-path: none;
        }
    
        .slick-slide {
            margin:0 10px 0;
        }
    
        .slick-prev {
            left:20px;
            display:block !important;
            margin-top:0;
        }
    
        .slick-next {
            right:20px;
            background:rgba(255,255,255,0.1);
            margin-top:0;
            
            &:hover {
                background:rgba(255,255,255,1);
                
                &:before {
                    filter: none;
                }
            }
        
            &:before {
                filter: brightness(0) invert(1);
            }
        }
        
        .bg_img {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            overflow:hidden;
            
            img {
                object-fit:cover;
                min-height:100%;
                min-width:100%;
                max-width:100%;
            }
        }
        
        .overlay {
            position:absolute;
            top:0;
            right:0;
            bottom:-1px;
            left:0;
            background: -moz-linear-gradient(bottom,  rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.15) 100%);
            background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.15) 100%);
            background: linear-gradient(to top,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.15) 100%);
        }
        
        .inner_content {
            position:relative;
            padding-top:12em;
            padding-bottom:6em;
            width:60%;
            margin-left:20%;
            z-index:12;
        }
        
        .heading {
            font-family:$roboto_cond;
            font-size:1.84em;
            line-height:1.15em;
            color:$white;
            font-weight:$bold;
        }
        
        .crumb {
            font-family:$roboto_cond;
            font-size:0.95em;
            line-height:1.15em;
            color:$white;
            font-weight:$bold;
        }
        
        .btn {
            margin:0 20px 0 0;
            text-transform:none;
            font-family:$roboto_cond;
            color:$green !important;
            background:$white !important;
            
            &:before {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e") !important;
                filter:none;
                transform:translateY(-70%);
            }
            
            &:hover {
                color:$white !important;
                background:$green_dark !important;
                
                &:before {
                    filter:brightness(0) invert(1);
                }
            }
        }        
    }
    
    &.testimonial {
                
        .slick-list {
            overflow:hidden !important;
            clip-path: inset( -100vw -100vw -100vw 40px );
        }
    
        .slick-slide {
            margin:0 20px 0;
            opacity:1;
            filter:none;
        }
    
        .slick-prev {
            left:-52px;
            display:block !important;
            margin-top:-30px;
        }
    
        .slick-next {
            right:-58px;
            background:rgba(255,255,255,0.1);
            margin-top:-30px;
            
            &:hover {
                background:rgba(255,255,255,1);
                
                &:before {
                    filter: none;
                }
            }
        
            &:before {
                filter: brightness(0) invert(1);
            }
        }
        
        .wrap_link {
            display:block;
            height:100%;
            text-decoration:none;
            
            &:hover {
                
                
                
                span.btn {
                    background:$green_dark !important;
                    color:$white !important;
                    
                    &:before {
                        filter:brightness(0) invert(1);
                    }
                }
            }
        }
        
        .slide_inner {
            position:relative;
            margin:0 0 60px;
            background-color:transparent;
            background-repeat:no-repeat;
            background-position:center top;
            background-size:cover;
            z-index:10;
        }
        
        .overlay {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            background:rgba(0,0,0,0.7);
            z-index:-1;
        }
        
        .inner_content {
            display:flex;
            justify-content:flex-start;
            align-items:flex-start;
            padding:44px 40px 100px;
            height:100%;
            
            &.has_logo {
                padding:44px 32px 100px;
                
                .quote {
                    flex:0 0 calc(100% - 64px);
                    margin-left:20px;
                }
            }
        }
        
        .client_logo {
            flex:0 0 44px;
            align-self:stretch;
            background-color:transparent;
            background-repeat:no-repeat;
            background-position:center top;
            background-size:100% auto;
        }
        
        .quote {
            flex:0 0 100%;
        }
        
        blockquote {
            margin:0 0 1.26em;
            font-family:$roboto;
            font-size:0.95em;
            line-height:1.55em;
            font-weight:$normal;
        }
        
        .name {
            margin:0 0 0.25em;
            font-family:$roboto_cond;
            font-size:1.05em;
            font-weight:$bold;
        }
        
        .info {
            margin:0;
            font-family:$roboto_cond;
            font-size:0.84em;
            font-weight:$normal;
        }
        
        span.btn {
            position:absolute;
            right:0;
            top:100%;
            margin:0;
            
            color:$green !important;
            background:none !important;
            
            &:before {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e") !important;
                filter:none;
            }
            
            &:hover {
                color:$white !important;
                background:none !important;
            }
        }
        
    }
    
    &.ins_landing {
        
    }
}

@media only screen and (max-width: #{$bp_small}) {
    
    #FeaturedInsights {
        
        .content_section_inner {
            padding:0 0 6em;
        }
    }
    
    #hero .hero_slider {
        
        .slick-prev {
            left:20px;
            top:calc(100% + 40px);
            display:block !important;
            margin-top:0;
        }

        .slick-next {
            right:20px;
            top:calc(100% + 40px);
            background:rgba(255,255,255,0.1);
            margin-top:0;
        
            &:hover {
                background:rgba(255,255,255,1);
            
                &:before {
                    filter: none;
                }
            }
    
            &:before {
                filter: brightness(0) invert(1);
            }
        }
        
        iframe {
            height: 100%;
            min-height: 0;
        }
        
        .inner_content {
            padding:4em 2em 2em;
            
            .heading {
                font-size:2em;
            }
        }
    }
    
    .card_slider {
        margin:0 -20px;
        
        &.ins,
        &.cs,
        &.testimonial {
            
            .slick-prev {
                left:20px;
                top:calc(100% + 40px);
                display:block !important;
                margin-top:0;
            }
    
            .slick-next {
                right:20px;
                top:calc(100% + 40px);
                background:rgba(255,255,255,1);
                margin-top:0;
                
                &:before {
                    filter: none;
                }
            
                &:hover {
                    background:rgba(255,255,255,1);
                
                    &:before {
                        filter: none;
                    }
                }
            }
        }
        
        &.ins {
            
            
            .slick-prev {
                top:calc(100% + 50px);
            }
    
            .slick-next {
                top:calc(100% + 50px);
            }
            
            .wrap_link {
                
                &:hover {
                
                    span.btn {
                        background:$green !important;
                        color:$white !important;
                    
                        &:before {
                            filter:none;
                        }
                    }
                }
            }
            
            .slide_inner {
                display:block;
            }
        
            .img {
                
                
                > div {      
                    height:0;
                    padding-bottom:60%; 
                    background-position:center center;
                }
            }
        
            .cont {
                flex:0 0 40%;
                padding:50px 20px 20px;
            }
            
            span.btn {
                color:$white !important;
                background:$green !important;
            
                &:before {
                    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23ffffff' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e") !important;
                }
            
                &:hover {
                    color:$white !important;
                    background:$green !important;
                }
            }
        }
        
        &.cs {
            
            .inner_content {
                padding:10em 30px 20px;
            }
        }
        
        &.testimonial {
            
        }
        
        &.ins_landing {
            
            .slick-prev {
                top:calc(100% + 50px);
            }
    
            .slick-next {
                top:calc(100% + 50px);
            }
            
            .bg_img {
                
                img {
                    max-width:none;
                    max-height:100%;
                }
            }
            
            .inner_content {
                width:100%;
                margin:0;
                padding:4em 2em 2em;
            
                .heading {
                    font-size:2em;
                }
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #hero .hero_slider {
        
        .inner_content {
            padding:8em 0 2em;
            
            .heading {
                font-size:2.5em;
            }
        }
    }
    
    .card_slider {
        
        &.ins {
            
            .slide_inner {
                display:block;
            }
            
            .cont {
                padding:50px 30px 30px;
            }
        
            .img {
                
                > div {      
                    height:0;
                    padding-bottom:60%; 
                    background-position:center center;
                }
            }
        }
        
        &.ins_landing {
            
            .bg_img {
                
                img {
                    max-width:none;
                    max-height:100%;
                }
            }
            
            .inner_content {
                width:70%;
                margin-left:15%;
                padding:8em 0 4em;
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}