/**********************************************************************************************/
/***** PORTFOLIO - LANDING PAGES, GRIDS *******************************************************/
/**********************************************************************************************/
.page-template-page-portfolio,
.archive.tax-project-type,
.archive.tax-project-industry {
    
    
    
    .title_wrap {
        display:flex;
        justify-content:space-between;
        align-items:flex-end;
        margin:0 0 3em;
        padding:60px 0 18px;
        border-bottom:1px solid $white;
    }
    
    h1 {
        flex:0 0 auto;
    }
    
    #projects_grid_filters {
        flex:0 0 auto;
        margin-bottom:3px;
        
        .projects_grid_sort_button,
        .thru_link {
            display:inline-block;
            margin:0 0 0 50px;
            font-family:$roboto_cond;
            font-weight:$normal;
            font-size:0.95em;
            text-decoration:none;
            text-transform:uppercase;
            letter-spacing:2px;
            opacity:0.75;
            transition:opacity 0.3s;
            
            &:first-of-type {
                margin-left:0;
            }
            
            &:hover,
            &.is_checked {
                font-weight:$bold;
                opacity:1;
            }
        }
        
        .client_label {
            display:inline-block;
            margin:0;
            font-family:$roboto_cond;
            font-weight:$normal;
            font-size:0.95em;
            text-decoration:none;
            text-transform:uppercase;
            letter-spacing:2px;
        }
    }
}

.archive.tax-project-type,
.archive.tax-project-industry {

    .content_section_inner {
        position:relative;
    }
    
    .crumb {
        
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    .page-template-page-portfolio,
    .archive.tax-project-type,
    .archive.tax-project-industry {
    
        .title_wrap {
            flex-wrap:wrap;
        }
    
        h1 {
            flex:0 0 100%;
            padding-bottom:1em;
            text-align:center;
        }
    
        #projects_grid_filters {
            flex:0 0 100%;
            text-align:center;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    .page-template-page-portfolio,
    .archive.tax-project-type,
    .archive.tax-project-industry {
    
        #projects_grid_filters {
            
            .projects_grid_sort_button,
            .thru_link {
                margin:0 0 0 25px;
                font-size:0.85em;
            }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .page-template-page-portfolio,
    .archive.tax-project-type,
    .archive.tax-project-industry {
    
        #projects_grid_filters {
            
            .projects_grid_sort_button,
            .thru_link {
                margin:0 0 0 25px;
                font-size:0.85em;
            }
        
            .client_label {
                font-size:0.85em;
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}


/**********************************************************************************************/
/***** PORTFOLIO - INDUSTRY/TYPE TAXONOMY GRIDS ***********************************************/
/**********************************************************************************************/
#portfolio_wrap {
    
    &.no_featured {
        
        .featured_projects {
            display:none;
        }
    }
}

.featured_projects {
    display:block;
    margin-right:-20px;
    margin-left:-20px;
    margin-bottom:3em;
    
    * {
        box-sizing:border-box;
    }
    
    h2 {
        margin:0 20px 0 !important;
        font-size:32px;
        line-height:32px;
    }
    
    .gridblock {
        display:inline-block;
        //float:left;
        width:calc(50% - 40px);
        margin:20px;
        vertical-align:top;
        text-decoration:none;
    }
}

.projects_grid {
    margin-right:-20px;
    margin-left:-20px;
    
    * {
        box-sizing:border-box;
    }
    
    &:after {
        content: '';
        display: block;
        clear: both;
    }
    
    .gutter-sizer {
        width:4%;
    }
    
    .gridblock {
        display:inline-block;
        //float:left;
        width:calc(33.3333% - 40px);
        margin:20px;
        vertical-align:top;
        text-decoration:none;
    }
    
    .gridblock.tax_h {
        display:none;
        justify-content:flex-start;
        align-items:flex-end;
        width:calc(100% - 40px);
        margin:30px 20px 5px;
        padding:0;
        border:none;
        opacity:0;
        transition:opacity 0.2s;
        
        &:first-of-type {
            margin-top:0;
        }
        
        h2 {
            flex:0 0 calc(100% - 150px);
            margin-top:0 !important;
            margin-bottom:0;
            font-size:32px;
            line-height:32px;
        }
        
        .view_all {
            position:relative;
            flex:0 0 150px;
            width:150px;
            padding-right:24px;
            text-align:right;
            text-decoration:none;
            font-weight:400;
            font-size:18px;
            line-height:1em;
            transition:all 0.3s;
            text-transform:normal !important;
            letter-spacing:0 !important;
            color:$green_on_slate;
            
            &:before {
                position:absolute;
                right:0;
                top:50%;
                width:17px;
                height:13px;
                background:none;
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                transition:all 0.3s;
                transform:translateY(-65%) rotate(0);
                filter:none;
            }
            
            &:hover {
                color:$white;
                
                &:before {
                    filter:brightness(0) invert(1);
                }
            }
        }
    }
    
    &.type_sorted .gridblock,
    &.industry_sorted .gridblock {
        display:none;
    }
    
    &.type_sorted .gridblock.tax_h.p_type {
        display:flex;
        opacity:1;
    }
    
    &.industry_sorted .gridblock.tax_h.p_industry {
        display:flex;
        opacity:1;
    }
    
    .archive.tax-project-type &,
    .archive.tax-project-industry & {
    
        .gridblock.tax_h.p_type,
        .gridblock.tax_h.p_industry {
            display:flex;
            opacity:1;
        }
    }
    
    &.type_sorted .gridblock.keep_visible,
    &.industry_sorted .gridblock.keep_visible {
        display:inline-block !important;
    }
    
    &.type_sorted .gridblock.tax_h.keep_visible,
    &.industry_sorted .gridblock.tax_h.keep_visible {
        display:flex !important;
    }
}


@media screen and (max-width: #{$bp_small}) {
    
    .featured_projects {
        padding-top:60px;
    
        .gridblock {
            width:calc(100% - 40px);
        }
    }
    
    .projects_grid {
        
        .gridblock {
            width:calc(50% - 40px);
        }
        
        .gridblock.tax_h {
            margin-top:60px;
            align-items:center;
        }
    }
    
}

@media only screen and (max-width: 540px) {
    
    .featured_projects {
        
        h2 {
            font-size:26px;
            line-height:26px;
        }
    }
    
    .projects_grid {
        
        .gridblock {
            width:calc(100% - 40px);
        }
        
        .gridblock.tax_h {
            
            h2 {
                font-size:26px;
                line-height:26px;
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}


/**********************************************************************************************/
/***** PORTFOLIO - CLIENT-SPECIFIC CUSTOM PAGE TEMPLATE ***************************************/
/**********************************************************************************************/


@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}


/**********************************************************************************************/
/***** PROJECT - SINGLE/DETAIL ****************************************************************/
/**********************************************************************************************/
.single-project {
    
    
    article.type-project {
        
    }
    
    .project_details {
        margin:30px 0 0 15px;
        padding:0 0 0 18px;
        border-left:3px solid $green_on_slate;
        
        * {
            font-family: $roboto_cond;
        }
        
        .cs_link {
            display:inline-block;
            position:relative;
            margin:0.5em 0 -10px;
            padding:10px 46px 10px 0;
            color:$green_on_slate;
            line-height:1.1em;
            text-decoration:none;
            transition:all 0.3s;
            
            &:before {
                position:absolute;
                right:16px;
                top:46%;
                width:17px;
                height:13px;
                background:none;
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%2393AEA6' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                transition:all 0.3s;
                transform:translateY(-70%);
            }
            
            &:hover,
            &:focus {
                color:$white;
                
                &:before {
                    right:6px;
                    filter:brightness(0) invert(1);
                }
            }
        }
    }
    
    #related_projects {
        .content_section_inner {
            padding-top:140px;
            padding-bottom:140px;
        }
    }
    
    .rp4wp-related-posts {
        width:auto;
        overflow:visible;
        
        .rp4wp-posts-list {
            float:none;
            margin:0 0 0 -20px;
        }
    }
    
    .modal_gallery {
        width:100%;
        margin:3em 0;
        position: relative;
        padding:0;
        display: grid;
        grid-column-gap: ($gutter * 2);
        grid-row-gap: ($gutter * 2);
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: 1fr;
        grid-auto-rows: auto;

        .img {
            display:block;
            position: relative;
            object-fit: cover;
            overflow:hidden;
        
            .img_wrap {
                overflow:hidden;
            
                > div {
                    height:0;
                    padding-bottom:100%;
                    background-color:$gray_mid;
                    background-repeat:no-repeat;
                    background-position:center top;
                    background-size:cover;
                    transition:all 0.3s;
                }
            }

            .caption {
                margin-top:10px;
                text-align:left;
                color:$white;
                font-size:0.74em;
                line-height:1.2em;
                padding-right:30px;
                padding-left:15px;
                border-left:2px solid $green_on_slate;
                font-style:normal;
            }
        
            &.fw {
                grid-column: 1 / span 2;
            
                .img_wrap {
                
                    > div {
                        padding-bottom:50%;
                    }
                }
            }
            
            .play_btn {
                &:hover {
                    background:$green;
                }
            }
        
            &:hover {
                .img_wrap > div {
                    transform:scale(1.1);
                }
                
                .play_btn {
                    background:$green_dark;
                }
            }
        }
    }
    
    .project_industries {
        margin:0;
        padding:0.75em 0;
        border-top:3px solid $green_on_slate;
        font-family: $roboto_cond;
        font-weight: $normal;
        font-size:1.1em;
        color: $green_on_slate;
        
        span {
            display:inline-block;
            color: $green_on_slate;
        }
    }
}


@media screen and (max-width: #{$bp_small}) {
    
    .single-project {
        
        #related_projects {
            .content_section_inner {
                padding-top:4em;
                padding-bottom:8em;
            }
            
            .top_barred {
                
                h3 {
                    margin-bottom:3em;
                }
            }
        }
    
        .rp4wp-related-posts {
            width:auto;
            overflow:visible;
        
            .rp4wp-posts-list {
                width:auto;
                float:none;
                margin:0 -20px !important;
            }
        }
        
        .modal_gallery {
            width:100%;
            margin:3em 0;
            position: relative;
            padding:0;
            display: grid;
            grid-column-gap: ($gutter * 2);
            grid-row-gap: ($gutter * 2);
            grid-template-columns: repeat(2,1fr);
            grid-template-rows: 1fr;
            grid-auto-rows: auto;

            .img {
                display:block;
                position: relative;
                object-fit: cover;
                overflow:hidden;
        
                .img_wrap {
                    overflow:hidden;
            
                    > div {
                        height:0;
                        padding-bottom:100%;
                        background-color:$gray_mid;
                        background-repeat:no-repeat;
                        background-position:center top;
                        background-size:cover;
                        transition:all 0.3s;
                    }
                }

                .caption {
                    margin-top:5px;
                    text-align:left;
                    color:$gray;
                    font-size:0.74em;
                }
        
                &.fw {
                    grid-column: 1 / span 2;
            
                    .img_wrap {
                
                        > div {
                            padding-bottom:50%;
                        }
                    }
                }
            
                .play_btn {
                    &:hover {
                        background:$green;
                    }
                }
        
                &:hover {
                    .img_wrap > div {
                        transform:scale(1.1);
                    }
                
                    .play_btn {
                        background:$green_dark;
                    }
                }
            }
        }
    }
    
}

@media only screen and (max-width: 540px) {
    
    .single-project {
        
        
        .modal_gallery {
            grid-template-columns: repeat(1,1fr);
            grid-column-gap: 0;

            .img {
        
                &.fw {
                    grid-column: 1 / span 1;
            
                    .img_wrap {
                
                        > div {
                            padding-bottom:100%;
                        }
                    }
                }
            }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}