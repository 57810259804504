/**********************************************************************************************/
/***** GUTENBERG RESETS / STANDARD NAKED BLOCKS ***********************************************/
/**********************************************************************************************/

/* remove top margin from first heading element */
#content {
    .wp-block-columns {
        .wp-block-column {
            box-sizing:border-box;
        
            :first-child {
                margin-top:0;
            }
            
            &:not(:first-child) {
                //margin-left:5em;
            }
        }
    }
}

.default-page #content article {
    
    
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > p,
    > ol,
    > ul,
    > hr,
    > span,
    > a {
        width:calc(100% - 80px);
        max-width:1420px;
        margin-right:auto;
        margin-left:auto;
    }
    
    .wp-block-columns {
        display:flex;
        flex-wrap:nowrap;
        width:calc(100% - 80px);
        max-width:1420px;
        margin:0 auto;
        padding:0 0 60px 0;
        
        .wp-block-column {
            
            &:first-child {
                padding-right:10%;
            }
            
            &:not(:first-child) {
                margin-left:20px;
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    #content {
        .wp-block-columns {
            
            
            .wp-block-column {
                flex:0 0 100% !important;
        
                :first-child {
                    margin-top:0;
                }
            
                &:not(:first-child) {
                    margin-left:0;
                }
            }
            
            &.reverse_col_mobile {
                
                .wp-block-column {
                    &:last-child {
                        order:-1;
                    }
                }
            }
        }
    }
    
    .default-page #content article {
    
    
        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6,
        > p,
        > ol,
        > ul,
        > hr,
        > span,
        > a {
            width:calc(100% - 40px);
        }
    
        //MAX WIDTH FOR READABILITY, NAKED ELEMENTS
        > p,
        > ol,
        > ul,
        > hr,
        > span,
        > a {
            padding-right:0;
        }
    
        .wp-block-columns {
            display:block;
            width:calc(100% - 40px);
            padding:0 0 40px 0;
        
            .wp-block-column {
                
            
                &:first-child {
                    padding-right:0;
                }
            
                &:not(:first-child) {
                    margin-left:0;
                }
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #content {
        .wp-block-columns {
            .wp-block-column {
        
                :first-child {
                    margin-top:0;
                }
            
                &:not(:first-child) {
                    margin-left:2em;
                }
            }
        }
    }
    
    .default-page #content article {
        
        .wp-block-columns {
            display:block;
            width:calc(100% - 80px);
            padding:0 0 60px 0;
        
            .wp-block-column {
                
            
                &:first-child {
                    margin-bottom:40px;
                    padding-right:0;
                }
            
                &:not(:first-child) {
                    margin-left:0;
                }
            }
        }
    }
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}