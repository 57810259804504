/**********************************************************************************************/
/***** SITEWIDE PAGINATION STYLES *************************************************************/
/**********************************************************************************************/
//NATIVE PAGINATION
.paging-navigation {
    margin:2em 0 1em;
    text-align:center;
    
    #insight-list & {
        margin-left:-20px;
        margin-right:-20px;
    }
    
    .search-results & {
        margin:2em 0 2em;
    }
}

.pagination {
    position:relative;
    display:block;
	text-align:center;
	margin:0 auto;
	padding:0;
	list-style:none;
	z-index: 1;
    
    .page-numbers {
        display: inline-block;
        width:auto;
        height:30px;
        margin:0;
        padding:0 12px;
        box-sizing:border-box;
        color: $green;
        font-size:0.84em;
        line-height:30px;
        font-weight:$normal;
        border:none;
        text-decoration: none;
        transition: all 0.3s;
        
        .search-results & {
            color:$gray;
        }
                
        &:hover,
        &:focus {
            color: $white;
            
            .search-results & {
                color:$green;
            }
        }
        
        &:active {
            color: $white;
            font-weight:$bold;
            
            .search-results & {
                color:$green;
            }
        }
        
        &.current {
            color: $white;
            font-weight:$bold;
            cursor: default;
            
            .search-results & {
                color:$green;
            }
        }
        
        &.dots {
            background:none;
            box-shadow:none;
            padding-right:0;
            padding-left:0;
            width:20px;
            font-weight:$normal;
            text-decoration: none;
        }
        
        &.prev,
        &.next {
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            margin:0;
            padding:0 0 0 30px;
            font-size:0.84em;
            font-weight:$normal;
            color:$green;
            transition:none;
            
            .search-results & {
                color:$gray;
            }
            
            
            &:before,
            &:after {
                position:absolute;
                left:0;
                top:50%;
                width:17px;
                height:13px;
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                transform:translateY(10%) rotate(180deg);
                
                .search-results & {
                    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23323e48' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                }
            }
            
            &:hover {
                color:$white;
                
                &:before,
                &:after {
                    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                    filter:brightness(0) invert(1);
                }
                
                .search-results & {
                    color:$green;
                    
                    &:before,
                    &:after {
                        filter:none;
                    }
                }
            }
        }
        
        &.prev {
            left:0;
            
            &:after {
                display:none;
            }
        }
        
        &.next {
            right:0;
            padding:0 30px 0 0;
            
            &:before {
                display:none;
            }
            
            &:after {
                position:absolute;
                left:auto;
                right:0;
                transform:translateY(-100%) rotate(0deg);
            }
        }
    }
}




@media screen and (max-width: #{$bp_small}) {
    
    .paging-navigation {
        
        #insight-list & {
            margin-left:0;
            margin-right:0;
        }
    }
    
}

@media only screen and (max-width: 540px) {
    
    .pagination {
        
        .page-numbers {
            padding:0 8px;
        }
    }
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .paging-navigation {
        
        #insight-list & {
            margin-left:0;
            margin-right:0;
        }
    }
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}