/**********************************************************************************************/
/***** CONTENT TIMELINE PLUGIN - STYLE OVERRIDES/CUSTOMIZATION ********************************/
/**********************************************************************************************/
//POPUP DETAILS
.pp_pic_holder {
    .pp_details {
        display:none !important;
    }
}

.timeline,
#content .timeline {
    overflow:visible !important;
    
    //kill elements
    .image_roll_glass,
    .image_roll_zoom {
        display:none !important;
    }
    
    .t_line_view {
        //width:90% !important;
    }
    
    .timeline_line {
        //width:90% !important;
        
        #t_line_left,
        #t_line_right {
            top:38px !important;
            width:14px !important;
            height:24px !important;
            
            &:after {
                left:50% !important;
                top:50% !important;
                width:10px;
                height:17px;
                background:none;
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 24'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='2.1 24 0 21.9 9.8 12 0 2.1 2.1 0 14 12'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                transform:translate(-50%,-50%) rotate(180deg);
                transition:all 0.3s;
            }
            
            &:hover {
                &:after {
                    filter: brightness(0) invert(1);
                }
            }
        }
        
        #t_line_right {
            top:36px !important;
            &:after {
                transform:translate(-50%,-50%);
            }
        }
        
        .t_line_holder {
            background:none !important;
            
            &:before {
                position:absolute;
                left:0;
                top:49px;
                width:100%;
                height:1px;
                background:$green;
                content:'';
            }
        }
        
        .t_line_node {
            padding:2px 0 38px !important;
            color:$green !important;
            font-family:$roboto_cond !important;
            font-weight:$bold !important;
            font-size:16px !important;
            background:none !important;
            
            &:after {
                margin-left:-5px !important;
                border:1px solid $green !important;
                background:$gray_mid !important;
            }
            
            &.active {
                color:$white !important;
                
                &:after {
                    border:1px solid $white !important;
                    background:$white !important;
                }
            }
            
            &:hover {
                &:after {
                    border:1px solid $white !important;
                    background:$white !important;
                }
            }
        }
    }
    
    &.flatNav {
        
        
        &:hover {
            
            .t_left,
            .t_right {
                opacity:0.5;
                
                &:before {
                    background:rgba(255,255,255,1);
                }
                
                &:after {
                    filter:none;
                }
            }
        }
        
        .t_left,
        .t_right {
            //left:10%;
            width:58px !important;
            opacity:1 !important;
            background:none !important;
            //transition:none;
            
            &:before {
                position:absolute;
                left:50%;
                top:50%;
                height: 58px;
                width: 58px;
                border:none;
                line-height: 0px;
                font-size: 0px;
                cursor: pointer;
                background:rgba(255,255,255,0.1);
                color: transparent;
                top: 50%;
                transform: translate(-50%, -50%);
                padding: 0;
                outline: none;
                content:'';
                transition:all 0.3s;
            }
            
            &:after {
                position:absolute;
                left:50%;
                top:50%;
                width:14px;
                height:24px;
                background:none;
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 24'%3e%3cg fill='%234B5762' %3e%3cpolygon points='2.1 24 0 21.9 9.8 12 0 2.1 2.1 0 14 12'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                transition:all 0.3s;
                transform:translate(-50%,-50%);
                filter: brightness(0) invert(1);
            }
        }
        
        .t_left {
            
            
            &:after {
                transform:translate(-50%,-50%) rotate(180deg);
            }
        }
        
        .t_right {
            //left:auto;
            //right:10%;
            
        }
    }
    
    .item {
        opacity:0.4;
        transition:opacity 0.3s !important;
        background:$gray_mid !important;
        box-shadow:none !important;
        color:$white !important;
        text-align:left !important;
        
        &:before,
        &:after { //this is the top blue border + notch element on hover upper year dots
            display:none !important;
        }
        
        &:hover,
        &.item_node_hover {
            opacity:1;
        }
        
        .con_borderImage {
            border:none !important;
            height:0 !important;
            padding-bottom:75% !important;
            
            img {
                width:100% !important;
                height:100% !important;
                object-fit:cover !important;
            }
        }
        
        h2 {
            margin:10px 10px 0.5em !important;
            color:$white !important;
            font-family:$roboto_cond !important;
            font-size:18px !important;
            line-height:1.3em !important;
            font-weight:$bold !important;
            text-align:left !important;
        }
        
        > span {
            display:inline-block !important;
            margin:0 10px 10px !important;
            font-family:$roboto_cond !important;
            font-size:18px !important;
            line-height:1.3em !important;
            text-align:left !important;
        }
    }
}

@media screen and (max-width: #{$bp_small}) {

    .timeline,
    #content .timeline {
        .t_line_view {
            //width:440px !important;
        }
    
        .timeline_line {
            //width:220px !important;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}