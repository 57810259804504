/**********************************************************************************************/
/***** CUSTOM GUTENBERG BLOCK: ACCORDION ******************************************************/
/**********************************************************************************************/
.block_accordion {
    width:calc(100% + 100px);
    margin:2em -50px;
    
    .block_inner {
        
    }
    
    .acc_wrap {
        padding:0;
        margin-right:0;
        width:100%;
    
        .acc_list {
            border-bottom:1px solid $gray;
            
            .page & {
                border-bottom:1px solid $green;
            }
        }
    
        .acc_head {
            border-top:1px solid $gray;
            
            .page & {
                border-top:1px solid $green;
            }
        
            &.active {
                background:none;
            
                .toggle {
                
                    svg .select_caret {
                        fill:$gray;
                        
                        .page & {
                            fill:$white;
                        }
                    }
                }
            }
        
            h5 {
                color:$gray;
                
                .page & {
                    color:$white;
                }
            }
        
            .toggle {
                
                svg {
                    .select_caret {
                        fill:$gray;
                        
                        .page & {
                            fill:$green;
                        }
                    }
                }
            }
        }
    
        .acc_cont {
            border-top:1px solid $gray;
            background:$white;
            
            .page & {
                border-top:1px solid $green;
                background:$gray;
            }
        
            .inner {
                border:none;
            
                * {
                    color:$gray;
                    
                    .page & {
                        color:$white;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {

    .block_accordion {
        width:100%;
        margin:2em 0;
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}