/**********************************************************************************************/
/***** FORM STYLES ****************************************************************************/
/**********************************************************************************************/
#content {
    
    .csection .gform_wrapper {
        margin-top:2em;
    }
    
    .gform_wrapper {
        margin:0;
        padding:0;
    
        * {
            box-sizing:border-box;
        }
    
        .gform_fields {
            margin:0 !important;
            padding:0 !important;
            list-style:none !important;
            grid-column-gap: 9%;
        
            li,
            .gfield {
                position:relative;
                width:100%;
                margin:1.25em 0 0;
                padding:0;
                vertical-align:top;
                text-align:left;
                list-style:none !important;
                z-index:100;
                
                &:first-of-type {
                    //margin-top:0;
                }
                
                &.no_float {
                
                    .gfield_label {
                        position:static;
                    }
                }
            
                &.msg {
                    margin-top:20px;
        
                    label,
                    .gfield_label {
                        left:0;
                        width:100%;
                    }
                }
                
                &.gfield_html {
                    margin-bottom:1.25em;
                    margin-top:0;
                }
                
                &.gfield--type-captcha {
                    margin:0;
                }
                
                &.gfield--type-radio,
                &.gfield--type-checkbox {
                    
                    .gfield_label,
                    .ginput_complex label {
                        margin-bottom:0.5em;
                        height:auto;
                        line-height:1.2em;
                        white-space:normal;
                    }
                }
            
                &:before {
                    display:none !important;
                }
            
                &.gform_hidden {
                    display:none;
                    margin:0 !important;
                }
                
                &.gfield_visbility_hidden {
                    margin:0 !important;
                }
                
                &.recaptcha_field {
                    margin:0 !important;
                }
                
                &.field_sublabel_below.hidden_label {
                    margin:0 !important;
                }
                
                &.gfield_nfprivacy {
                    margin-top:0 !important;
                }
            
            }
        }
    
        .gfield_label,
        .ginput_complex label {
            display:inline-block;
            position:absolute;
            left:0;
            top:0;
            width:auto;
            margin:0;
            padding:0 8px;
            font-size:16px;
            font-weight:$normal;
            color: $green;
            background:none;
            height:30px;
            line-height:30px;
            z-index:106;
            user-select:none;
            pointer-events:none;
            white-space: nowrap;
            transition:all 0.3s;
        
            .gfield_required {
                position: relative;
                display:inline-block;
                top:0;
                margin-left:0;
            }
            
            &.gfield_label_before_complex {
                position:static;
                margin:0 0 2em;
                padding:0;
                font-size:16px;
                color: $green;
                
                .gfield_required {
                    
                }
            }
            
            #secondary &,
            .page-template-page-contact & {
                
            }
            
            &.float_label {
                top:-0.9em;
                font-size:14px;
                color:$gray;
                height:1em;
                line-height:1em;
                                
                .gfield_required {
                    color: $green;
                }
            
                #secondary & {
                    //left:0;
                    //top:-15px;
                }
            }
        
        }
        
        legend.gfield_label {
            position:relative;
            left:0;
            padding:0;
        }
    
        input[type='text'],
        input[type='number'],
        input[type='tel'],
        input[type='email'],
        textarea {
            width:100%;
            padding:8px;
            border:none;
            height:32px;
            border-radius:0;
            border:none;
            font-family:$roboto;
            font-weight:$normal;
            font-size:16px;
            line-height:1em;
            border:none;
            border-bottom:2px solid $green;
            -webkit-appearance:none;
            letter-spacing:normal;
            color:$green;
            background:none;
            transition:all 0.3s;
            
            &:hover {
                border-color:$gray;
            }
                
            &:focus {
                outline:none;
                border-color:$gray;
            }
        }
    
        textarea {
            height:7em; //this allows for four lines w/out scrollbar on desktop
            line-height:1.3em;
        }
    
        input[type='hidden'] {
            display:none;
        }
    
        .field_sublabel_below {
        
            .ginput_container label {
                font-size:14px;
                font-style:italic;
                color: $green;
            }
        }
    
        .instruction {
            padding:0;
            font-size:14px;
            line-height:1.3em;
            font-style:italic;
            color: $green_dark;
        }
    
        .ginput_container_select,
        .ginput_container_text,
        .ginput_container_email,
        .ginput_container_phone,
        .ginput_container_date,
        .ginput_container_website,
        .ginput_container_number {
            margin:0;
            border:none;
        }
    
        .ginput_container_textarea {
            margin:0;
        }
        
        .ginput_container_select,
        .ginput_complex .address_state {
            position:relative;
            cursor:pointer;
            background:none;
            z-index:105;
            
            &:hover {
                
                &:after {
                    transform:translateY(-50%) rotate(180deg);
                    opacity:1;
                }
            }
        
            &:after {
                position:absolute;
                right:10px;
                top:50%;
                //content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 9'%3E%3Cg fill='%230F2C15' fill-rule='nonzero' opacity='1.0'%3E%3Cpath d='M7,8.5 C6.7,8.5 6.5,8.4 6.3,8.2 L0.3,2.2 C-0.1,1.8 -0.1,1.1 0.3,0.7 C0.7,0.3 1.4,0.3 1.8,0.7 L7,6 L12.2,0.8 C12.6,0.4 13.3,0.4 13.7,0.8 C14.1,1.2 14.1,1.9 13.7,2.3 L7.7,8.3 C7.5,8.4 7.3,8.5 7,8.5 Z' %3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                content:'';
                text-align:center;
                z-index:-1;
                width: 14px; 
                height: 9px;
                background:transparent url('../../images/select-caret.svg') no-repeat center center;
                transform:translateY(-50%);
                opacity:0.7;
                transition:all 0.3s;
            }
        
            select {
                border: none;
                border-bottom:2px solid $green;
                outline: 0;
                margin:0;
                padding:8px 30px 8px 8px;
                font-family:$roboto;
                font-size:16px;
                font-weight: $normal;
                line-height:1em;
                color:$green;
                width:100%;
                background:none;
                border-radius:0;
                -webkit-user-select: none;
                -moz-user-select: -moz-none;
                -ms-user-select: none;
                user-select: none;
                -webkit-appearance: none;
                -moz-appearance: radio-container;
                appearance: none;
                white-space:pre-wrap;
                transition:all 0.3s;
                z-index:110;
                cursor:pointer;
                
                &:hover {
                    border-bottom:2px solid $green;
                }
                
                &:focus {
                    outline:none;
                    border-bottom:2px solid $green;
                }
            }
        
            select::-ms-expand {
                display: none;
            }
        }
        
        .ginput_container_radio {
            margin-top:0;
        }
        
        .gfield_radio {
            position: relative;
            cursor: pointer;
            margin:0;
            padding:0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        
            > li {
                display:inline-block;
                width:auto !important;
                margin:0;
                vertical-align:top;
                font-size:100% !important;
            }
            
            label {            
                display:inline-block;
                /*display:flex;
                align-items:center;
                justify-content:center;*/
                position: relative;
                margin:0 0 5px;
                padding: 0 25px;
                font-size: 16px !important;
                font-weight:$normal !important;
                font-style:normal !important;
                height:1.2em;
                max-width:none !important;
                line-height:1em;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: $gray !important;
        
                &:before {
                    position:absolute;
                    left:4px;
                    top:3px;
                    width:10px;
                    height:10px;
                    border-radius:50%;
                    content:'';
                    box-shadow: inset 15px 15px $green;
                    transition: 0.2s transform ease-in-out;
                    transform: scale(0);
                }
                
                &:after {
                    position:absolute;
                    left:0;
                    top:-1px;
                    width:16px;
                    height:16px;
                    border-radius:50%;
                    border:2px solid $green;
                    content:'';
                    z-index:-1;
                }
            }
        
            input[type='radio'] {
                position:absolute;
                opacity:0;
                cursor:pointer;
        
                &:checked {
            
                    ~ label:before {
                        transform: scale(1);
                    }
                }
            }
        }
        
        .gfield_checkbox {
            position: relative;
            cursor: pointer;
            margin:0;
            padding:0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        
            > li {
                display:inline-block;
                //width:auto !important;
                margin:0 !important;
                vertical-align:top;
                font-size:100% !important;
            }
        
            label {
                display:inline-block;
                /*display:flex;
                align-items:center;
                justify-content:center;*/
                position: relative;
                margin:0;
                padding: 0 20px 0 25px;
                font-size: 16px !important;
                font-weight: $normal !important;
                height:1.2em;
                max-width:none !important;
                line-height:1.2em;
                font-style:normal !important;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: $gray !important;
            
                #secondary & {
                    margin:0;
                    font-size:0.9em !important;
                
                    &:before {
                        
                    }
                }
                
                &:before {
                    position:absolute;
                    left:0;
                    top:50%;
                    width:13px;
                    height:13px;
                    font-family: FontAwesome;
                    line-height:13px;
                    font-size:10px;
                    color: $white;
                    content:'\f00c';
                    border:2px solid $green;
                    background:none;
                    text-align:center;
                    transform:translateY(-50%);
                }
            }
        
            input[type='checkbox'] {
                position:absolute;
                opacity:0;
                cursor:pointer;
            
                &:checked {
                
                    ~ label:before {
                        //background-image: url(../../images/checkmark-on.png);
                        background: $green;
                    }
                }
            }
        
            &:hover {
            
            }
        
        }
        
        .no_label {
            .gfield_label {
                display:none;
            }
            .gfield_checkbox label {
                position:relative;
                font-size:14px;
                color: $green;
                
                &:after {
                    position:absolute;
                    width:10px;
                    height:10px;
                    display:inline-block;
                    top: -0.25em;
                    font-size: 80%;
                    color: $green;
                    content:'*';
                }
            }
        }
        
        .gfield_contains_required.gfield_nfprivacy {
            .gfield_required {
                display:none !important;
            }
        }
    
        .nfprivacy-field-description {
            margin:0 0 20px;
            font-size:14px;
            line-height:1.3em;
            font-weight:$normal;
            color:$gray;
            
            a {
                color:$gray;
                text-decoration:underline;
                text-decoration-thickness:1px;
                text-underline-offset:0.1em;
                text-decoration-color:$gray;
        
                &:hover {
                    color:$green;
                    text-decoration-color:$green;
                }
            }
        }
        
        .charleft,
        .ginput_counter {
            display:none !important;
        }
        
        .gform_description {
            margin:0 0 1em;
            line-height:1.3em;
        }
        
        .gform_validation_errors {
            position:relative;
            margin:0 0 30px;
            padding:20px 44px 20px 24px;
            border-radius:0;
            box-shadow:none;
            border:2px solid $green;
            background:rgba(125,156,145,0.8);
            color:$white;
            text-align:left;
            
            .gform_submission_error {
                margin:0;
                padding:0;
                border:none;
                color:$white;
                font-weight:$normal;
                font-size:1em;
                line-height:1.26em;
                text-align:left;
                -webkit-font-smoothing: auto;
            }
            
            .gform-icon--close {
                display:none;
            }
        }
        
        .gform_required_legend {
            display:none !important;
            margin:20px 0;
            font-size:14px;
            font-style:italic;
        }
        
        .gfield_required {
            color:$green !important;
        }
        
        .gfield_error {
            input[type='text'],
            input[type='number'],
            input[type='tel'],
            textarea,
            select {
                border:none;
                border-bottom:2px solid $gray !important;
            }
        }
        
        .validation_message {
            background:none;
            border:none;
            color:$gray;
            padding:0 8px;
            margin:5px 0 0;
            font-size:14px;
            font-style:italic;
            font-weight: $normal;
            line-height:1.3em;
            
            &.instruction {
                position:absolute;
                right:32px;
                margin:0;
                padding-left:0;
            }
        }
        
        .grecaptcha-badge {
            //visibility:hidden !important;
        }
        
        .gform_footer {
            position:relative;
            margin:1em 0 0;
            padding:0;
            text-align:left;
            
            .gform_button {
                display:inline-block;
                position:relative;
                margin:0;
                padding:14px 32px;
                width:auto;
                background:$green !important;
                font-family: $roboto;
                font-size: 16px !important;
                font-weight: $medium;
                font-style:normal;
                line-height:1.1em !important;
                color: $white !important;
                border:none;
                border-radius:30px;
                text-decoration:none;
                box-sizing:border-box;
                text-align:center;
                -webkit-appearance:none;
                transition:all 0.3s;
                cursor:pointer;
                
                &:hover,
                &:focus {
                    background:$green_dark !important;
                }
                
            }
        }
    }
    
    //VERBOSE, BUT MUST HANDLE DARK BG FORMS
    .gray,
    .gray_mid {
        
        .gform_wrapper {
            
            .gfield_label,
            .ginput_complex label {
                
                &.float_label {
                    color:$gray_light2;
                    height:1em;
                    line-height:1em;
                                
                    .gfield_required {
                        color: $green;
                    }
                }
        
            }
            
            input[type='text'],
            input[type='number'],
            input[type='tel'],
            input[type='email'],
            textarea {
                
                &:hover,
                &:focus {
                    border-color:$gray_light2;
                }
            }
            
            .ginput_container_select,
            .ginput_complex .address_state {
        
                &:after {
                    background:transparent url('../../images/select-caret-light.svg') no-repeat center center;
                }
        
                select {
                    
                    &:hover,
                    &:focus {
                        border-color:$gray_light2;
                    }
                }
            }
            
            .gfield_radio {
                
                label {            
                    color: $gray_light2 !important;
        
                    &:before {
                        box-shadow: inset 15px 15px $gray_light2;
                    }
                
                    &:after {
                        border:1px solid $gray_light2;
                    }
                }
            }
            
            .gfield_checkbox {
                
                label {
                    color: $gray_light2 !important;
                
                    &:before {
                        color: transparent;
                    }
                }
        
                input[type='checkbox'] {
                    
                    &:checked {
                
                        ~ label:before {
                            color:$gray;
                            background: $green;
                        }
                    }
                }
        
            }
            
            .nfprivacy-field-description {
                color:$gray_light2;
            
                a {
                    color:$gray_light2;
                    text-decoration-color:$gray_light2;
        
                    &:hover {
                        color:$green;
                        text-decoration-color:$green;
                    }
                }
            }
            
            .gform_validation_errors {
                background:rgba(125,156,145,0.5);
            }
            
            .gfield_error {
                input[type='text'],
                input[type='number'],
                input[type='tel'],
                textarea,
                select {
                    border-bottom:2px solid $gray_light2 !important;
                }
            }
        
            .validation_message {
                color:$gray_light2;
            }
        }
        
        .gform_confirmation_message {
            border:2px solid $green;
            background:$gray_light2;
            color:$green;
        }
    }
    
    .gray {
        
        .gform_wrapper {
            
            .gfield_label,
            .ginput_complex label {
                
                &.float_label {
                    
                    .gfield_required {
                        color: $green_on_slate;
                    }
                }
        
            }
            
            input[type='text'],
            input[type='number'],
            input[type='tel'],
            input[type='email'],
            textarea {
                color:$green_on_slate;
            }
            
            .ginput_container_select,
            .ginput_complex .address_state {
                
                
                select {
                    color:$green_on_slate;
                    
                }
            }
            
            .nfprivacy-field-description {
                
                a {
                    
                    &:hover {
                        color:$green_on_slate;
                        text-decoration-color:$green_on_slate;
                    }
                }
            }
        }
    }
    
    .gray_mid {
        
        
        
    }
    
    .gform_confirmation_wrapper {
        text-align:left;
    }
    
    .gform_confirmation_message {
        margin:0 0 30px;
        padding:20px 24px;
        border-radius:3px;
        box-shadow:none;
        border:2px solid $green;
        background:$gray_light2;
        color:$green;
        font-weight:$normal;
        text-align:left;
        
        strong {
            font-weight:$normal !important;
        }
    }
    
    //SIDEBAR
    #secondary {
        
        
        .gform_wrapper {
            
            .gform_fields {
                display:block;
                padding-right:20px;
                
                li,
                .gfield {
                    margin:1.75em 0 0;
                }
            }
            
            .gform_required_legend {
                margin-top:0;
            }
            
            .gform_title {
                margin:0 0 0.5em;
                font-size:1.05em;
                color:$green;
            }
            
            .nfprivacy-field-description {
                margin-top:20px;
            }
            
            .gform_description {
                display:block;
                margin-bottom:10px;
                font-size:0.95em;
                line-height:1.26em;
            }
            
            .gform_footer {
                margin:2em 0 0;
            
                .gform_button {
                    margin:0;
                    font-size:16px;
                
                    &:hover {
                        
                    }
                }
            }
        }
    }
}

::-webkit-input-placeholder {
  color: rgba(125,156,145,0.7);
}
::-moz-placeholder {
  color: rgba(125,156,145,0.7);
}
:-ms-input-placeholder {
  color: rgba(125,156,145,0.7);
}
:-moz-placeholder {
  color: rgba(125,156,145,0.7);
}

#footer_form,
.inline-cta,
#access_gate,
#secondary .contentupgrade {
    
    h2,
    h3,
    p,
    .gform_description {
        color: $white;
    }
    
    h2 {
        margin:0;
    }
}

//ALL ACCESS GATES (GATED, CONTENT UPGRADE)
#content {
    #access_gate,
    #access_gate_outer,
    .contentupgrade {
    
        .gform_wrapper {
            
            input[type='text'],
            input[type='number'],
            input[type='email'],
            input[type='tel'],
            textarea {
                border-bottom:2px solid $green !important;
                color:$green !important;
                
                &:hover,
                &:focus {
                    border-bottom:2px solid $white !important;
                }
            }
            
            .ginput_container_select,
            .ginput_complex .address_state {
                
                &:after {
                    background:transparent url('../../images/select-caret.svg') no-repeat center center !important;
                }
        
                select {
                    border-bottom:2px solid $green !important;
                    color:$green !important;
                    
                    &:hover,
                    &:focus {
                        outline:none;
                        border-bottom:2px solid $white !important;
                    }
                }
            }
            
            .gfield_radio {
                
                label {            
                    color: $gray_light2 !important;
        
                    &:before {
                        box-shadow: inset 15px 15px $gray_light2;
                    }
                
                    &:after {
                        border:1px solid $gray_light2;
                    }
                }
            }
            
            .gfield_checkbox {
                
                label {
                    color: $gray_light2 !important;
                    
                    
                    &:before {
                        color: transparent !important;
                    }
                }
        
                input[type='checkbox'] {
                    
                    &:checked {
                
                        ~ label:before {
                            color:$gray !important;
                            background: $green !important;
                        }
                    }
                }
        
            }
            
            .gform_required_legend {
                display:none;
                margin:0;
            }
            
            .gform_footer {
                margin-top:2em;
            }
        }
    }
}

#secondary .gform_wrapper,
.page-template-page-contact .gform_wrapper {

    margin:0;
    padding:0;

    h3,
    .gform_title {
        margin:0 0 0.5em;
    }

    p,
    .gform_description {
    
    }
}

//FOOTER FORM, FLOATING
#content #footer_form {
    padding:0;
    
    .content_section_inner {
        padding:8em 0;
    }
    
    .cust_title {
        margin-top:0;
        font-size:1.26em;
    }
    
    .cust_title,
    p,
    .gform_description {
        color:$white;
    }
    
    .single-casestudy & {
        
        .cust_title,
        p,
        .gform_description {
            color:$gray;
        }
    }
    
}

//CUSTOMIZE THE LOADING SPINNER
.nf-ajax-loading {
    position:absolute;
    left:50%;
    top:50%;
    margin:-20px 0 0 -20px;
    padding:0;
    width:40px !important;
    height:40px !important;
    min-height:0 !important;
    background-image: url(../../images/loading.svg);
    background-size:40px 40px !important;
    border:none !important;
    background-repeat:no-repeat;
    background-position:center center;
}

.gform_ajax_spinner {
    position:absolute;
    left:-48px;
    top:0;
	margin-left:0px !important;
	padding:0 !important;
	border:none !important;
	width: 40px !important;
	height: 40px !important;
	
	#secondary & {
	    right:-15px;
	    top:5px;
	    width: 30px !important;
	    height: 30px !important;
	}
}

#nfprivacy-modal {
    
    .nfprivacy-modal-body-inner {
        padding:80px 20px 20px;
    }
    
    .nfprivacy-modal-close,
    .nfprivacy-modal-confirm {
        display:inline-block;
        position:relative;
        margin:1em 0 1em 20px;
        padding:14px 26px;
        width:auto;
        background:$green !important;
        font-family: $roboto;
        font-size: 16px !important;
        font-weight: $medium;
        font-style:normal;
        line-height:1.1em !important;
        color: $white !important;
        border:none;
        border-radius:0;
        text-decoration:none;
        box-sizing:border-box;
        text-align:center;
        -webkit-appearance:none;
        transition:all 0.3s;
        cursor:pointer;
                
        &.nfprivacy-modal-close-top {
            position:absolute;
            top:20px;
            right:20px;
            margin:0;
        }
            
        &:before {
            display:none;
        }
    
        &:hover {
            background:$green_dark !important;
        }
    }
    
}



//WEBINAR ACCESS CODE
.webinar-past-form-access-code {
    
    input[type='text'] {
        width:50%;
        max-width:200px;
        padding:8px 10px;
        height:34px;
        border-radius:0;
        border:none;
        font-family:$roboto;
        font-weight:$normal;
        font-size:16px;
        line-height:1em;
        border:2px solid $gray;
        -webkit-appearance:none;
        letter-spacing:normal;
        color:$gray;
        background:none;
        transition:border-color 0.3s;
    
        &:focus {
            outline:none;
            border-color:$green;
        }
    }
}

//PASSWORD PROTECTION FORM (At this point just for Portfolio page template)
#pw_protection_wrap {
    display:flex;
    justify-content:flex-start;
    align-items:center;
    height:100vh;
    width:100vw;
    background:$gray_dark;
    
    > form {
        flex:0 0 40vw;
        margin:0 auto;
        
        .logo {
            display:inline-block;
            width:236px;
            max-width:100%;
            height:48px;
            margin:-20px 0 16px 0;
            z-index:1000;
            box-sizing:border-box;
            vertical-align:top;
            transition:all 0.2s linear;
        
            img {
                display:block;
                width:100%;
                max-width:100%;
            }
        }
        
        p {
            color:$white !important;
            
            &.footnote {
                margin:1em 0 0;
                font-style:italic;
            }
        }
        
        label {
            display:none;
            color:$white;
        }
        
        input[type='password'] {
            display:inline-block;
            margin:0;
            padding:0 20px;
            background:$gray_dark;
            border: 3px solid $white;
            outline:none;
            height:50px;
            color: $white;
            font-size: 1em;
            line-height:50px;
            font-family: $roboto;
            border-radius:0;
            -webkit-appearance:none;
            box-sizing:border-box;
        
            &::-webkit-input-placeholder {
                color: rgba(125,156,145,0.7);
            }
            &:-moz-placeholder {
                color: rgba(125,156,145,0.7);
            }
            &::-moz-placeholder {
                color: rgba(125,156,145,0.7);
            }
            &:-ms-input-placeholder {
                color: rgba(125,156,145,0.7);
            }

            &:focus::-webkit-input-placeholder {
                color: rgba(125,156,145,1);
            }

            &:focus:-moz-placeholder {
                color: rgba(125,156,145,1);
            }

            &:focus::-moz-placeholder {
                color: rgba(125,156,145,1);
            }

            &:focus:-ms-input-placeholder {
                color: rgba(125,156,145,1);
            }
        }
        
        input[type='submit'] {
            margin:0 0 0 20px;
            line-height:1em;
            z-index:10;
        }
    }
}

@media only screen and (max-width: #{$bp_small}) {
    
    #content #footer_form {
        
        .content_section_inner {
            padding:4em 20px;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}