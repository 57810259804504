/**********************************************************************************************/
/***** CUSTOM GUTENBERG BLOCK: TESTIMONIAL ****************************************************/
/**********************************************************************************************/
.block_testimonial {
    width:calc(100% + 100px);
    margin:2em -50px;
    
    .block_inner {
        position: relative;
        z-index:10;
        background:$gray_mid;
    }
    
    .bg_img {
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        content: '';
        background-color:transparent;
        background-repeat:no-repeat;
        background-position:center top;
        background-size:cover;
    }
    
    .overlay {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background:rgba(0,0,0,0.8);
    }
    
    .inner_content {
        position:relative;
        padding:3em;
        z-index:11;
    }
    
    blockquote {
        margin:0 0 1.26em;
        font-family:$roboto;
        font-size:0.95em;
        line-height:1.55em;
        font-weight:$normal;
        color:$white;
    }
    
    .name {
        margin:0 0 0.25em;
        font-family:$roboto_cond;
        font-size:1.05em;
        font-weight:$bold;
        color:$white;
    }
    
    .info {
        margin:0;
        font-family:$roboto_cond;
        font-size:0.84em;
        font-weight:$normal;
        color:$white;
    }
}

@media screen and (max-width: #{$bp_small}) {

    .block_testimonial {
        width:100%;
        margin:2em 0;
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}