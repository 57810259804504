/**********************************************************************************************/
/***** PRIMARY NAV ****************************************************************************/
/**********************************************************************************************/
#primary-navigation {
    //flex:1 1 auto;
    position:relative;
    margin:0;
    padding:0;
    box-sizing:border-box;
    transition:opacity 0.5s;
    
    .nav-menu {
        position:relative;
        display:flex;
        align-items:center;
        margin:0;
        padding:0;
        list-style:none;
        text-align:right;
        
        > li {
            position:relative;
            display:inline-block;
            margin:0;
            transition: all 0.3s ease 0s;
            vertical-align:top;
            text-align:left;
            
            &:first-of-type {
                
            }
            
            &:last-of-type {
                a {
                    padding-right:0;
                    
                    &:after {
                        left:calc(50% + 10px);
                    }
                }
            }
            
            &.contact {
                margin:0 0 0 20px;
                
                a {
                    padding:14px 24px !important;
                    border-radius:30px;
                    border:1px solid $green;
                    white-space:nowrap;
                    
                    &:after {
                        display:none !important;
                    }
                    
                    &:hover {
                        
                    }
                }
            }
            
            a {
                display:block;
                position:relative;
                padding:30px 32px;
                line-height:1em;
                font-family:$roboto_cond;
                font-size:0.95em;
                font-weight: $normal;
                text-transform:uppercase;
                color: $green;
                text-align:center;
                letter-spacing:0.01em;
                transition:all 0.3s;  
                text-decoration:none;
                border-radius: 0;
                -webkit-font-smoothing: auto;
                
                &:after {
                    position:absolute;
                    left:32px;
                    bottom:24px;
                    width:calc(100% - 64px);
                    height:2px;
                    content:'';
                    background:$green;
                    opacity:0;
                    transition:all 0.3s;
                }
                
                .scrolling & {
                    padding:20px 32px;
                    
                    &:after {
                        bottom:14px;
                    }
                }
            }
            
            &:hover > a,
            &:focus > a {
                color:$white;
                background:$green;
                
                &:after {
                    display:none;
                }
            }
            
            &.menu-item-has-children {
                
                &:hover > a,
                &:focus > a {
                    background: $green_dark;
                }
                
                &:hover > a.norm,
                &:focus > a.norm {
                    background: $green;
                }
            }
            
            &.current-menu-item > a,
            &.current-menu-ancestor > a,
            &.current-post-ancestor > a,
            &.current-page-ancestor > a {
                
                &:after {
                    opacity:0.25;
                }
            }
            
            
            &:hover .sub-menu,
            &:focus .sub-menu {
                left:0;
                margin-top:0;
                visibility:visible;
                opacity:1;
            }
        
            &.flip:hover .sub-menu,
            &.flip:focus .sub-menu {
                left:auto;
                right:0;
            }
        }
        
        ul {
            position: absolute;
            top:100%;
            //left: -999em;
            left:0;
            width:360px;
            min-width:100%;
            margin:0;
            background:$green;
            padding:0;
            z-index:1000;
            visibility:hidden;
            opacity:0;
            transition:opacity 0.3s;
            box-sizing:border-box;            
            
            li {
                position:relative;
                display:block;
                margin:0;
                background:none;
                overflow:hidden;
                
                a {
                    display:block;
                    width:100%;
                    box-sizing:border-box;
                    vertical-align:middle;
                    line-height:1.15em;
                    height:auto;
                    padding:22px 32px;
                    color:$white;
                    font-size:1.05em; /* 20px */
                    text-transform:none;
                    background:$green !important;
                    transition:all 0.3s;
                    text-align:left;
                    border-radius:0;
                    text-decoration:none;
                    
                    .scrolling & {
                        padding:14px 32px;
                    }
                    
                    &:hover,
                    &.active,
                    .nav-menu li:hover &,
                    .nav-menu li:focus & {
                        color: $white;
                        background:$green_dark !important;
                    }
                    
                }
                
                &.current-menu-item a,
                &.current-menu-ancestor a,
                &.current-post-ancestor a,
                &.current-page-ancestor a {
                    color: $white;
                    background:$green_dark !important;
                                                        
                    &:hover,
                    &.active,
                    .nav-menu li:hover &,
                    .nav-menu li:focus & {
                        color: $white;
                        background:$green_dark !important;
                    }
                }
                
                &:first-of-type {
                    
                    a {
                        //border:none;
                    }
                }
            }
        }
    }
}

/* MOBILE NAV */
#primary-navigation-mobile {
    position:relative;
    width:100%;
    padding:0;
    
    .nav-menu {
        list-style:none;
        margin:0;
        padding:0;
                
        > .menu-item-has-children {
            
            > .toggle {
                display:none;
                border:none;
                background:none;
            }
        }
        
        > li {
            margin:0;
            border:none;
            
            &:first-child {
                
            }
            
            &.contact {
                display:inline-block;
                margin:20px auto;
                
                > a {
                    padding:14px 24px !important;
                    border-radius:30px;
                    border:1px solid $green;
                    font-weight: $normal;
                    -webkit-font-smoothing: auto;
                    
                    &:after {
                        display:none !important;
                    }
                    
                    &:hover {
                        
                    }
                }
            }
            
            > a {
                display:block;
                position:relative;
                padding:20px 0 10px;
                line-height:1em;
                font-family:$roboto_cond;
                font-size:0.95em;
                font-weight: $bold;
                text-transform:uppercase;
                color: $green;
                text-align:left;
                letter-spacing:0.01em;
                transition:all 0.3s;  
                text-decoration:none;
                border-radius: 0;
                -webkit-font-smoothing: antialiased;
                
                &:after {
                    display:none;
                }
            }
            
            &.current-menu-item > a,
            &.current-menu-ancestor > a,
            &.current-post-ancestor > a,
            &.current-page-ancestor > a {
                color:$green;
                
                &:after {
                    background:$green;
                }
            }
            
        }
        
        ul {
            //display:none;
            list-style:none;
            width:100%;
            margin:0;
            padding:0 20px;
            background:none;
            border-top:none;
            
            li {
                
                
                a {
                    display:block;
                    font-size:.89em;
                    font-family:$roboto_cond;
                    color: $green;
                    font-weight:$normal;
                    text-transform:none;
                    text-decoration:none;
                    margin:0;
                    padding:5px 0 0;
                    transition: all 0.3s ease 0s;
                    -webkit-font-smoothing: auto;
                }
                
                &.current-menu-item a,
                &.current-menu-ancestor a,
                &.current-post-ancestor a,
                &.current_page_parent a,
                &.current_page_ancestor a,
                &.current-page-ancestor a {
                    color:$green;
                }
                
                > .toggle {
                    display:none;
                }
            }
        }
        
        .current-menu-item,
        .current-menu-ancestor,
        .current-post-ancestor,
        .current_page_parent,
        .current_page_ancestor,
        .current-page-ancestor {
            
            .sub-menu {
                //display:none;
            }
        }
        
        .current-menu-item > a {
            
        }
    }
}

@media only screen and (max-width: #{$bp_mid}) { //tablet portrait is the goal here
    
    #primary-navigation {
        display:none;
    }
    #primary-navigation-mobile {
        display:block;
    }
    
}

@media only screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    #primary-navigation {
        
        .nav-menu {
            
            > li {
            
                a {
                    padding:30px 20px;
                
                    &:after {
                        left:20px;
                        width:calc(100% - 40px);
                    }
                
                    .scrolling & {
                        padding:20px 20px;
                    }
                }
            }
        
            ul {
                
                li {
                    
                    a {
                        padding:22px 20px;
                    
                        .scrolling & {
                            padding:14px 20px;
                        }
                    
                    }
                }
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    
    #primary-navigation {
        
        .nav-menu {
        
            > li {
            
                a {
                    font-size:0.84em;
                    padding:30px 16px;
                }
            }
        }
    }
    
}