/**********************************************************************************************/
/***** ACCORDION CONTENT ELEMENTS (flex layout, dedicated Jobs accordion) *********************/
/**********************************************************************************************/
.acc_wrap {
    padding:4em 0;
    margin-right:-50px;
    width:calc(100% + 50px);
    
    * {
        box-sizing:border-box;
    }
    
    .acc_intro {
        margin:0 0 4em;
    }
    
    .acc_nav_wrap {
        margin:0 0 1.5em;
        
        .btn {
            margin:0 0 0 15px;
            padding:14px 24px;
            background:none !important;
            color: $green !important;
            
            &:first-of-type {
                margin:0;
            }
            
            &.active,
            &:hover,
            &:focus {
                background:$gray !important;
                color:$white !important;
                outline:none;
            }
        }
    }
    
    .acc_list {
        margin:0;
        padding:0;
        border-bottom:1px solid $green;
        list-style:none;
        
        .acc_node {
            margin:0;
            padding:0;
            list-style-type:none;
        }
    }
    
    .acc_head {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        border-top:1px solid $green;
        background:none;
        transition:all 0.3s;
        transition-delay:0.3s;
        
        &.active {
            background:$gray;
            transition:all 0s;
            transition-delay:0s;
            
            h5 {
                font-weight:$bold;
            }
            
            .toggle {
                transform:rotate(180deg);
                
                svg .select_caret {
                    fill:$white;
                }
            }
        }
        
        h5 {
            flex:0 0 calc(100% - 64px);
            position:relative;
            margin:0;
            padding:20px 10px 20px 32px;
            font-family:$roboto;
            font-size:1em;
            font-weight:$normal;
            color:$white;
            text-transform:none;
            cursor:pointer;
        }
        
        .toggle {
            display:flex;
            align-items:center;
            justify-content:center;
            flex:0 0 64px;
            align-self:stretch;
            transition:all 0.3s;
            transform-origin:center;
            
            svg {
                .select_caret {
                    fill:$green;
                }
            }
        }
    }
    
    .acc_cont {
        display:none;
        background:$gray;
        padding:0 32px 20px 32px;
        
        .inner {
            padding-top:20px;
            border-top:1px solid $green;
            
            * {
                color:$white;
            }
        }
        
        .meta {
            margin:0 0 1.5em;
            
            p {
                margin:0 0 0.5em;
                
                span {
                    display:inline-block;
                    margin:0 10px;
                    
                    &:last-of-type {
                        display:none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {

    .acc_wrap {
        margin:0;
        padding:0 0 2em;
        width:100%;
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .acc_wrap {
        margin-right:0;
        width:100%;
    
        .acc_nav_wrap {
            
            .btn {
                margin:0 20px 20px 0;
                padding:14px 24px;
                
                &:first-of-type {
                    margin-right:20px;
                }
            }
        }
    }
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}