/**********************************************************************************************/
/***** SITEWIDE TABLE STYLES ******************************************************************/
/**********************************************************************************************/
//GENERAL TABLE STYLES
table {
    width:100%;
    margin: 1em 0; 
    border: 1px solid $gray; 
    border-top: none; 
    table-layout:auto;
    
    td,
    th { 
        border-left: 1px solid $gray; 
        padding: 1em;
        background: $white;
        font-size:0.86em;
        color:$gray !important;
    }
    th { 
        background: $gray_mid;
        color:$white !important;
    }

    tr {
        border-top: 1px solid $gray; 
        border-left: 1px solid $gray;
        
        &:nth-child(even) td {
            background: $gray_light;
        }
    }
    
}

//INLINE WYSIWYG TABLE STYLES (ADDED VIA THE CLASSIC MCE EDITOR)
.wysiwyg {
    
    table {
        border: 1px solid $gray; 
        border-top: none; 
        table-layout:auto;
        
        td,
        th { 
            border: 1px solid $gray; 
            padding: 1em;
            background: $gray_light;
            color:$gray !important;
            
            &:first-of-type {
                width:auto !important;
            }
        }
        
        th { 
            background: $gray;
            color:$white !important;
            font-weight:$bold;
            font-family:$roboto_cond;
        }
        
        tr {
            border-top: 1px solid $gray; 
            border-left: 1px solid $gray;
            
            &:nth-child(even) td {
                background: $white;
            }
            
            &:first-of-type {
                
                td {
                    background:$gray_light;
                }
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {

    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}