/**********************************************************************************************/
/***** FILTERS/GRIDS **************************************************************************/
/**********************************************************************************************/
.page-template-page-results {
    
    .results_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-auto-rows: auto;
        grid-column-gap: 60px;
        grid-row-gap: 20px;
        grid-auto-flow: dense;
        margin:0 0 2em;
        padding:0;
    
        * {
            box-sizing:border-box;
        }
        
        .rcard {
            padding-bottom:60px;
            height:100%;
            min-height:470px;
            text-decoration:none;
            
            .inner {
                position:relative;
                margin:0 0 60px;
                background-color:transparent;
                background-repeat:no-repeat;
                background-position:center top;
                background-size:cover;
                z-index:10;
            }
            
            span.btn {
                position:absolute;
                right:0;
                top:100%;
                margin:0;
                padding-top:18px !important;
                padding-bottom:18px !important;
        
                color:$green !important;
                background:none !important;
        
                &:before {
                    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e") !important;
                    filter:none;
                }
        
                &:hover {
                    color:$white !important;
                    background:none !important;
                }
            }
            
            &:hover {
                
                span.btn {
                    background:$green_dark !important;
                    color:$white !important;
            
                    &:before {
                        filter:brightness(0) invert(1);
                    }
                }
            }
            
            &.cta {
                grid-column: 2 / span 2;
                
                &.flip {
                    grid-column: 1 / span 2;
                }
                
                .inner {
                    display:flex;
                    justify-content:flex-start;
                    align-items:center;
                    flex-direction:row;
                    background:$gray_mid;
                }
                
                .inner_content {
                    display:block;
                    padding:40px 12.3%;
                    
                    * {
                        color:$white;
                    }
                    
                    .eyebrow {
                        display:block;
                        margin:0 0 1em;
                        color:$green;
                        font-family:$roboto_cond;
                        font-size:0.95em;
                        text-transform:uppercase;
                        -webkit-font-smoothing: auto;
                    }
                }
            }
            
            
            &.standard {
                grid-column: 2 / span 2;
                
                &.flip {
                    grid-column: 1 / span 2;
                }
        
                .bg_img {
                    padding-bottom:50%;
                }
                
                &.align_left {
                    grid-column: 1 / span 2;
                }
                
                .inner {
                    justify-content:flex-end;
                }
        
                .overlay {
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    background: -moz-linear-gradient(bottom,  rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.05) 100%);
                    background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0.05) 100%);
                    background: linear-gradient(to top,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0.05) 100%);
                    z-index:-1;
                }
                
                .overlay_solid {
                    display:none;
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    z-index:-1;
                }
                
                &.no_overlay {
                    
                    .overlay {
                        display:none;
                    }
                    
                    .overlay_solid {
                        display:block;
                    }
                }
        
                .inner_content {
                    display:block;
                    padding:40px 48px;
                }
        
                .heading {
                    margin:0 0 0.25em;
                    font-family:$roboto_cond;
                    font-weight:$bold;
                    font-size:1.05em;
                    line-height:1.17em;
                    color:$white;
                }
        
                p {
                    margin:0;
                    font-family:$roboto_cond;
                    font-weight:$normal;
                    font-size:0.95em;
                    color:$white;
                }
        
                
            }
            
            &.testimonial {
                
                &.flip {
                    grid-column: 3 / span 1;
                }
                
        
                .overlay {
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    background:rgba(0,0,0,0.7);
                    z-index:-1;
                }
        
                .inner_content {
                    display:flex;
                    justify-content:flex-start;
                    align-items:flex-start;
                    padding:44px 40px;
                    height:100%;
            
                    /*&.has_logo {
                        padding:44px 32px;
                
                        .quote {
                            flex:0 0 calc(100% - 64px);
                            margin-left:20px;
                        }
                    }*/
                }
                
                &.test_to_standard {
                    
                    .inner {
                        justify-content:flex-end;
                    }
                    
                    .inner_content {
                        display:block;
                        height:auto;
                    }
                }
                
                &.no_overlay {
                    
                    .overlay {
                        display:none;
                    }
                }
        
                /*.client_logo {
                    flex:0 0 44px;
                    align-self:stretch;
                    background-color:transparent;
                    background-repeat:no-repeat;
                    background-position:center top;
                    background-size:100% auto;
                }*/
                
                .heading {
                    margin:0 0 0.25em;
                    font-family:$roboto_cond;
                    font-weight:$bold;
                    font-size:1.05em;
                    line-height:1.17em;
                    color:$white;
                }
        
                p {
                    margin:0;
                    font-family:$roboto_cond;
                    font-weight:$normal;
                    font-size:0.95em;
                    color:$white;
                }
        
                .quote {
                    flex:0 0 100%;
                }
        
                blockquote {
                    margin:0 0 1.26em;
                    font-family:$roboto_slab;
                    font-size:1.1em;
                    line-height:1.55em;
                    font-weight:$normal;
                }
        
                .name {
                    margin:0 0 0.25em;
                    font-family:$roboto_cond;
                    font-size:1.05em;
                    font-weight:$bold;
                }
        
                .info {
                    margin:0;
                    font-family:$roboto_cond;
                    font-size:0.84em;
                    font-weight:$normal;
                }
        
                
            }
        }
    }
}

.home .results_grid,
.home .services_grid {
    display: grid;
	grid-template-columns: repeat(auto-fit, minmax(260px,1fr));
	grid-template-rows: 1fr;
	grid-auto-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    margin:0 0 2em;
    padding:0;
    
    * {
        box-sizing:border-box;
    }
}

.rcard,
.scard,
.pcard {
    display:block;
    position:relative;
    
    &.wide {
        grid-column: 1 / span 2;
        
        .bg_img {
            padding-bottom:50%;
        }
    }
    
    .inner {
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        align-items:flex-start;
        height:100%;
    }
    
    .bg_img_wrap {
        width:100%;
        overflow:hidden;
    }
    
    .bg_img {
        height:0;
        width:100%;
        padding-bottom:100%;
        background-color:transparent;
        background-repeat:no-repeat;
        background-position:center top;
        background-size:cover;
        transition:all 0.3s;
    }
    
    .inner_content {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        width:100%;
    }
    
    .left {
        flex:1 0 calc(100% - 160px);
        padding:10px 20px 10px 10px;
    }
    
    h3 {
        margin:0;
    }
    
    p {
        margin:10px 0 0;
    }
    
    span.btn.arrowed.trans {
        flex:0 0 160px;
        margin:0;
        padding:15px 34px 15px 12px;
        color:$green_on_slate !important;
        font-size:16px !important;
        
        &:before {
            right:14px;
            transform:translateY(-50%);
            content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%2393AEA6' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
            filter:none;
        }
    }
    
    &:hover {
        
        .bg_img {
            transform:scale(1.2);
        }
        
        span.btn.arrowed.trans {
            background:$green !important;
            color:$white !important;
            
            &:before {
                filter:brightness(0) invert(1);
            }
        }
    }
}

.pcard {
    
    .bg_img {
        padding-bottom:70%;
    }
    
    h3 {
        font-weight:$normal;
    }
    
    .left {
        padding:10px 20px 10px 0;
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    .page-template-page-results {
    
        .results_grid {
            display:block;
        
            .rcard {
                min-height:0;
            
                span.btn {
                    color:$white !important;
                    background:$green !important;
        
                    &:before {
                        content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23ffffff' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e") !important;
                    }
        
                    &:hover {
                        color:$white !important;
                        background:$green !important;
                    }
                }
            
                &:hover {
                
                    span.btn {
                        background:$green !important;
                        color:$white !important;
            
                        &:before {
                            filter:none;
                        }
                    }
                }
            
                &.standard {
        
                    .inner_content {
                        display:block;
                        padding:12em 30px 30px;
                    }
                }
            }
        }
    }
    
    .home .results_grid,
    .home .services_grid {
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        
        .rcard,
        .scard {
            display:block;
            position:relative;
    
            &.wide {
                grid-column: 1 / span 2;
        
                .bg_img {
                    padding-bottom:50%;
                }
            }
    
            .bg_img {
                padding-bottom:100%;
            }
        }
    }
    
}

@media screen and (max-width: 540px) {
    
    .home .results_grid,
    .home .services_grid {
        grid-template-columns: 1fr;
        
        .rcard,
        .scard {
            display:block;
            position:relative;
    
            &.wide {
                grid-column: 1 / span 1;
        
                .bg_img {
                    //padding-bottom:100%;
                }
            }
    
            .bg_img {
                padding-bottom:50%;
            }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .page-template-page-results {
    
        .results_grid {
            display:block;
        
            .rcard {
                min-height:0;
                
                &.standard {
        
                    .inner_content {
                        display:block;
                        padding:15em 48px 2em;
                    }
                }
                
                &.testimonial {
                    
                    .inner_content {
                        padding-top:6em;
                        padding-bottom:6em;
                        
                        &.has_logo {
                            padding:6em 32px;
                        }
                    }
                }
            }
        }
    }
    
    .home .results_grid,
    .home .services_grid {
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        
        .rcard,
        .scard {
            &.wide {
                grid-column: auto;
    
                .bg_img {
                    padding-bottom:100%;
                }
            }

            .bg_img {
                padding-bottom:50%;
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}


.insights-filter-clear {
    position:relative;
    display: inline-block;
    color: $green;
    margin:0 0 0 -24px;
    padding:0 0 0 28px;
    font-weight:$medium;
    text-decoration: none;
    text-align: left;
    line-height: 22px;
    font-size: 16px;
    
    &:before {
        position:absolute;
        left:0;
        top:50%;
        transform:translateY(-50%);
        width:20px;
        height:20px;
        background:none;
        content:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cg fill='%237D9C91' %3e%3cpath d='M6.2,14.8 L10,11 L13.8,14.8 L14.9,13.7 L11.1,10 L14.9,6.2 L13.8,5.1 L10,8.9 L6.2,5.2 L5.2,6.2 L8.9,10 L5.1,13.8 L6.2,14.8 Z M10,20 C8.6,20 7.3,19.7 6.1,19.2 C4.9,18.7 3.8,18 2.9,17.1 C2,16.2 1.3,15.1 0.7,13.9 C0.1,12.7 0,11.4 0,10 C0,8.6 0.3,7.3 0.8,6.1 C1.3,4.9 2,3.8 2.9,2.9 C3.8,2 4.9,1.3 6.1,0.8 C7.3,0.3 8.6,0 10,0 C11.4,0 12.7,0.3 13.9,0.8 C15.1,1.3 16.2,2 17.1,2.9 C18,3.8 18.7,4.9 19.2,6.1 C19.7,7.3 20,8.6 20,10 C20,11.4 19.7,12.7 19.2,13.9 C18.7,15.1 18,16.2 17.1,17.1 C16.2,18 15.1,18.7 13.9,19.2 C12.7,19.7 11.4,20 10,20 Z M10,18.5 C12.4,18.5 14.4,17.7 16,16 C17.6,14.3 18.5,12.3 18.5,10 C18.5,7.6 17.7,5.6 16,4 C14.3,2.4 12.3,1.5 10,1.5 C7.7,1.5 5.6,2.3 4,4 C2.4,5.7 1.5,7.7 1.5,10 C1.5,12.4 2.3,14.4 4,16 C5.6,17.7 7.7,18.5 10,18.5 Z'%3e%3c/path%3e%3c/g%3e%3c/svg%3e");
        filter: none;
        transition:all 0.3s;
    }
    
    &:hover {
        color:$white !important;
        background:none !important;
        
        &:before {
            filter: brightness(0) invert(1);
        }
    }
}

.insights-filter-desc {
    margin:0 0 60px;
    font-family:$roboto;
    font-size:0.95em;
    font-weight:$normal;
    
    span {
        display:inline-block;
        position:relative;
        margin:0 0.75em;
        padding:14px 18px;
        width:auto;
        background:$gray !important;
        font-family: $roboto;
        font-size: 100% !important;
        font-weight: $bold;
        font-style:normal;
        line-height:1.1em !important;
        color: $white !important;
        border:none;
        border-radius:30px;
        text-decoration:none;
        box-sizing:border-box;
        text-align:center;
        -webkit-appearance:none;
        transition:all 0.3s;
        cursor:pointer;
    }
    
    .insights-filter-clear {
        margin:0;
        color:$green;
    }
}

.filters_wrap {
    
}

.insights-landing-filter {
    margin:0 0 40px;
    padding:0;
    list-style:none;
    
    .insights-filter-title {
        margin:0 0 1em;
        padding:0;
        font-family:$roboto_cond;
        font-size:0.95em;
        font-weight:$bold;
        color:$white;
    }
    
    ul {
        margin:0 0 4em;
        padding:0;
        list-style:none;
        
        &.children {
            margin:0 0 0 20px;
        }
        
        li {
            display:block;
            margin:0;
            font-weight:$normal;
            
            &.current-cat {
                
                
                a {
                    color:$green;
                    font-weight:$bold;
                    
                    &:before {
                        opacity:1;
                    }
                    
                    &:hover {
                        color:$green;
                    }
                }
            }
        }
        
        a {
            position:relative;
            display:block;
            padding:10px 0;
            color:$black;
            font-weight:$medium;
            font-size:0.84em;
            line-height:1.26em;
            transition:all 0.3s;
            
            &:before {
                position:absolute;
                left:-20px;
                top:50%;
                width:8px;
                height:8px;
                border-radius:4px;
                opacity:0;
                content:'';
                background:$green;
                transform:translateY(-50%);
                transition:opacity 0.3s;
            }
            
            &:hover {
                color:$green;
                
                &:before {
                    opacity:1;
                }
            }
        }
    }
}

.insight-list {

    .grid-halves {
        margin:0 -20px;
    }
    
    article.card {
        margin:0 0 60px;
        
        .wrap_link {
            display:block;
            height:100%;
            text-decoration:none;
            
            &:hover {
                
                .heading {
                    color:$green_dark;
                }
                
                .img > div {
                    transform:scale(1.05);
                }
                
                span.btn {
                    background:$green_dark !important;
                    color:$white !important;
                    
                    &:before {
                        filter:brightness(0) invert(1);
                    }
                }
            }
        }
        
        .inner {
            display:flex;
            justify-content:flex-start;
            align-items:flex-start;
            height:100%;
        }
        
        .img {
            align-self:stretch;
            flex:0 0 31.6%;
            overflow:hidden;
            
            > div {       
                height:100%;     
                background-color:$green;
                background-repeat:no-repeat;
                background-position:center top;
                background-size:cover;
                transition:all 0.3s;
            }
        }
        
        .cont {
            flex:0 0 68.4%;
            align-self:stretch;
            background:$gray_light;
            padding:76px 40px 40px;
        }
        
        .eyebrow {
            display:block;
            margin:0 0 1em;
            color:$green;
            font-family:$roboto_cond;
            font-size:0.95em;
            text-transform:uppercase;
            -webkit-font-smoothing: auto;
        }
        
        .heading {
            margin:0 0 1.5em;
            font-family:$roboto_cond;
            font-weight:$bold;
            font-size:1.58em;
            line-height:1.17em;
            color:$gray;
            transition:all 0.3s;
        }
        
        .meta {
            margin:0 0 1.5em;
            color:$gray;
            font-family:$roboto_cond;
            font-size:1.05em;
        }
        
        span.btn {
            margin:0;
            color:$gray !important;
            background:none !important;
            
            &:before {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23323e48' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
            }
            
            &:hover {
                color:$gray !important;
                background:none !important;
            }
        }
    }
}


@media screen and (max-width: #{$bp_small}) {
    
    #ins_landing {
        
        #tertiary {
            margin:0 0 60px;
            padding:0;
        }
    
        #ins_main {
            margin:0 0 100px;
            padding:0;
        }
    
        #secondary {
            padding:0;
        
            > div {
                margin:0;
            }
        }
    }
    
    .filters_wrap {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
    }
    
    .insights-landing-filter {
        flex:0 0 calc(50% - 20px);
        margin:0 0 0 40px;
        
        &:first-of-type {
            margin:0;
        }
    
        ul {
            margin:0;
            
            li {
                //display:inline-block;
                margin:0 20px 10px 0;
            }
        
            a {
                padding:0 0 0 24px;
            
                &:before {
                    left:0;
                }
            }
        }
    }
    
    .insight-list {
        
        .grid-halves {
            margin:0;
        }
    }
    
}

@media only screen and (max-width: 540px) {
    
    .insight-list {
        
        article.card {
        
            .inner {
                flex-direction:column;
            }
    
            .img {
                
                > div {      
                    height:0;
                    padding-bottom:60%; 
                    background-position:center center;
                }
            }
    
            .cont {
                flex:0 0 40%;
                padding:50px 20px 20px;
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .filters_wrap {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
    }
    
    .insights-landing-filter {
        flex:0 0 calc(50% - 20px);
        margin:0 0 0 40px;
        
        &:first-of-type {
            margin:0;
        }
    
        ul {
            margin:0;
            
            li {
                //display:inline-block;
                margin:0 20px 10px 0;
            }
        
            a {
                padding:0 0 0 24px;
            
                &:before {
                    left:0;
                }
            }
        }
    }
    
    .insight-list {

        .grid-halves {
            margin:0;
        }
        
        article.card {
            
            .cont {
                padding:50px 30px 30px;
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}