/**********************************************************************************************/
/***** FLEX LAYOUTS (STATIC PAGES) ************************************************************/
/**********************************************************************************************/
.flex_layouts_wrap {
    
}

.content_section_inner.flex_layouts {
    width:100%;
    max-width:none;
    padding:0;
    
    > .inner_bg {
        padding:8em 0;
        background:$gray_mid;
    }
    
    &.dark {
    
        > .inner_bg {
            background:$gray;
        }
    }
    
    &.tight_top {
        
        > .inner_bg {
            padding-top:80px;
        }
    }
    
    &.tight_btm {
        
        > .inner_bg {
            padding-bottom:80px;
        }
    }
    
    .layout_intro {
        margin-bottom:3em;
    }
    
    &.two_col_content_img {
        
        .grid {
            display:flex;
            justify-content:flex-start;
            align-items:center;
        }
        
        &.flip {
            
            .cont {
                margin-left:0;
                margin-right: 15.5%;
                padding:0 0 0 10%;
            }
            
            .media {
                margin-right:0;
                margin-left: 5.5%;
                order:-1;
            }
        }
        
        .cont {
            margin-left: 5.5%;
            padding:0 15% 0 0;
            
            .top_barred {
                padding-top: 1px;
            }
        }
        
        .media {
            position: relative;
            margin-right: 5.5%;
            padding:0;
            
            .img_wrap {
                position:relative;
            }
            
            &.modal_gallery {

                .img {
                    display:block;
                    position: relative;
                    object-fit: cover;
                    overflow:hidden;
        
                    .img_wrap {
                        overflow:hidden;
            
                        > div {
                            height:0;
                            padding-bottom:67%;
                            background-color:$gray_mid;
                            background-repeat:no-repeat;
                            background-position:center top;
                            background-size:cover;
                            transition:all 0.3s;
                        }
                    }

                    .caption {
                        margin-top:10px;
                        text-align:left;
                        color:$white;
                        font-size:0.74em;
                        line-height:1.2em;
                        padding-right:30px;
                        padding-left:15px;
                        border-left:2px solid $green_on_slate;
                        font-style:normal;
                    }
            
                    .play_btn {
                        &:hover {
                            background:$green;
                        }
                    }
        
                    &:hover {
                        .img_wrap > div {
                            transform:scale(1.1);
                        }
                
                        .play_btn {
                            background:$green_dark;
                        }
                    }
                }
            }
        }
    }
    
    &.img_grid {
        
        .img_grid_wrap {
            position: relative;
            padding:0;
            display: grid;
            grid-column-gap: $gutter;
            grid-row-gap: $gutter;
            grid-template-columns: repeat(2,1fr);
            grid-template-rows: 1fr;
            grid-auto-rows: auto;
            
            .img_wrap {
                position:relative;
            }
            
            &.modal_gallery {

                .img {
                    display:block;
                    position: relative;
                    object-fit: cover;
                    overflow:hidden;
                    
                    &.fw {
                        grid-column: 1 / span 2;
                    
                        .img_wrap > div {
                            padding-bottom:50%;
                        }
                    }
        
                    .img_wrap {
                        overflow:hidden;
            
                        > div {
                            height:0;
                            padding-bottom:67%;
                            background-color:$gray_mid;
                            background-repeat:no-repeat;
                            background-position:center top;
                            background-size:cover;
                            transition:all 0.3s;
                        }
                    }

                    .caption {
                        margin-top:10px;
                        text-align:left;
                        color:$white;
                        font-size:0.74em;
                        line-height:1.2em;
                        padding-right:30px;
                        padding-left:15px;
                        border-left:2px solid $green_on_slate;
                        font-style:normal;
                    }
            
                    .play_btn {
                        &:hover {
                            background:$green;
                        }
                    }
        
                    &:hover {
                        .img_wrap > div {
                            transform:scale(1.1);
                        }
                
                        .play_btn {
                            background:$green_dark;
                        }
                    }
                }
            }
        }
    }
    
    &.bg_img_inset_cont {
        
        .bg_cont {
            position:relative;
            z-index:10;
            
            &:before {
                position: absolute;
                left:0;
                top:0;
                right:0;
                bottom:0;
                content: '';
                background-color:transparent;
                background-repeat:no-repeat;
                background-position:center top;
                background-size:cover;
                filter: grayscale(100%);
                z-index:-1;
            }
        }
        
        .overlay {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            background: -moz-linear-gradient(bottom,  rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.25) 100%);
            background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.25) 100%);
            background: linear-gradient(to top,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.25) 100%);
            z-index:-1;
        }
        
        .inner_content {
            padding:20em 0 4em;
        }
        
        h2 {
            @extend h1;
            color:$white;
        }
    }
    
    &.standard_layout {
        
        h3 {
            margin-bottom:2em;
        }
    }
    
    &.video_embed {
        
        .vid_wrap {
            position:relative;
            background-color:transparent;
            background-repeat:no-repeat;
            background-size:cover;
            background-position:center center;
            z-index:10;
        }
    }
    
    &.cs_slider {
        
        h3 {
            margin-bottom:2em;
        }
    }
    
    &.test_slider {
        
    }
    
    &.ins_slider {
        
        .single-staff & {
        
            > .inner_bg {
                padding-bottom:15em;
                background:$white;
            }
        }
    }
    
    &.staff_grid {
        
        h2 {
            margin:0 0 90px;
            @extend h1;
            color:$white;
        }
        
        .btn.ct {
            margin:0 0 90px;
        }
        
        .team_grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
            grid-template-rows: 1fr;
            grid-auto-rows: auto;
            grid-column-gap: 60px;
            grid-row-gap: 60px;
            padding:0;
        
            .team_card {
                display:block;
                
                &:hover {
                    .headshot {
                        filter: grayscale(0%);
                    }
                }
            }
        
            .headshot {
                height:0;
                padding-bottom:100%;
                background-color:$gray_mid;
                background-repeat:no-repeat;
                background-position:center top;
                background-size:cover;
                filter: grayscale(100%);
                transition:all 0.3s;
            }
        
            .cont {
                padding:0;
                text-align:left;
            }
            
            h4 {
                margin:0.75em 0 0.25em;
                font-family:$roboto_cond;
                font-weight:$bold;
            }
        
            p {
                margin:0;
                font-family:$roboto_cond;
                font-size:0.95em;
                font-weight:$light;
                -webkit-font-smoothing: antialiased;
            }
        }
    }
    
    &.accordion_content {
        
        h3 {
            margin-bottom:1em;
        }
        
        .acc_wrap {
            padding-top:0;
        }
    }
    
    &.content_timeline {
        max-width:none;
        
        > .inner_bg {
            padding-top:1em;
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    .content_section_inner.flex_layouts {
        
        > .inner_bg {
            padding:4em 0;
        }
        
        &.two_col_content_img {
        
            .grid {
                display:block;
            }
        
            &.flip {
            
                .cont {
                    margin-right:20px;
                    margin-left:20px;
                    padding:0%;
                }
            
                .media {
                    margin-right:20px;
                    margin-left:20px;
                    order:1;
                }
            }
        
            .cont {
                margin-right:20px;
                margin-left:20px;
                margin-bottom:3em;
                padding:0;
            }
            
            .media {
                margin-right:20px;
                margin-left:20px;
                padding:0;
            }
        }
        
        &.img_grid {
            padding:0 20px;
        }
        
        &.bg_img_inset_cont {
        
            .inner_content {
                padding:10em 20px 2em;
            }
        }
        
        &.standard_layout {
            padding:0 20px;
        }
        
        &.video_embed {
            
            > .inner_bg {
                padding-right:20px;
                padding-left:20px;
            }
        }
        
        &.cs_slider,
        &.test_slider,
        &.ins_slider {
            padding:0;
            
            > .inner_bg {
                padding:4em 20px 6em;
            }
            
            h3 {
                margin-bottom:3em;
            }
        }
        
        &.cs_slider {
            
        }
    
        &.test_slider {
            
        }
    
        &.ins_slider {
        
            .single-staff & {
        
                > .inner_bg {
                    padding-bottom:8em;
                }
            }
        }
        
        &.staff_grid {
            
            h2 {
                margin-bottom:30px;
            }
            
            .righted {
                text-align:left !important;
            }
            
            > .inner_bg {
                padding-right:20px;
                padding-left:20px;
            }
            
            .team_grid {
                grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
                grid-column-gap: 20px;
            }
        }
        
        &.accordion_content {
            
            > .inner_bg {
                padding-right:20px;
                padding-left:20px;
            }
        }
        
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .content_section_inner.flex_layouts {
        
        > .inner_bg {
            padding:4em 20px;
        }
        
        &.two_col_content_img {
        
            &.flip {
            
                .cont {
                    margin-left:0;
                    margin-right: 5.5%;
                }
            
                .media {
                    margin-right:0;
                    margin-left: 5.5%;
                }
            }
        
            .cont {
                padding:0 5.5% 0 0;
            }
        
            .media {
                
            }
        }
        
        &.bg_img_inset_cont {
        
            .inner_content {
                padding:15em 0 4em;
            }
        }
        
        &.staff_grid {
        
            .team_grid {
                grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    .content_section_inner.flex_layouts {
        
        &.staff_grid {
        
            .team_grid {
                grid-template-columns: repeat(auto-fit, minmax(240px,1fr));
            }
        }
    }
    
}

/**********************************************************************************************/
/***** FLEX LAYOUTS (MODULAR LANDING PAGE TEMPLATE) *******************************************/
/**********************************************************************************************/
.mod_flex_layouts_wrap {
    
    
    .content_section {
        background-repeat:no-repeat;
        background-position:center top;
        background-size:cover;
    }
}

.content_section_inner.mod_flex_layouts {
    width:100%;
    max-width:none;
    padding:0;
    
    h1,h2,h3 {
        margin-top:0;
        font-family:$roboto_slab;
        color:$green;
    }
    
    h1 {
        font-size:3.25rem;
    }
    
    h2 {
        font-size:2.25rem;
    }
    
    > .inner_bg {
        padding:6em 0;
    }
    
    &.standard_layout {
        
        &.invert {
            
            * {
                color:$white !important;
            }
        }
        
        > .inner_bg {
            padding:5em 0 4em;
        }
        
        .entry-content {
            display:flex;
            justify-content:flex-start;
            align-items:flex-start;
            padding:0;
            
            &.two_col {
                
                .col1,
                .col2 {
                    display:block;
                    flex:0 0 45%;
                }
                
                .col2 {
                    margin-left:10%;
                }
            }
            
            h1,h2,h3 {
                color:$green;
            }
        }
        
        .col1 {
            flex:0 0 100%;
        }
        
        .col2 {
            display:none;
        }
    }
    
    &.bg_img_left_cont {
        
        * {
            color:$white;
        }
        
        h1,h2 {
            font-family:$roboto_slab;
            font-size:3.25rem;
        }
    }
    
    &.icon_list_buckets {
        
        .buckets_wrap {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(calc(50% - 40px),1fr));
            grid-template-rows: 1fr;
            grid-auto-rows: auto;
            grid-column-gap: 80px;
            grid-row-gap: 80px;
            padding:0;
        }
        
        .bucket {
            
        }
        
        .icon {
            display:block;
            width:100px;
            margin:0 auto 1.5em;
            
            img {
                display:block;
                width:100%;
            }
        }
        
        h3 {
            margin:0 0 0.5em;
            text-align:center;
            font-size:2rem;
            color:$white;
        }
        
        .list_items {
            margin:0;
            padding:0;
            
            li {
                margin:0;
                padding:15px 0;
                border-top:1px solid $green;
                color:$white;
            }
        }
    }
    
    
    &.cta_bar {
        
        > .inner_bg {
            padding:4em 0;
        }
        
        .heading {
            
        }
        
        .cta {
            text-align:center;
        }
        
        h2 {
            margin:0;
            color:$white;
        }
        
        .btn {
            margin:0;
        }
    }
    
    &.insight_list {
        
        
        .wrap_link {
            display:block;
            margin:0 0 20px;
            text-decoration:none;
            
            &:hover {
                
                
                .img > div {
                    transform:scale(1.05);
                }
                
                h3 {
                    color:$yellow;
                }
            }
        }
        
        .inner {
            display:flex;
            justify-content:flex-start;
            align-items:flex-start;
        }
        
        .img {
            flex:0 0 30%;
            overflow:hidden;
            
            > div {
                padding-bottom:50%;
                background-repeat:no-repeat;
                background-position:center center;
                background-size:cover;
                transition:all 0.3s;
            }
        }
        
        .cont {
            flex:0 0 calc(70% - 2rem);
            margin:0 0 0 2rem;
            padding:10px 0 0;
            border-top:1px solid $green;
            
            p {
                color:$white;
                font-size:0.95em;
                
                &.meta {
                    font-size:90%;
                }
            }
            
            h3 {
                margin:0.75em 0 0.5em;
                color:$green;
                transition:all 0.3s;
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    .content_section_inner.mod_flex_layouts {
        padding:0 20px;
    
        h1 {
            font-size:2.5rem;
        }
    
        h2 {
            font-size:1.5rem;
        }
    
        > .inner_bg {
            padding:4em 0;
        }
        
        &.standard_layout {
        
            > .inner_bg {
                padding:3em 0 2.5em;
            }
        
            .entry-content {
                flex-wrap:wrap;
                
                &.two_col {
                
                    .col1,
                    .col2 {
                        flex:0 0 100%;
                    }
                    
                    .col1 {
                        margin-bottom:0.5em;
                    }
                
                    .col2 {
                        margin-left:0;
                    }
                }
            }
        }
        
        &.bg_img_left_cont {
            @include background-opacity($gray, 0.8);
            
            * {
                color:$white !important;
            }
            
            h1,h2 {
                font-size:2.5rem;
            }
        }
        
        &.icon_list_buckets {
        
            .buckets_wrap {
                grid-template-columns: repeat(auto-fit, minmax(calc(100% - 40px),1fr));
                grid-column-gap: 0;
                grid-row-gap: 50px;
            }
        }
        
        &.cta_bar {
        
            > .inner_bg {
                padding:3em 0;
            }
            
            .heading {
                text-align:center;
                margin:0 0 30px;
            }
        }
    
        &.insight_list {
            
            .wrap_link {
                margin:0 0 50px;
            }
            
            .inner {
                flex-wrap:wrap;
            }
        
            .img {
                flex:0 0 100%;
                margin:0 0 15px;
            }
        
            .cont {
                flex:0 0 100%;
                margin:0;
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .content_section_inner.mod_flex_layouts {
        
        &.bg_img_left_cont {
            @include background-opacity($gray, 0.6);
            
            * {
                color:$white !important;
            }
        }
        
        &.icon_list_buckets {
        
            .buckets_wrap {
                grid-template-columns: repeat(auto-fit, minmax(calc(50% - 20px),1fr));
                grid-column-gap: 40px;
            }
        }
    }
    
}

@media screen and (min-width: 1025px) and (max-width: #{$bp_full}) {
    
    
    
}