/**********************************************************************************************/
/***** UTILITY ********************************************************************************/
/**********************************************************************************************/
/* ---------------------------------------------------------------
   HELPER CLASSES
--------------------------------------------------------------- */
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }

.top_barred {
    position:relative;
    padding-top:1px !important;
    padding-right:0 !important;
    
    &:before {
        position:absolute;
        left:0;
        top:0;
        width:100%;
        max-width:236px;
        height:8px;
        background: $green;
        content:'';
    }
    //border-top:8px solid $green;
    
    h2,h3 {
        font-family:$roboto_slab !important;
    }
    
    h3 {
        margin-top:0.75em;
        font-size:1.8em;
    }
}


.alignleft,
.alignright {
    float:left;
    margin:0 1.26em 1.26em 0;
    width:auto;
    max-width:60%;
    height:auto;
}

.alignright {
    float:right;
    margin:0 0 1.26em 1.26em;
}

.alignnone,
.aligncenter {
    width:calc(100% + 100px);
    max-width:calc(100% + 100px);
    margin:1.26em -50px;
    height:auto;
    text-align:center;
}

figure {
    display:block;
    width:100%;
    margin:2em auto;
    
    &.wp-block-image,
    &.wp-block-embed {
        margin:0 auto 1.26em;
    }
    
    &.wp-caption {
        margin-bottom:10px;
    }
    
    .single-post &,
    .single-casestudy & {
        width:calc(100% + 100px);
        margin:2em -50px;
        
        &.wp-block-image,
        &.wp-block-embed {
            margin:2em -50px;
        }
        
        &.alignleft {
            width:auto;
            margin-left:-50px;
        }
        
        &.alignright {
            width:auto;
            margin-right:-50px;
        }
        
        &.aligncenter {
            figcaption {
                text-align:center;
            }
        }
    }
}

iframe {
    //max-width:100% !important;
}

.vid_cont {
    position:relative;
    margin:3em 0;
    background-color:transparent;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    z-index:10;
}

.video_container {
    position:relative;
    padding-bottom:56.25%;
    //padding-bottom:62.5%;
    /*padding-top:30px;*/
    margin:0 0 0 0;
    width:100%;
    height:0;
    opacity:1;
    visibility:visible;
    overflow:hidden;
    transition:opacity 0.3s;
    
    &.off {
        opacity:0;
        visibility:hidden;
    }
}
.video_container iframe,
.video_container object,
.video_container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.to_cols_3 {
    column-count:3;
    column-gap: 74px;
    
    > li {
        list-style-position: inside;
        break-inside: avoid;
    }
}

.pos_rel {
    position:relative !important;
}
.float_left {
	float:left !important;
}
.float_right {
	float:right !important;
}
.of_hidden {
    overflow:hidden !important;
}
.nomargin {
    margin:0 !important;
}
.nomargin_top {
    margin-top:0 !important;
}
.nomargin_bottom {
    margin-bottom:0 !important;
}
.large_margin_top {
    margin-top:100px !important;
}
.large_margin_bottom {
    margin-bottom:100px !important;
}
.tall_pad {
    padding-top:100px !important;
    padding-bottom:100px !important;
}
.short_pad {
    padding-top:30px !important;
    padding-bottom:30px !important;
}
.nopadding {
    padding:0 !important;
}
.nopadding_left {
    padding-left:0 !important;
}
.nopadding_right {
    padding-right:0 !important;
}
.nopadding_lr {
    padding-left:0 !important;
    padding-right:0 !important;
}
.nopadding_tb {
    padding-top:0 !important;
    padding-bottom:0 !important;
}
.nopadding_top {
    padding-top:0 !important;
}
.nopadding_bottom {
    padding-bottom:0 !important;
}
.small_padding_bottom {
    padding-bottom:30px !important;
}
.large_padding_bottom {
    padding-bottom:100px !important;
}
.small_padding_top {
    padding-top:30px !important;
}
.large_padding_top {
    padding-top:100px !important;
}
.nobullets {
    list-style:none !important;
}
.upper {
    text-transform:uppercase !important;
}
.lower {
    text-transform:none !important;
}
.centered {
    text-align:center !important;
}
.lefted {
    text-align:left !important;
}
.righted {
    text-align:right !important;
}
.blockit {
    display:block !important;
}
.flex_vert_cent {
    display:flex !important;
    align-items:center !important;
}
.width_cap {
    max-width:1008px;
}

.show_on_mobile {
	display:none !important;
}
.hide_on_mobile {
	display:block !important;
}

.show_on_tablet {
	display:none !important;
}
.hide_on_tablet {
	display:block !important;
}

@media only screen and (max-width: 767px) {
    
    .alignnone,
    .aligncenter {
        width:100%;
        max-width:100%;
        margin:1.26em 0;
    }
    
    .flex_wrap_center {
        display:block;
    }
    
    .flexrow {
        display:flex;
        
        &.noflex_m {
            display:block;
            
            .flex_i + .flex_i {
                margin-left:0;
            }
        }
    
    }
    
    .to_cols_3 {
        column-count:1;
    }
    
    .show_on_mobile {
        display:block !important;
    }
    .hide_on_mobile {
        display:none !important;
    }
    
    .large_padding_bottom {
        padding-bottom:60px !important;
    }
    .large_padding_top {
        padding-top:60px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    
    .show_on_tablet {
        display:block !important;
    }
    .hide_on_tablet {
        display:none !important;
    }
    
    .nopadding_tablet {
        padding:0 !important;
    }
    
}