/**********************************************************************************************/
/***** RESET **********************************************************************************/
/**********************************************************************************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/**********************************************************************************************/
/***** STRUCTURAL *****************************************************************************/
/**********************************************************************************************/
html {
    height:100%;
}
body {
    height:auto !important;
    min-height:100%;
    //padding-top:1px;
    box-sizing:border-box;
    font-family: $roboto;
    font-weight: $normal;
    font-size: 19px;
	line-height: 1.26em;
    color: $gray;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    background: $white;
}

/*body.in_search {
    #main,
    .site-title,
    #primary-navigation,
    #colophon {
        opacity: 0.2;
    }
}*/

#page {
	width:100%;
	height:100%;
	margin:0 auto;
	
	&.hide_primary_nav {
	    
	    .site-header .header_right {
	        display:none;
	    }
	}
}

#main {
    width:100%;
    margin:$header_h 0 0;
    background:$white;
    transition:margin 0.3s;
    
    .landingpage-noheader & {
        margin:0 !important;
    }
    
    &.has_alert {
        margin-top:166px;
        
        &.scrolling {
            margin-top:146px;
        }
    }
}

#primary {
    position:relative;
    z-index:9;
}

#secondary {
    padding:0;
    
    /*.body-gatedcontent-preform & {
        display:none !important;
    }*/
}

#content {
    position:relative;
    width:100%;
    //min-height:1800px; /****************** TEMP FOR DEV *****/
    margin:0 auto;
    background:none;
    box-sizing:border-box;
    z-index:10;
    overflow:hidden;
    
    > article {
        
        > .gform_wrapper {
            padding-top:0;
            width:calc(100% - 80px);
            max-width:1206px;
            margin:0 auto 60px;
        }
    }
    
    .single-project & {
        background:$gray;
    }
}

#content_wrap {
    
}

.entry-content_wrap {
    position:relative;
    padding:2em 0 0 0;
    
    .single-casestudy & {
        padding:3em 0 0 0;
    }
    
    .post-type-podcast & {
        padding:0;
    }
    
    .single-jobs & {
        padding:0;
    }
    
    .single-services & {
        
        .crumb {
            position:relative;
            top:0;
        }
    }
}

.crumb {
        display:block;
        position:absolute;
        left:0;
        top:2em;
        text-decoration:none;
        color:$green !important;
        font-family:$roboto_cond;
        font-size:0.95em;
        font-weight:normal;
        -webkit-font-smoothing: auto;
        
        &.arrowed {
            padding-left:24px;
            
            &:before {
                position:absolute;
                left:0;
                top:50%;
                width:17px;
                height:13px;
                background:none;
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                transition:all 0.3s;
                transform:translateY(-25%) rotate(180deg);
                filter:none;
            }
            
            &:hover {
                color:$white !important;
                
                &:before {
                    filter:brightness(0) invert(1);
                }
            }
        }
        
        .single-casestudy & {
            top:3.5em;
            
            &:hover {
                color:$green_dark !important;
                
                &:before {
                    filter:none;
                }
            }
        }
    }

.content_section {
    position:relative;
    width:100%;
    /*overflow:hidden;*/
    text-align:left;
    
    &.content_wrapper {
        z-index:10;
    }
    
    &.gray {
        background: $gray;
        
        * {
            color:$white;
        }
        
        table td {
            color:$gray;
        }
    }
    
    &.gray_mid {
        background: $gray_mid;
        
        * {
            color:$white;
        }
        
        table td {
            color:$gray;
        }
    }
    
    &.gray_dark {
        background: $gray_dark;
        
        * {
            color:$white;
        }
        
        table td {
            color:$gray_dark;
        }
    }
    
    &.green {
        background: $green;
        
        * {
            color:$white;
        }
        
        table td {
            color:$gray;
        }
    }
    
    &.green_dark {
        background: $green_dark;
        
        * {
            color:$white;
        }
        
        table td {
            color:$gray;
        }
    }
    
    &.white {
        background: $white;
    }
    
    &.invert {
        > * {
            color: $white;
        }
        
        table td {
            color:$gray;
        }
    }
}

.content_section_inner {
    width:calc(100% - 40px);
    max-width:1420px;
    margin:0 auto;
    padding:60px 0;
    text-align:left;
    box-sizing:border-box;
    background-color: transparent;
    
    &.full {
        width:100%;
        max-width:none;
    }
    &.narrow {
        width:75%;
        max-width:calc(1420px * 0.75);
    }
    &.narrower {
        width:65%;
        max-width:calc(1420px * 0.65);
    }
    
    .flex_layouts {
        padding-bottom:0;
    }
}

.wysiwyg {
    text-align:left;
    
    &.narrow {
        width:80%;
        margin:0 auto;
    }
    &.narrower {
        width:65%;
        margin:0 auto;
    }
}

@media screen and (max-width: #{$bp_full}) {
    
    .entry-content_wrap {
        
        .crumb {
            position:relative;
            top:0;
            left:0;
            margin:0 0 2em;
            
            .single-casestudy & {
                top:0;
            }
        }
    }
    
}

@media screen and (max-width: #{$bp_small}) {
    
    #main {
        margin:0;
        
        .home & {
            margin:0;
        }
        
        &.has_alert {
            margin-top:0;
        
            &.scrolling {
                margin-top:0;
            }
        }
    }
    
    #primary {
        float:none;
        width:100%;
        box-sizing:border-box;
        margin:0;
        //padding:40px 0;
    }
    #secondary {
        float:none;
        width:100%;
        margin-top:60px;
        margin-bottom:60px;
        padding:0;
        
        > .inner {
            min-height:0;
            width:100%;
            margin-left:0;
        }
    }
    
    .content_section {
        
    }
    
    .content_section_inner {
        padding-top:60px;
        padding-bottom:30px;
    }
    
    .content_section_inner,
    .content_section_inner.narrow,
    .content_section_inner.narrower {
        width:100%;
        padding-left:20px;
        padding-right:20px;
    }
    
    /*.entry-content_wrap {
        
        .crumb {
            position:relative;
            top:0;
            left:0;
            margin:0 0 2em;
            
            .single-casestudy & {
                top:0;
            }
        }
    }*/
    
}

@media screen and (max-width: 480px) {
    
    .content_section_inner,
    .content_section_inner.narrow,
    .content_section_inner.narrower {
        width:100%;
        padding-left:20px;
        padding-right:20px;
    }
    
}


@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #main {
        margin:0;
        
        .home & {
            margin:0;
        }
    }
    
    /*.entry-content_wrap {
        
        .crumb {
            position:relative;
            top:0;
            left:0;
            margin:0 0 2em;
            
            .single-casestudy & {
                top:0;
            }
        }
    }*/
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_full}) {
    
    .wysiwyg {
        
        /*br {
            display:none;
        }*/
        
        &.narrow {
            width:85%;
        }
        &.narrower {
            width:85%;
        }
    }
    
    .content_section_inner {
        
    }
    
    .content_section_inner.narrow,
    .content_section_inner.narrower {
        width:85%;
    }
    
}

@media screen and (min-width: 1025px) and (max-width: #{$bp_full}) {
    
    .content_section_inner {
        
    }
    
}

/* SPECIFIC FOR BRINGING IN MOBILE NAV AND HAMBURGER */
@media screen and (max-width: #{$bp_mid}) {
    
    body.mobile_nav_open {
        overflow:hidden;
    }
    
    #site-inner {
        position:relative;
    }
    
}