/**********************************************************************************************/
/***** WIDGETS ********************************************************************************/
/**********************************************************************************************/
#secondary,
.service_sidebar {
    
    
    .single-post & {
        padding-top:70px;
    }
    
    .single-casestudy & {
        padding-top:8px;
    }
    
    .post-type-podcast & {
        padding-top:0;
    }
    
    .widget {
        margin:0 0 6em;
        padding:0;
        border:none;
        
        .services_list {
            margin-top:1.5em;
            
            > li {
                margin:0 0 0.75em;
                font-family:$roboto_cond;
                font-size:1em;
                font-weight:$normal;
            }
            
            a {
                display:block;
                color:$gray;
                text-decoration:underline;
                text-decoration-color:rgba(125,156,145,0);
                text-underline-offset:0.3em;
                transition:all 0.3s;
            
                &:hover {
                    color:$green;
                    text-decoration-color:$green;
                    text-underline-offset:0.15em;
                }
            }
        }
        
        &.locations {
            h4 {
                margin:0 0 0.25em;
                font-size:0.95em;
                font-weight:$bold;
            }
            
            p {
                margin:0;
                font-size:0.95em;
                line-height:1.6em;
            }
            
            .map_directions {
                position:relative;
                display:block;
                margin:0 0 1.25em;
                padding:0 0 0 28px;
                line-height:1.33em;
                color:$green;
                text-decoration:underline;
                text-decoration-color:rgba(125,156,145,0);
                text-underline-offset:0.3em;
                transition:all 0.3s;
                
                &:before {
                    position:absolute;
                    left:0;
                    top:0;
                    width:18px;
                    height:24px;
                    content:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 24'%3e%3cpath d='M1.4,13.9 L1.4,13.9 C1.5,14 1.6,14.1 1.6,14.2 L8.1,23.6 C8.3,23.8 8.7,24 9,24 C9.3,24 9.6,23.8 9.8,23.6 L16.3,14.2 C16.4,14.1 16.4,14 16.5,13.9 L16.5,13.8 L16.5,13.8 C17.7,12 18.2,9.8 17.9,7.6 C17.6,5.5 16.5,3.5 14.9,2.1 C13.3,0.8 11.2,0 9,0 C6.8,0 4.7,0.8 3.1,2.2 C1.5,3.6 0.4,5.6 0.1,7.7 C-0.2,9.9 0.3,12.1 1.4,13.9 L1.4,13.9 Z M9,5.3 C10.5,5.3 11.9,6.2 12.5,7.6 C13.1,9 12.8,10.6 11.7,11.7 C10.6,12.7 9,13 7.6,12.5 C6.2,11.9 5.2,10.5 5.2,9 C5.2,6.9 6.9,5.3 9,5.3 Z' fill='%237D9C91'%3e%3c/path%3e%3c/svg%3e");
                }
                
                &:hover {
                    text-decoration-color:$green;
                    text-underline-offset:0.15em;
                    
                    &:before {
                        
                    }
                }
            }
        }
        
        .btn {
            padding:14px 32px !important;
            font-size:16px !important;
        }
        
        //PLUGGEDIN
        .episodes {
            margin:0 0 0.5em;
            padding-right:20%;
            
            li {
                border-bottom:1px solid $green_dark;
                
                &:first-of-type {
                    border-top:3px solid $green_dark;
                }
            }
            
            a {
                display:block;
                position:relative;
                padding:15px 15px 15px 45px;
                color:$green;
                font-family:$roboto_cond;
                
                &:before {
                    position:absolute;
                    left:0;
                    top:50%;
                    transform:translateY(-50%);
                    width:30px;
                    height:30px;
                    background:$yellow url('../../images/soundcloud-play.svg') no-repeat 55% center;
                    background-size:9px auto;
                    content:'';
                    border-radius:50%;
                }
                
                &:hover {
                    color:$green_dark;
                }
            }
        }
        
        //GATED, WEBINAR CONTENT
        .widget-smartcta {
        
            &.gated-sidebar-cta {
                
                
                .panel__inner {
                    
                    
                    .single-post & {
                        padding-right:31.6%;
                    }
                }
            }
            
            .panel__inner {
                
            }
            
            .wrap_link {
                display:block;
                
                &:hover {
                    
                    .panel__image img {
                        transform:scale(1.1);
                    }
                    
                    .widget-title {
                        color:$white !important;
                    }
                }
            }
            
            .widget-title {
                margin:0 0 1em;
                color:$white;
                transition:all 0.3s;
                
                .single-post & {
                    color:$gray;
                }
            }
            
            .panel__image {
                margin:0 0 20px -10px;
                width:calc(100% + 10px);
                background:none;
                z-index:10;
                                                
                .img_wrap {
                    position:relative;
                    overflow:hidden;
                    transition:all 0.3s;
                }
                
                img {
                    display:block;
                    width:100%;
                    transition:all 0.3s;
                }
            }
            
            .panel__content {
                background:none;
                margin:0;
                padding:0 10px 0 0;
                font-size:1em;
                
                p {
                    margin:0;
                    color:$white;
                    
                    .single-post & {
                        color:$gray;
                    }
                }
            }
            
            .entry-summary {
                margin:0.75em 0 0;
                font-size:0.84em;
            }
            
            .btn {
                margin:2em 0 0;
            }
            
            .wrap_link {
                display:block;
                
                &:hover {
                    
                    .panel__image img {
                        transform:scale(1.1);
                    }
                    
                    .widget-title {
                        color:$white !important;
                        
                        .single-post & {
                            color:$gray !important;
                        }
                    }
                    
                    .btn {
                        color:$white !important;
                        background:$green_dark !important;
                    }
                }
            }
        }
        
        &.widget_rp4wp_related_posts_widget {
            padding:0;
            border:none;
            
            .post-type-podcast & {
                display:none;
            }
        }
        
        //NORMAL (NON-SMARTCTA)
        .panel__inner {
            
        }
        
        .panel__content {
            background:none;
            
            .sidebar-form {
                padding:0 40px 0 0;
                
                .gform_body {
                    padding-right:20px;
                }
                
                .single-post &,
                .page-template-page-pluggedin & {
                    padding:0 42% 0 0;
                    
                    .gform_body {
                        padding-right:0;
                    }
                }
            }
        }
    }
    
    .widget-title,
    .widget-label {
        font-family:$roboto_cond;
        font-size:1em;
        font-weight:$bold;
        color:$green;
        margin:0 0 1em;
        
        a {
            color:$white;
            
            &:hover {
                color:$green;
            }
        }
    }
    
    .widget-label {
        font-size:1.05em;
    }
    
    .rp4wp-related-posts {
        overflow:visible;
        
        .widget-title {
            margin:0 0 1em;
        }
    }
    
    .rp4wp-posts-list {
        float:none;
        margin:0 !important;
        padding:0 !important;
        list-style:none !important;
        border:none;
        
        li {
            margin:0 0 20px -10px;
            width:calc(100% + 10px);
            list-style-type:none;
        }
        
        .wrap_link {
            display:flex;
            justify-content:flex-start;
            align-items:center;
            height:100%;
            padding:0 40px 0 0;
            text-decoration:none;
            
            &:hover {
                
                h4 {
                    color:$green;
                }
                
                .img > div {
                    transform:scale(1.1);
                }
            }
        }
        
        h4 {
            flex:0 0 calc(100% - 140px);
            margin:0 0 0 20px;
            font-family:$roboto_cond;
            font-weight:$normal;
            font-size:1em;
            -webkit-font-smoothing: auto;
            transition:all 0.3s;
        }
        
        .img {
            flex:0 0 120px;
            height:120px;
            overflow:hidden;
            
            > div {       
                height:100%;     
                background-color:$green;
                background-repeat:no-repeat;
                background-position:center center;
                background-size:cover;
                transition:all 0.3s;
            }
        }
    }
}

@media only screen and (max-width: #{$bp_small}) {
    
    #secondary {
    
        .widget {
            
            //GATED, WEBINAR CONTENT
            .widget-smartcta {
                
                &.gated-sidebar-cta {
                    
                    .panel__inner {
                        
                        .single-post & {
                            padding-right:20%;
                        }
                    }
                }
                
                .panel__image {
                    margin:0 0 20px;
                    width:100%;
                }
            }
            
            .panel__content {
                
                .sidebar-form {
                    
                    .single-post & {
                        padding:0 20% 0 0;
                    }
                }
            }
        }
        
        .rp4wp-posts-list {
            
            li {
                margin-left:0;
                width:100%;
            }
        }
    }
    
}

@media only screen and (max-width: 540px) {
    
    #secondary {
    
        .widget {
            
            //GATED, WEBINAR CONTENT
            .widget-smartcta {
                
                &.gated-sidebar-cta {
                    
                    .panel__inner {
                        
                        .single-post & {
                            padding-right:0;
                        }
                    }
                }
            }
            
            .panel__content {
                
                .sidebar-form {
                    
                    .single-post & {
                        padding:0;
                    }
                }
            }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #secondary {
    
        .widget {
            
            //GATED, WEBINAR CONTENT
            .widget-smartcta {
                
                &.gated-sidebar-cta {
                    
                    .panel__inner {
                        
                        .single-post & {
                            padding-right:0;
                        }
                    }
                }
                
                .btn {
                    padding:14px 24px;
                }
            }
            
            .panel__content {
                
                .sidebar-form {
                    padding:0;
                    
                    .single-post & {
                        padding:0;
                    }
                }
            }
            
            .panel__content {
                
                .sidebar-form {
                    
                    .gform_body {
                        padding-right:0;
                    }
                
                    .single-post & {
                        padding:0;
                    
                        .gform_body {
                            padding-right:0;
                        }
                    }
                }
            }
        }
        
        .rp4wp-posts-list {
            
            .wrap_link {
                padding:0;
                align-items:flex-start;
            }
        
            h4 {
                flex:0 0 calc(100% - 100px);
            }
        
            .img {
                flex:0 0 80px;
                height:80px;
            }
        }
    }
    
}