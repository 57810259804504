/**********************************************************************************************/
/***** HEADER *********************************************************************************/
/**********************************************************************************************/
#alertbar {
    background:$green;
    height:46px;
    overflow:hidden;
    color:$white;
    text-decoration:none;
    
    .inner {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        width:100%;
        height:100%;
        max-width:1420px;
        margin:0 auto;
        
        &:hover {
            .arrow {
                margin-left:20px;
            }
        }
    }
    
    * {
        box-sizing:border-box;
    }
    
    .heading {
        flex:0 0 auto;
        display:block;
        margin:0;
        font-family:$roboto_slab;
        font-size:0.8em;
        font-weight:$bold;
        color:$white;
    }
    
    .arrow {
        flex:0 0 7px;
        display:block;
        width:7px;
        height:12px;
        margin-left:10px;
        background:transparent url('../../images/slider-nav-caret.svg') no-repeat center center;
        background-size:cover;
        transition:all 0.3s;
        filter:brightness(0) invert(1);
    }
}

#mobile_nav_toggle {
    display:none;
    position:absolute;
    right:0;
    top:35px;
    width:24px;
    height:20px;
    margin:0 auto;
    cursor: pointer;
    outline:none;
    border:none;
    background:none;
    z-index:1010;
    transition:all 0.3s;
    
    &.open {
        
    }
    
    .inner {
        position:absolute;
        right:0;
        top:0px;
        width:24px;
        height:20px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
    }
}
#mobile_nav_toggle span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $green;
    border-radius:0;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}
#mobile_nav_toggle.open span {
    background: $green;
}
#mobile_nav_toggle span:nth-child(1) {
    top: 0px;
}
#mobile_nav_toggle span:nth-child(2),
#mobile_nav_toggle span:nth-child(3) {
    top: 9px;
}
#mobile_nav_toggle span:nth-child(4) {
    top: 18px;
}
#mobile_nav_toggle.open span:nth-child(1) {
    top: 9px;
    width: 0%;
    left: 50%;
}
#mobile_nav_toggle.open span:nth-child(2) {
  transform: rotate(45deg);
}
#mobile_nav_toggle.open span:nth-child(3) {
  transform: rotate(-45deg);
}
#mobile_nav_toggle.open span:nth-child(4) {
    top: 9px;
    width: 0%;
    left: 50%;
}

#mobile_nav_tray {
    display:none;
    position:fixed;
    top:0;
    bottom:0;
    width:100%;
    height:100vh;
    padding:$header_h_mobile 20px 60px;
    box-sizing:border-box;
    overflow:scroll;
    z-index:990;
    @include background-opacity($gray_light, 0.98);
    
    .mobile_nav_open & {
        
    }
    
    .inner {
        height:100%;
        overflow:scroll;
    }
}

.site-header {
    //position:absolute;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    background:$gray_light;
    height:$header_h;
    transition:all 0.3s linear;
    z-index:1000;
    
    &.has_alert {
        height:166px;
        
        &.scrolling {
            height:136px;
        }
        
        .header_inner {
            height:calc(100% - 46px);
        }
        
        #search_container {
            top:calc(-100% - 66px);
            
            &.active {
                top:0;
            }
        }
    }
    
    .home & {
        
    }
    
    .mobile_nav_open & {
        background:none;
    }
    
    .landingpage-noheader & {
        display:none;
    }
    
    .grid {
        height:100%;
        
        > div {
            height:100%;
        }
    }
    
    .grid-pad {
        padding-top:0;
    }
    
    &.scrolling {
        height:$header_h_mobile;
    }
}

.header_inner {
    position:relative;
    width:calc(100% - 40px);
    max-width:1420px;
    height:100%;
    margin:0 auto;
    box-sizing: border-box;
}

.site-title {
    display:flex;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    height:100%;
    max-width:100%;
    margin:0;
    z-index:1000;
    box-sizing:border-box;
    vertical-align:top;
    transition:margin 0.5s linear;
    transform:none;
    
    .scrolling & {
        
        .mobile_nav_open & {
            
        }
        
        > a {
            
        }
    }
    
    .mobile_nav_open & {
        
    }
    
    > a {
        display:block;
        flex:0 0 280px;
        position:relative;
        
        img {
            display:block;
            width:100%;
        }
        
        &:hover {
            text-decoration:none;
        }
    }
}

.tagline {
    display:flex;
    justify-content:flex-end;
    align-items:center;
    padding:0 0 0 100px;
    
    p {
        display:inline-block;
        margin:0;
        font-family:$roboto_cond;
        font-size:0.95em;
        color:$green;
        font-weight:$normal;
        line-height:1.26em;
    }
}

.header_right {
    position:relative;
    margin:0;
    padding:0 60px 0 0;
    display:flex;
    justify-content:flex-end;
    align-items:center;
    flex-wrap:nowrap;
    height:100%;
    //z-index:1000;
    transition:all 0.3s linear;
}

@media only screen and (max-width: #{$bp_mid}) {

    #mobile_nav_toggle {
        display:block;
    }
    
    .site-header {
        position:relative;
        height:$header_h_mobile;
    }
    
    .site-title {
        height:auto;
        margin:22px 0 0;
        
        > a {
            flex:0 0 180px;
        }
    }
    
    .tagline {
        justify-content:flex-start;
        padding:0;
        
        &.kill_mobile {
            display:none;
        }
    
        p {
            font-size:0.74em;
        }
    }
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #mobile_nav_toggle {
        top:50px;
    }
    
    #mobile_nav_tray {
        padding-top:$header_h;
    }
    
    .site-header {
        height:$header_h;
        
        &.scrolling {
            height:$header_h;
        }
    }
    
    .site-title {
        height:auto;
        margin:22px 0 0;
        
        > a {
            flex:0 0 280px;
        }
    }
    
    .tagline {
        margin-top:5px;
    }
    
}

@media only screen and (max-width: #{$bp_small}) {
    
    .site-title {
        
        > a {
            flex:0 0 150px;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    
    .site-title {
        
        > a {
            flex:0 0 180px;
        }
    }
    
    .tagline {
        padding:0 0 0 60px;
    }
    
}

@media screen and (min-width: 1201px) and (max-width: #{$bp_full}) {
    
    .site-title {
        
        > a {
            flex:0 0 230px;
        }
    }
    
    .tagline {
        padding:0 0 0 60px;
    }
    
}

@media screen and (min-width: 1300px) {
    
    .tagline {
        padding:0 0 0 70px;
    }
    
}

@media screen and (min-width: 1421px) {
    
    .tagline {
        padding:0 0 0 30px;
    }
    
}

@media screen and (min-width: 1600px) {
    
    
    
}