/**********************************************************************************************/
/***** FOOTER *********************************************************************************/
/**********************************************************************************************/
#colophon.site-footer {
    position:relative;
    background: $gray;
    
    * {
        box-sizing:border-box;
    }
    
    .footer_inner {
        position:relative;
        margin:0 auto;
        padding:80px 0 0;
        width:100%;
        max-width:none;
        background:none;
        color:$white;
    }
    
    .footer_top {
        
        
    }
    
    .footer_bottom {
        margin-top:2.5em;
        padding:10px 0;
        border-top:1px solid $gray_light;
        max-width:none;
        
        > .grid {
            
        }
    }
    
    .logo_wrap {
        padding:0;
    }
    
    .cont_wrap {
        padding:0 0 0 20px;
        
        &.info {
            padding:0 20px 0 3%;
        }
    }
    
    .logo {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        width:100%;
        margin:6px 0 0;
        z-index:1000;
        box-sizing:border-box;
        vertical-align:top;
        transition:margin 0.5s linear;
        transform:none;
    
        > a {
            display:block;
            flex:0 0 268px;
            position:relative;
        
            img {
                display:block;
                width:100%;
            }
        }
    }
    
    .info_social_wrap {
        padding:0;
        text-align:right;
    }
    
    .info_social {
        display:flex;
        justify-content:flex-end;
        align-items:center;
    }
    
    .award_logos_wrap {
        display:flex;
        justify-content:flex-end;
        align-items:center;
        margin:0;
        
        img {
            display:block;
            max-height:60px;
            margin:0 0 30px 36px;
        }
    }
    
    p {
        margin:0;
        font-size:0.84em;
        line-height:1.625em;
        font-weight:$normal;
        color:$white;
        
        &.info {
            display:inline-block;
            margin:0 40px 0 0;
        }
        
        &.copyright {
            margin:0 0 0.25em;
            color:$gray_light2;
            text-align:right;
        }
        
        a {
            transition:all 0.3s;
            color:$white;
            text-decoration:underline;
            text-decoration-color:$gray;
            
            &:hover {
                color:$white;
                text-decoration-color:$white;
            }
        }
    }
    
    #menu-footer-legal-nav {
        display:block;
        margin:0;
        padding:0;
        
        li {
            display:block;
            margin:0;
            
            a {
                display:block;
                padding:0;
                font-size:0.84em;
                line-height:1.625em;
                font-weight:$normal;
                color:$gray_light2;
                transition:all 0.3s;
                text-decoration:underline;
                
                &:hover {
                    color:$white;
                }
            }
        }
    }
    
    .locs {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        
        .loc {
            flex:0 0 50%;
            padding-right:6%;
            box-sizing:border-box;
        }
        
        h5 {
            margin:0;
            font-family:$roboto;
            line-height:1.625em;
            font-size:0.84em;
            color:$white;
            text-transform:none;
            font-weight:normal;
            -webkit-font-smoothing: auto;
        }
    }
    
    .social_cont {
        display:inline-block;
        text-align:right;
        //margin-right:76px;
    }
    
    .social {
        display:inline-block;
        position:relative;
        width:40px;
        height:40px;
        border-radius:50%;
        margin:0 0 0 15px;
        background: $gray_mid;
        text-indent:-9999px;
        overflow:hidden;
        vertical-align:top;
        transition:all 0.3s;
        color: $white;
        
        span {
            display:block;
            text-align:left;
        }
        
        svg {
            display:block;
            position:absolute;
            left:50%;
            top:50%;
            margin:0;
            transition: all 0.2s;
            transform:translate(-50%,-50%);
            
            .shape {
                transition: all 0.3s;
                fill: $white;
            }
        }
        
        &:hover {
            background:$green_dark;
            
            svg {
                .shape2 {
                    fill:$green_dark;
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    
    #colophon.site-footer {
        
    }
    
}

@media only screen and (max-width: #{$bp_mid}) {
    
    #colophon.site-footer {
        
        .footer_top {
            padding:0 20px;
        }
    
        .footer_bottom {
            padding:10px 20px;
        }
        
        .footer_inner {
            padding:60px 0 0;
        }
        
        .logo {
            
            > a {
                flex:0 0 168px;
            }
        }
        
        .award_logos_wrap {
            
            img {
                display:block;
                max-height:42px;
            }
        }
    }
    
}

@media only screen and (max-width: #{$bp_small}) {
	
	#colophon.site-footer {
	    
	    .footer_inner {
            padding:40px 0 0;
        }
    
        .footer_top {
            
        }
    
        .footer_bottom {
            display:block;
            
            .grid > div {
                text-align:center;
            }
        }
        
        .award_logos_wrap {
            display:block;
            margin-top:30px;
            
            img {
                margin:0 0 30px;
            }
        }
        
        .info_social_wrap {
            text-align:left;
        }
    
        .info_social {
            display:block;
        }
        
        .locs {
            display:block;
            margin:0 0 2em;
        
            .loc {
                margin-top:2em;
            }
        
            h5 {
                margin:0;
                font-family:$roboto;
                line-height:1.625em;
                font-size:0.84em;
                color:$white;
                text-transform:none;
                font-weight:normal;
                -webkit-font-smoothing: auto;
            }
            
            a {
                text-decoration-color:$gray;
            }
        }
        
        .logo {
            
            > a {
                flex:0 0 180px;
            }
        }
        
        p {
            &.info {
                display:block;
                margin:0 0 10px;
            }
            
            &.copyright {
                margin:20px 0 0;
                text-align:center;
            }
            
            a {
                text-decoration-color:$white;
            }
        }
        
        .social_cont {
            text-align:left;
            margin:2em 0 0;
        }
        
        .social {
            margin:0 20px 0 0;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}