/**********************************************************************************************/
/***** TYPOGRAPHY *****************************************************************************/
/**********************************************************************************************/
* {
    ::-moz-selection {
        background: rgba(96,132,119,1);
        color:$white;
    }
    ::selection {
        background: rgba(96,132,119,1);
        color:$white;
    }
}

hr {
    height: 1px;
    background: $gray;
    border: none;
    margin: 20px 0;
    
    .gray_mid &,
    .gray & {
        background:$white;
    }
}

a {
	text-decoration:none;
	color: $gray;
	transition:all 0.3s;
	
	&:hover {
	    color: $green;
	}
}
.wysiwyg,
.entry-content {
    a {
        text-decoration:underline;
        
        color:$gray;
        
        &:hover {
            color:$green;
        }
        
        &.btn {
            text-decoration:none !important;
        }
    }
}

h1,h2,h3,h4,h5,h6 {
    margin:0 0 0.5em;
    font-family: $roboto_cond;
    font-weight:$bold;
    line-height:1.2em;
    color: $gray;
    
    &.white {
        color:$white !important;
    }
    
    &.green {
        color:$green !important;
    }
}

h1,
p.heading {
    font-size: 2.37em; /* 45px */
    
    &.mid_long {
        font-size: 2em;
    }

    &.long {
        font-size: 1.7em;
    }
}

h2 {
    margin:1.5em 0 0.5em;
    font-size: 1.26em; /* 24px */
}

h3 {
    margin:1.5em 0 0.5em;
    font-size:1.05em; /* 20px */
}

h4 {
    margin:1.5em 0 0.5em;
    font-size:1.05em; /* 20px */
    font-family:$roboto;
    font-weight:$medium;
}

h5 {
    margin:0 0 0.25em;
    font-size:1em;
    text-transform:uppercase;
    -webkit-font-smoothing: antialiased;
}
h6 {
    margin:0 0 0.25em;
    font-size:1em;
    font-weight:$normal;
    text-transform:uppercase;
}

sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.55em;
    line-height: 0;
    font-size: 60%;
}
sub { 
    top: 0.55em; 
}

.entry-content {

    &.cp_form {
        padding-right:5.56%;
        
        h2 {
            margin-top:0;
        }
    }

    /*.single-casestudy &,
    .single-services & {
        position:relative;
    }*/
    
    //naked formatted content lists
    ul,
    ol {
        margin: 0 0 1.2em 70px;
        list-style:decimal;
    }
    > ul {
        padding:0;
        list-style:none;
    }
    > ul li,
    > ol li {
        display: list-item; 
        margin-bottom:1.2em;
        font-size:1em;
    }
    > ol li {
        list-style: decimal;
        
        &::marker {
            font-size:80%;
            font-weight:$bold;
        }
    }
        
    > ul li {
        position:relative;
        
        &::marker {
            font-size:60%;
        }
    }
    
    > ul ul,
    > ul ol,
    > ol ul,
    > ol ol {
        margin:1.2em 0 0 20px;
    }
}

.wysiwyg {
    
    p {
        font-size:1em;
        
        &.h2 {
            @extend h2;
        }
        
        &.h3 {
            @extend h3;
        }
        
        &.gray {
            color:$gray;
        }
        
        &.gform_title {
            margin:3em 0 0.5em !important;
        }
        
        .btn {
            margin-bottom:0 !important;
        }
    }
    
    ul,
    ol {
        margin: 0 0 1.2em 70px;
        list-style:decimal;
    }
    ul {
        padding:0;
        list-style:disc;
    }
    ul li,
    ol li {
        display: list-item; 
        margin-bottom:1.2em;
        font-size:1em;
    }
    
    //nested
    ul ul,
    ul ol,
    ol ul,
    ol ol {
        margin:1.2em 0 0 20px;
    }
    
    ol li {
        list-style: decimal;
        
        &::marker {
            font-size:80%;
            font-weight:$bold;
        }
    }
        
    ul li {
        position:relative;
        //list-style:none;
        
        &::marker {
            font-size:60%;
        }
    }
    
    dl {
        @extend p;
        margin-top:1.5em;
    
        dt {
            font-weight:$bold;
        }
    
        dd {
            margin:0 0 1em;
            font-style:italic;
        }
    }
    .invert & {
        
    }
    
    p:last-of-type {
        //margin:0;
    }
    
    &.plast {
        
        p:first-of-type {
            margin-bottom:1.5em !important;
        }
        
        p:last-of-type {
            margin:0 !important;
        }
    }
}

p {
    margin-bottom:1.2em;
    line-height:1.26em;
    
    strong, b {
        font-weight:$bold;
    }
    
    &.h2 {
        @extend h2;
    }
    
    &.h3 {
        @extend h3;
    }
    
    &.gform_title {
        margin:3em 0 0.5em !important;
    }
}

strong, b {
    font-weight: $bold;
}
i, em {
    font-style:italic;
}

blockquote {
    display:block;
    position:relative;
    font-family:$roboto_cond;
    font-size:1.26em; /* 24px */
    line-height:1.17em;
    font-weight:$bold;
    margin:1.5em 0;
    padding:0;
    width:100%;
    box-sizing:border-box;
    border:none;
    color: $green;
    text-align:left;
    
    
    &.wp-block-quote {
        margin-top:1.5em;
        margin-bottom:1.5em;
        
        &:before {
            position:absolute;
            left:20px;
            top:0;
            width:8px;
            height:100%;
            background:$green;
            content:'';
        }
        
        p:before {
            display:none;
        }
    }
    
    
    
    &.white {
        color: $white;
        
        &:before {
            background:$white;
        }
    }
    
    p {
        position:relative;
        margin:0;
        padding:0 0 0 42px;
        
        &:before {
            position:absolute;
            left:20px;
            top:0;
            width:8px;
            height:100%;
            background:$green;
            content:'';
        }
    }
}
cite,
p.cite {
    display:block;
    @extend p;
    font-family:$roboto;
    font-size:16px !important;
    font-weight:$medium;
    font-style:normal;
    text-align:left;
    margin:1em 0 0 !important;
}

pre, code {
    font-family: Courier, monospace;
}

small {
    font-size:80% !important;
}

img{
	vertical-align: bottom;
}

figcaption,
.footnote,
caption,
.caption {
    display:inline-block;
    text-align:left;
    font-size:0.74em;
    font-family: $roboto_cond;
    font-weight:$normal;
    font-style:italic;
    color:$white;
    -webkit-font-smoothing: auto;
    
    a {
        
    }
    
    .single-post &,
    .single-casestudy & {
        color:$gray;
    }
}

caption,
figcaption,
.caption {
    margin-top:1em;
}

.mfp-content figcaption .mfp-title {
    margin-top:10px;
    padding-right:30px;
    padding-left:15px;
    border-left:2px solid $green_on_slate;
    font-style:normal;
}

.mfp-content .mfp-iframe-scaler .mfp-title {
    position:absolute;
    top:calc(100% + 20px);
    left:0;
    right:0;
    padding-right:30px;
    padding-left:15px;
    text-align:left;
    font-size:0.74em;
    font-family: $roboto_cond;
    font-weight:$normal;
    font-style:italic;
    color:$white;
    -webkit-font-smoothing: auto;
    border-left:2px solid $green_on_slate;
    font-style:normal;
}

.screen-reader-text {
    clip:rect(1px, 1px, 1px, 1px);
    border:0;
    -webkit-clip-path:inset(50%);
    clip-path:inset(50%);
    width:1px;
    height:1px;
    margin:-1px;
    overflow:hidden;
    position:absolute !important;
    font-family: $roboto;
    font-size:1em;
    font-weight: $normal;
    color: $gray;
    z-index:10000;
    opacity:0;
    transition:opacity 0.2s;
    word-wrap:normal !important;
}
.site-navigation .screen-reader-text {
    left:auto;
    right:10px;
}
.screen-reader-text:focus {
    clip:auto;
    opacity:1;
}

@media only screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .entry-content {

        &.cp_form {
            padding-right:0;
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}