/**********************************************************************************************/
/***** SEARCH *********************************************************************************/
/**********************************************************************************************/
#search_toggle {
    position:absolute;
    right:0;
    top:50%;
    margin:0;
    width:20px;
    height:20px;
    transform:translateY(-50%);
    cursor:pointer;
    z-index:1020;
    
    svg {
        position:absolute;
        left:0;
        top:0;
        
        #search-mag-glass {
            fill:$green;
            transition:fill 0.3s;
        }
        
        #search-close {
            display:none;
            fill:$white;
        }
    }
    
    &:hover {
        
    }
    
    &.active {
        svg {
            #search-mag-glass {
                display:none;
            }
        
            #search-close {
                display:block;
            }
        }
    }
    
    .mobile_nav_open & {
        //background-image: url('../../images/search-mag-glass-white.svg');
    }
}

#search_container {
    position:absolute;
    right:-24px;
    top:calc(-100% - 20px);
    height:100%;
    width:500px;
    transition: all 0.5s ease-in-out;
    background:$green;
    z-index:1020;
    
    &.active {
        top:0px;
    }
    
    .search-submit {
        display:none;
    }
    
    
}

#search_container_mobile {
    display:none !important;
}

#searchform {
    position:absolute;
    left:48px;
    right:64px;
    bottom:40%;
    transition:bottom 0.3s;
    
    .scrolling & {
        bottom:35%;
    }
    
    input[type='text'] {
        width:100%;
        padding:0;
        border:none;
        border-bottom:1px solid $white;
        line-height:30px;
        font-family: $roboto_cond;
        font-weight:$normal;
        border-radius:0;
        //border-left:1px solid rgba(255,255,255,0.5);
        -webkit-appearance:none;
        font-size: 0.95em; /* 18px */
        line-height:30px;
        letter-spacing:0.01em;
        color: $white;
        text-transform:normal;
        background:none;
        transition:all 0.3s;
    
        &:focus,
        &:valid {
            outline:none;
        }
        
        &:focus {
            border-left-color:rgba(255,255,255,0);
        }
    }
    
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(255,255,255,1);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(255,255,255,1);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(255,255,255,1);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba(255,255,255,1);
    }
}

#search_container_insights {
    width:100%;
    margin:0 0 40px -20px;
    padding:0;
    background:$gray;
}

#searchform_insights,
#searchform_mobile {
    position:relative;
    right:auto;
    left:0;
    bottom:auto;
    padding:13px 76px 12px 20px;
    
    input[type='text'] {
        width:100%;
        margin-top:1px;
        padding:0;
        border:none;
        border-bottom:2px solid $gray;
        line-height:28px;
        font-family: $roboto_cond;
        font-weight:$normal;
        border-radius:0;
        //border-left:1px solid rgba(255,255,255,0.5);
        -webkit-appearance:none;
        font-size: 0.95em;
        letter-spacing:0.01em;
        color: $white;
        text-transform:none;
        background:transparent;
        transition:all 0.3s;

        &:focus,
        &:valid {
            outline:none;
        }

        &:focus {
            border-bottom:2px solid rgba(255,255,255,0.5);
        }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(255,255,255,0.5);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(255,255,255,0.5);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(255,255,255,0.5);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba(255,255,255,0.5);
    }
    
    .search-submit {
        display:block;
        position:absolute;
        right:0;
        top:0;
        width:56px;
        height:56px;
        margin:0;
        padding:0;
        border-radius:0;
        border:none;
        overflow:hidden;
        text-indent:-9999px;
        background:$gray url('../../images/search-mag-glass-white.svg') no-repeat center center !important;
        background-size:28px auto;
        cursor:pointer;
        z-index:1020;
        
        &:hover{
            background-color:$green !important;
        }
    }
}

#search-results.search-results {
    position:absolute;
    right:0;
    top:100%;
    width:100%;
    background:$green_dark;
    
    &.loading {
        display:block;
        min-height:100px;
        background:$green_dark url(../../images/loading-white.svg) no-repeat center center;
    }
    
    .search-results-no-results {
        padding:40px 48px 40px 48px;
        color: $white;
        font-family: $roboto_cond;
        font-size:0.95em;
        font-weight:$normal;
        line-height:1.26em;
    }
    
    .search-result-item {
        display:block;
        padding:10px 48px 10px 48px;
        color: $white;
        font-family: $roboto_cond;
        font-size:0.95em;
        font-weight:$normal;
        line-height:1.26em;
        text-decoration:none;
        
        &.btn {
            display:inline-block;
            position:relative;
            margin:0;
            padding:20px 48px 20px 48px;
            text-align:left;
            color:$white !important;
            
            &:before {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23ffffff' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                filter:brightness(0) invert(1);
            }
            
	        &:hover {
	            color: $white !important;
	            
	        }
        }
        
        &:first-child {
            margin-top:20px;
        }
        
        &:hover {
            background: $green;
            color: $white;
        }
        
        .strapline {
            display:block;
            position:relative;
            margin:0;
            padding:0 24px;
            height:24px;
            line-height:24px;
            text-transform:uppercase;
            font-weight:$bold;
            font-size:0.75em;
            color:$white;
            background:transparent;
            background-repeat:no-repeat;
            background-image:url(../../images/insight-icon-article-white.svg);
            background-position:left center;
            background-size:auto 16px;
            
            &.article,
            &.news,
            &.page {
                background-image:url(../../images/insight-icon-article-white.svg);
            }
        
            &.whitepaper {
                background-image:url(../../images/insight-icon-whitepaper-white.svg);
            }
        
            &.podcast {
                padding-left:24px;
                background-image:url(../../images/insight-icon-podcast-white.svg);
            }
        
            &.video,
            &.webinar {
                padding-left:28px;
                background-image:url(../../images/insight-icon-video-white.svg);
            }
        }
    }
}

#search_results_cont {
    
    
    .search_results {
        
        article.card {
            margin:0 0 60px;
        
            .wrap_link {
                display:block;
                height:100%;
                text-decoration:none;
            
                &:hover {
                
                    .heading {
                        color:$green_dark;
                    }
                
                    .img > div {
                        transform:scale(1.05);
                    }
                
                    span.btn {
                        background:$green_dark !important;
                        color:$white !important;
                    
                        &:before {
                            filter:brightness(0) invert(1);
                        }
                    }
                }
            }
        
            .inner {
                display:flex;
                justify-content:flex-start;
                align-items:flex-start;
                height:100%;
            }
        
            .img {
                align-self:stretch;
                flex:0 0 31.6%;
                overflow:hidden;
            
                > div {       
                    height:100%;     
                    background-color:$green;
                    background-repeat:no-repeat;
                    background-position:center top;
                    background-size:cover;
                    transition:all 0.3s;
                }
            }
        
            .cont {
                flex:0 0 68.4%;
                align-self:stretch;
                background:$gray_light;
                padding:76px 40px 40px;
                box-sizing:border-box;
                
                &.no_img {
                    flex:0 0 100%;
                }
            }
        
            .eyebrow {
                display:block;
                margin:0 0 1em;
                color:$green;
                font-family:$roboto_cond;
                font-size:0.95em;
                text-transform:uppercase;
                -webkit-font-smoothing: auto;
            }
        
            .heading {
                margin:0 0 1.5em;
                font-family:$roboto_cond;
                font-weight:$bold;
                font-size:1.58em;
                line-height:1.17em;
                color:$gray;
                transition:all 0.3s;
            }
        
            .meta {
                margin:0 0 1.5em;
                color:$gray;
                font-family:$roboto_cond;
                font-size:1.05em;
            }
        
            span.btn {
                margin:0;
                color:$gray !important;
                background:none !important;
            
                &:before {
                    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23323e48' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                }
            
                &:hover {
                    color:$gray !important;
                    background:none !important;
                }
            }
        }
    }
}

@media only screen and (max-width: #{$bp_mid}) {
    
    #search_container_insights {
        margin:0 0 40px;
    }
    
    #search_toggle,
    #search_container {
        display:none !important;
    }
    
    #search_container_mobile {
        display:block !important;
        margin-top:40px;
        width:100%;
        margin:0;
        padding:0;
        background:$gray;
        
        /*#searchform_mobile {
            position:relative;
            right:auto;
            left:0;
            bottom:auto;
            
            input[type='text'] {
                width:calc(100% - 30px);
                padding:0;
                border:none;
                border-bottom:1px solid $white;
                line-height:28px;
                font-family: $roboto_cond;
                font-weight:$normal;
                border-radius:0;
                //border-left:1px solid rgba(255,255,255,0.5);
                -webkit-appearance:none;
                font-size: 22px;
                letter-spacing:0.01em;
                color: $white;
                text-transform:uppercase;
                background:none;
                transition:all 0.3s;
    
                &:focus,
                &:valid {
                    outline:none;
                }
        
                &:focus {
                    border-left-color:rgba(255,255,255,0);
                }
            }
            
            .search-submit {
                display:block;
                position:absolute;
                right:0;
                top:50%;
                width:28px;
                height:28px;
                padding:0;
                border:none;
                overflow:hidden;
                text-indent:-9999px;
                background:transparent url('../../images/search-mag-glass-white.svg') no-repeat center center;
                background-size:28px auto;
                transform:translateY(-50%);
                cursor:pointer;
                z-index:1020;            
            }
        }*/
    }
}

@media only screen and (max-width: #{$bp_small}) {
    
    #search_container_insights {
        margin:0 0 40px;
    }
    
}

@media only screen and (max-width: 540px) {
    
    #search_results_cont {
        
        .search_results {
        
            article.card {
        
                .inner {
                    flex-direction:column;
                }
    
                .img {
                
                    > div {      
                        height:0;
                        padding-bottom:60%; 
                        background-position:center center;
                    }
                }
    
                .cont {
                    flex:0 0 40%;
                    padding:50px 20px 20px;
                }
            }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}