/**********************************************************************************************/
/***** SITEWIDE BUTTONS ***********************************************************************/
/**********************************************************************************************/
a.btn,
div.btn,
.btn a,
a span.btn,
button.btn,
.wp-block-button__link,
input[type='image'],
input[type='submit'],
input[type='button'],
button.gpnf-add-entry,
button[type='submit'] {
    display:inline-block;
    position:relative;
    margin:1em 0;
    padding:14px 52px;
    width:auto;
    background:$green !important;
    font-family: $roboto;
    font-size: 18px !important;
    font-weight: $medium;
    font-style:normal;
    line-height:1.1em !important;
    color: $white !important;
    border:none;
    border-radius:30px;
    text-decoration:none;
    box-sizing:border-box;
    text-align:center;
    -webkit-appearance:none;
    transition:all 0.3s;
    cursor:pointer;
    -webkit-font-smoothing: auto;
    
    &.gray {
        color: $green !important;
        background:$gray_mid !important;
        
        &:hover {
            color: $white !important;
            background:$gray !important;
        }
    }
    
    &.white {
        color: $green !important;
        background:$white !important;
        
        &:hover {
            color: $white !important;
            background:$green !important;
        }
    }
    
    &.sq {
        border-radius:0;
    }
    
    &.trans {
        background:none !important;
        color:$gray !important;
        padding:14px 18px;
        font-family:$roboto_cond;
        
        &:hover,
        &:focus {
            background:$green_dark !important;
            color:$white !important;
        }
        
        /*&.white,
        .gray_mid &,
        .gray & {
            
            
            &:hover {
                background:$green_dark !important;
                color:$white !important;
            }
        }*/
    }

    &.arrowed {
        padding-right:72px;
        font-size:0.95em;
        
        &:before {
            position:absolute;
            right:46px;
            top:46%;
            width:17px;
            height:13px;
            background:none;
            content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23ffffff' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
            transition:all 0.3s;
            transform:translateY(-50%);
        }
        
        &.trans {
            padding:14px 40px 14px 18px;
            font-family:$roboto_cond;
            
            &:before {
                right:16px;
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23323e48' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                transform:translateY(-60%);
            }
            
            /*.gray_mid &,
            .gray & {
                color:$white !important;
                
                &:before {
                    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23ffffff' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                }
            }*/
            
            &:hover,
            &:focus {
                
                &:before {
                    filter:brightness(0) invert(1);
                }
            }
        }
    }
    
    &:hover,
    &:focus {
        color:$white !important;
        background:$green_dark !important;
    }
}

input[type='image'],
input[type='submit'],
input[type='button'],
button.gpnf-add-entry,
button[type='submit'] {
    

    &:focus {
        outline:none;
    }
}

a.btn.disabled,
div.btn.disabled,
.btn a.disabled,
input[type='image']:disabled,
input[type='submit']:disabled,
input[type='button']:disabled,
button.btn:disabled,
button.gpnf-add-entry:disabled,
.wp-block-button__link.disabled,
button[type='submit']:disabled {
    cursor:default;
    color: $white !important;
    background: $gray_light !important;
    border-color: $gray_light !important;
    
    &:hover {
        cursor:default;
        color: $white !important;
        background: $gray_light !important;
        border-color: $gray_light !important;
    }
}

.read_more {
    
}

.play_btn {
    display:block;
    position:absolute;
    left:50%;
    top:50%;
    margin:-48px 0 0 -48px;
    width:96px;
    height:96px;
    border-radius:48px;
    background:$green;
    opacity:1;
    visibility:visible;
    transition:all 0.3s;
    z-index:20;
    cursor:pointer;
    
    &:after {
        position:absolute;
        left:23px;
        top:29px;
        margin:0;
        width:46px;
        height:38px;
        line-height:38px;
        text-align:center;
        color:$white;
        font-family:'dashicons';
        content:'\f522';
        font-size:58px;
        transition:all 0.3s;
    }
    
    &:hover {
        background:$green_dark;
        
        &:after {
            color: $white;
        }
    }
    
    &.off {
        opacity:0;
        visibility:hidden;
    }
}


// GUTENBERG BUTTON STYLES
.wp-block-buttons {
    width:100%;
    max-width:1420px;
    margin-right:auto;
    margin-left:auto;

    .wp-block-button {
        margin-bottom:0;
        margin-right:20px;
        
        &:last-child {
            margin-right:0;
        }
    }
}

.wp-block-button__link {
	border-radius: 0;
}

//ADD TO ANY SHARING, CUSTOM STYLES
.addtoany_content {
    
    .addtoany_header {
        font-family:$roboto_cond;
        font-weight:$bold;
        text-transform:none;
    }
    
    .a2a_s_facebook {
        background-color: rgb(24, 119, 242) !important;
    }

    .a2a_s_twitter {
        background-color: rgb(29, 155, 240) !important;
    }

    .a2a_s_pinterest {
        background-color: rgb(189, 8, 28) !important;
    }

    .a2a_s_linkedin {
        background-color: rgb(0, 123, 181) !important;
    }

    .a2a_s_reddit {
        background-color: rgb(255, 69, 0) !important;
    }

    .a2a_s_tumblr {
        background-color: rgb(53, 70, 92) !important;
    }

    .a2a_s_email {
        background-color: rgb(1, 102, 255) !important;
    }

    .a2a_s_link {
        background-color: rgb(1, 102, 255) !important;
    }

    .a2a_s_a2a {
        background-color: rgb(1, 102, 255) !important;
    }
}


@media screen and (max-width: #{$bp_small}) {

    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}