/**********************************************************************************************/
/***** GLOBAL *********************************************************************************/
/**********************************************************************************************/


/* GRID ACCESSORIES */
.grid {
    > div {
        
        &.flush_right {
            
            > .cont {
                width:calc(100% + #{$gutter});
                margin-right:(-$gutter);
            }
        }
        
        &.flush_left {
            
            > .cont {
                width:calc(100% + #{$gutter});
                margin-left:(-$gutter);
            }
        }
        
        &.flush_left_right {
            padding-right:0;
            > .cont {
                width:calc(100% + #{$gutter} + #{$gutter});
                margin-left:(-$gutter);
                margin-right:(-$gutter);
            }
        }
        
        > .cont {
            
        }
    }
}

/*@media screen and (min-width: #{$bp_full}) {
    .grid > div.flush_right > .cont {
        width: calc(100% + (((100vw - #{$bp_full}) / 2) + #{$gutter}));
        margin-right: calc(-1 * (((100vw - #{$bp_full}) / 2) + #{$gutter}));
    }
    .grid > div.flush_left > .cont {
        width: calc(100% + (((100vw - #{$bp_full}) / 2) + #{$gutter}));
        margin-left: calc(-1 * (((100vw - #{$bp_full}) / 2) + #{$gutter}));
    }
}*/

/**********************************************************************************************/
/***** HOME PAGE *******************************************************************************/
/**********************************************************************************************/
#HowWeCanHelp,
#OurResults,
#OurClients {
    
    .intro_cont * {
        color:$white;
    }
    
    h2 {
        margin-top:0.75em;
        font-size:1.8em;
    }
    
    &.invert {
        
        
        .intro_cont * {
            color:$gray;
        }
    }
}

#OurClients {
    
    
}

#Tenets {
    
    .content_section_inner {
        padding:6em 0;
    }
    
    .tenets {
        display:flex;
        justify-content:space-between;
        align-items:flex-start;
    }
    
    .tenet {
        flex:0 0 calc(33.3333% - 60px);
    }
    
    h3 {
        margin-top:0;
        margin-bottom:30px;
        font-family:$roboto_slab;
        font-weight:$bold;
        font-size:1.6em;
        color:$white;
    }
    
    p {
        margin:0;
        color:$white;
        padding-top:30px;
        border-top:1px solid $white;
    }
}

.client_logo_grid {
    display: grid;
    margin-top:5em;
    margin-bottom:8em;
    padding:0;
    grid-template-columns: repeat(5, minmax(150px, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap:4rem;
    
    .logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        transition:opacity 0.3s;
        opacity:0.6;
        
        img {
            display:block;
            width:200px;
            max-width:100%;
            height:100px;
            object-fit:contain;
        }
        
        &:hover {
            opacity:1;
        }
    }
}

#Announcements {
    
    h4 {
        margin:14em 0 20px;
        font-family: $roboto_cond;
        font-weight:$bold;
        font-size:1.3em;
        line-height:1.3em;
        color: $gray;
    }
    
    .announcements {
        padding:0;
        list-style:none;
    
        li {
            padding:20px 0 30px;
            border-top:2px solid $yellow;
        }
    
        a {
            display:block;
            color:$white;
            text-decoration:none;
            
            p.heading:before {
                opacity:1;
                visibility:visible;
            }
        
            &:hover {
                color:$white;
            
                .heading:before {
                    left:10px;
                }
            }
        }
    
        p {
            margin:0;
            padding-left:40px;
            color:$white;
            font-size:1em;
        
            &.heading {
                position:relative;
                margin:0 0 5px;
                font-family:$roboto_slab;
                font-weight:$bold;
                font-size:1em;
            
                &:before {
                    position:absolute;
                    left:0;
                    top:50%;
                    transform:translateY(-50%);
                    width:7px;
                    height:12px;
                    background:transparent url('../../images/yellow-caret.svg') no-repeat center center;
                    background-size:cover;
                    content:'';
                    transition:all 0.3s;
                    opacity:0;
                    visibility:hidden;
                }
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    #Tenets {
    
        .content_section_inner {
            padding:60px 20px 30px;
        }
    
        .tenets {
            display:block;
        }
    
        .tenet {
            margin:0 0 60px;
            
            &:last-of-type {
                margin-bottom:30px;
            }
        }
    }
    
    .client_logo_grid {
        margin-bottom:3em;
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }
    
    #Announcements {
        margin:4em 0;
    
        h4 {
            margin:0 0 20px;
        }
    
        .announcements {
            
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .client_logo_grid {
        grid-template-columns: repeat(3, minmax(150px, 1fr));
    }
    
}

@media screen and (min-width: 1025px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** ABOUT PAGE *******************************************************************************/
/**********************************************************************************************/




@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1025px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** PLUGGEDIN (Podcast) (CUSTOM PAGE TEMPLATE) *********************************************/
/**********************************************************************************************/
.social_follow {
    
    a {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        padding:8px 0;
        text-decoration:none !important;
        transition:all 0.3s;
        
        &:hover {
            
            svg {
                
                > g {
                    fill:$green_dark;
                }
            }
        }
    }
    
    .icon {
        display:block;
        flex:0 0 24px;
    }
    
    .label {
        display:block;
        flex:0 0 calc(100% - 34px);
        margin-left:10px;
        font-family:$roboto;
        font-size:0.84em;
        font-weight:$light;
        color:$white;
    }
    
    svg {
        display:inline-block;
        vertical-align:middle;
        width:100%;
        height:auto;
        
        > g {
            fill:$green;
            transition:all 0.3s;
        }
    }
    
    &.lower {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        margin-top:10px;
        
        a {
            flex:0 0 calc(33.333% - 7px);
            margin:0 0 0 10px;
            padding:10px;
            background:$green;
            align-self:stretch;
            box-sizing:border-box;
            
            &:first-of-type{
                margin:0;
            }
            
            .icon {
                
            }
    
            .label {
                font-family:$roboto_cond;
                font-size:0.84em;
                line-height:1.2em;
                font-weight:$bold;
                color:$white;
            }
            
            svg {
                width:100%;
                height:auto;
        
                > g {
                    fill:$white;
                    transition:all 0.3s;
                }
            }
            
            &:hover {
                background:$green_dark;
                
                svg {
                
                    > g {
                        fill:$white;
                    }
                }
            }
        }
    }
}

#host_block {
    display:flex;
    justify-content:flex-start;
    align-items:flex-end;
    background:$gray_light;
    margin-top:2em;
    
    .img {
        flex:0 0 36%;
        align-self:stretch;
        padding-top:15px;
        background-color:transparent;
        background-repeat:no-repeat;
        background-position:center bottom;
        background-size:cover;
    }
    
    .cont {
        flex:0 0 64%;
        padding:50px 40px 50px 15px;
        box-sizing:border-box;
        
        h2 {
            margin:0 0 0.5em;
            font-size:1.53em;
            font-weight:$bold;
            color:$green_dark;
            
            span {
                font-weight:$normal;
            }
        }
        
        p:last-of-type {
            margin:0;
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 540px) {
    
    .social_follow {
        
        
        &.lower {
            display:block;
        
            a {
                margin:10px 0 0;
            }
        }
    }
    
    #host_block {
        flex-direction:column;
    
        .img {
            padding-top:0;
            padding-bottom:100%;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1025px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** STAFF BIO PAGE *************************************************************************/
/**********************************************************************************************/
.single-staff {
    
    .headshot {
        margin:40px 0 1em;
    }
    
    .hs_inner {
        height:0;
        padding-bottom:120%;
        background-color:transparent;
        background-repeat:no-repeat;
        background-position:center top;
        background-size:cover;
    }
    
    .social_cont {
        text-align:left;
        margin:0;
    }
    
    .social {
        display:inline-block;
        position:relative;
        width:30px;
        height:30px;
        border-radius:50%;
        margin:0 0 0 10px;
        background: $gray_light2;
        text-indent:-9999px;
        overflow:hidden;
        vertical-align:top;
        transition:all 0.3s;
        color: $white;
        
        &:first-of-type {
            margin:0;
        }
        
        span {
            display:block;
            text-align:left;
        }
        
        svg {
            display:block;
            position:absolute;
            left:50%;
            top:50%;
            margin:0;
            transition: all 0.2s;
            transform:translate(-50%,-50%);
            
            .shape {
                transition: all 0.3s;
                fill: $white;
            }
        }
        
        &:hover {
            background:$green;
            
            svg {
                .shape2 {
                    fill:$green;
                }
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1025px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** SERVICES (LANDING, DETAIL) *************************************************************/
/**********************************************************************************************/
.wp-block-columns.reasons_to_believe {
    margin:1.2em 0;
    
    .wp-block-column {
        flex:0 0 33.333%;
        margin-left:0 !important;
        padding:20px;
        background:$green;
        
        * {
            color:$white !important;
        }
    }
    
    .wp-block-separator {
        border:none;
    }
}

.service_blocks_list {
    margin:0;
    list-style:none;
    
    * {
        box-sizing:border-box;
    }
}

.service_block {
    margin:60px 0 0;
    padding:0;
    list-style-type:none;
    
    &:nth-of-type(even) {
        
        .inner_content {
            order:-1;
        }
    }
    
    .inner {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        height:100%;
        text-decoration:none;
        
        &:hover {
            
            
            .bg_img > div {
                transform:scale(1.1);
            }
            
            span.btn.trans {
                color:$white !important;
                background:$green_dark !important;
                
                &:before {
                    filter:brightness(0) invert(1);
                }
            }
        }
    }
    
    .bg_img {
        align-self:stretch;
        flex:0 0 36.4%;
        overflow:hidden;
        
        > div {       
            height:100%;     
            background-color:$gray_mid;
            background-repeat:no-repeat;
            background-position:center top;
            background-size:cover;
            transition:all 0.3s;
        }
    }
    
    .inner_content {
        flex:0 0 63.6%;
        align-self:stretch;
        background:$gray_light;
        padding:40px 60px 50px;
    }
    
    .crumb {
        margin:0 0 0.5em;
        font-family:$roboto_cond;
        font-size:1.05em;
        font-weight:$bold;
        color:$green;
        text-transform:none;
    }
    
    p {
        font-family:$roboto;
        color:$gray !important;
        
        &.heading_large {
            margin:0 0 0.5em;
            font-family:$roboto_cond;
            font-size:2.47em;
            font-weight:$normal;
        }
    }
    
    //IF NO SERVICE HEADING, THE CMS TITLE GETS DIFFERENT TREATMENT
    h2.heading_large {
        font-family:$roboto_cond;
        font-size:2.37em;
        font-weight:$bold;
        color:$green;
    }
    
    span.btn.trans {
        margin:0 0 0 -18px;
        color:$green !important;
        background:none !important;
            
            &:before {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e") !important;
            }
            
            &:hover {
                color:$green !important;
                background:none !important;
            }
    }
}

.service_sidebar {
    padding:0;
    
    .inner {
        padding:3.1em 0 0 100px;
    }
}

.wch {
    margin:0 0 6em;
    
    h3 {
        margin:0 0 0.75em;
        font-family:$roboto_cond;
        font-size:1.26em;
        line-height:1.21em;
        color:rgba(255,255,255,0.8) !important;
        font-weight:$bold;
    }
    
    .wch_list {
        margin:0;
        padding:0 0 0 30px;
        list-style:disc;
        font-family:$roboto_cond;
        font-size:1.26em;
        line-height:1.21em;
        font-weight:$bold;
        
        > li {
            position:relative;
            margin:0 0 0.75em;
            padding:0 0 0 5px;
            list-style-type:disc;
            color:rgba(255,255,255,0.8) !important;
            
            &::marker {
                font-size:80%;
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    .service_block {
        
        
        .inner {
            display:block;
        
            &:hover {
            
            
                span.btn.trans {
                    color:$white !important;
                    background:$green !important;
                
                    &:before {
                        filter:none;
                    }
                }
            }
        }
    
        .bg_img {
            flex:0 0 auto;
        
            > div {       
                height:0;
                padding-bottom:60%;
            }
        }
    
        .inner_content {
            flex:0 0 auto;
            padding:30px 30px 40px;
        }
        
        p {
            
            &.heading_large {
                font-size:2em;
            }
        }
    
        span.btn.trans {
            margin:0;
            color:$white !important;
            background:$green !important;
            
            &:before {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23ffffff' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e") !important;
            }
        
            &:hover {
                color:$white !important;
                background:$green !important;
            }
        }
    }
    
    .wch {
        margin-top:4em;
        padding-left:0;
        padding-top:0;
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .service_block {
        
        .inner_content {
            padding:60px 40px 50px;
        }
        
        p {
            
            &.heading_large {
                font-size:2em;
            }
        }
    }
    
    .wch {
        
        .wch_list {
            font-size:1em;
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** INSIGHTS LANDING ***********************************************************************/
/**********************************************************************************************/
#ins_landing {
    
    * {
        box-sizing:border-box;
    }
    
    #tertiary {
        padding:60px 0 0;
    }
    
    #ins_main {
        margin-top:0;
        padding:0;
        
        .addtoany_share_save_container {
            display:none;
        }
    }
    
    #secondary {
        padding:60px 0 0 40px;
        
        > div {
            width:100%;
        }
    }
}


@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #ins_landing {
    
        #tertiary {
            margin-bottom:2em;
            padding:0;
        }
    
        #secondary {
            padding:105px 0 0;
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** INSIGHTS DETAIL ************************************************************************/
/**********************************************************************************************/
.single-post {
    
    #primary .rp4wp-related-posts {
        display:none !important;
    }
    
    .crumb {
        display:block;
        position:relative;
        top:0;
        margin:0 0 0.75em;
        text-decoration:none;
        color:$green !important;
        font-family:$roboto_cond;
        font-size:0.95em;
        font-weight:normal;
        -webkit-font-smoothing: auto;
        
        &.arrowed {
            padding-left:24px;
            
            &:before {
                position:absolute;
                left:0;
                top:50%;
                width:17px;
                height:13px;
                background:none;
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e");
                transition:all 0.3s;
                transform:translateY(-25%) rotate(180deg);
                filter:none;
            }
            
            &:hover {
                color:$green_dark !important;
                
                &:before {
                    filter:none;
                }
            }
        }
    }
}

//PODCAST
.episode_share {
    margin:0 0 2em;
    padding:0 0 2em;
    border-bottom:1px solid $gray_light2;
    
    > div {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        flex-wrap:wrap;
    }
    
    .social {
        flex:0 0 calc(33.3333% - 20px);
        display:block;
        margin:0 20px 0 0;        
        
        span {
            
            &.icon {
                position:relative;
                display:inline-block;
                vertical-align:middle;
                width:40px;
                height:40px;
                border-radius:50%;
                background: $gray_mid;
                overflow:hidden;
                transition:all 0.3s;
                color: $white;
            }
            
            &.label {
                display:inline-block;
                vertical-align:middle;
                padding-left:10px;
                line-height:40px;
                text-align:left;
                color:$gray;
                transition:all 0.3s;
            }
        }
    
        svg {
            display:block;
            position:absolute;
            left:50%;
            top:50%;
            margin:0;
            transition: all 0.2s;
            transform:translate(-50%,-50%);
        
            .shape {
                transition: all 0.3s;
                fill: $white;
            }
        }
    
        &:hover {
            span.icon {
                background:$green_dark;
            }
            
            span.label {
                color:$green_dark;
            }
        
            svg {
                .shape2 {
                    fill:$green_dark;
                }
            }
        }
    }
}

#transcript {
    margin:60px 0 0;
    
    .inner {
        position:relative;
        padding:20px 18% 0 0;
        overflow:hidden;
        transition:height 0.3s;
        border-top:1px solid $gray;
        
        &.short {
            height:280px;
        
            &:after {
                opacity:1;
                visibility:visible;
            }
        }
        
        &:after {
            position:absolute;
            left:0;
            bottom:0;
            width:100%;
            height:230px;
            background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
            background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
            background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
            transition:all 0.3s;
            content:'';
            z-index:1;
            opacity:0;
            visibility:hidden;
        }
    }
}

//GATED POSTS
#post_gate_teaser {
    margin:60px 0 120px;
        
    .inner {
        position:relative;
        overflow:hidden;
        
        &.short {
            height:280px;
        
            &:after {
                opacity:1;
                visibility:visible;
            }
        }
        
        &:after {
            position:absolute;
            left:0;
            bottom:0;
            width:100%;
            height:300px;
            background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
            background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
            background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
            content:'';
            z-index:1;
        }
    }
    
    .body-gatedcontent-postform & {
        display:none;
    }
}

.webinar-upcoming-form-date {
    
}

#access_gate,
#access_gate_outer {
    margin:3em -50px;
    border:none;
    background:$gray_mid;
    
    .inner {
        padding:60px 90px 70px;
    }
    
    #secondary & {
        margin:0 0 1.67em 0;
        
        .inner {
            padding:30px;
        }
    }
    
    * {
        color:$white !important;
    }
    
    .gform_wrapper {
        
        .gform_title {
            margin:0 0 0.75em;
            font-weight:$bold;
        }
    
        h3 {
            margin:0 0 0.75em;
        }
    
        .gform_description,
        .cust_desc {
            font-size:0.89em;
            font-weight:$normal;
        }
    
        &.cust_title {
            
            .gform_title {
                display:none !important;
            }
        }
        &.cust_desc {
        
            .gform_description {
                display:none !important;
            }
        }
    
        #content & {
            margin:0 !important;
            padding:0 !important;
    
            li {
                margin:2em 0 0 !important;
        
                &:first-of-type {
                    margin:0 !important;
                }
            }
        }
    }
}

#access_gate_outer {
    #access_gate {
        
        
        #secondary & {
            margin:0 0 1.67em 0;
        
            .inner {
                padding:30px 0;
            }
        }
    }
}

/*.body-gatedcontent-preform #secondary .widget {
    display:none !important;
}
.body-gatedcontent-postform #secondary .widget {
    display:block !important;
}*/

@media screen and (max-width: 1300px) {
    
    /*.episode_share {
        
        .social {
            flex:0 0 calc(50% - 20px);
            margin:0 20px 20px 0;
        }
    }*/
    
}

@media screen and (max-width: #{$bp_small}) {
    
    .episode_share {
        
        .social {
            flex:0 0 calc(50% - 20px);
            margin:0 20px 20px 0;
        }
    }
    
    #access_gate,
    #access_gate_outer {
        margin:3em 0;
    
        .inner {
            padding:60px 40px 50px;
        }
    }
    
    /*#access_gate_outer {
        
        .contentupgrade-wrapper {
            margin:1.67em -20px;
    
            .single-landingpage & {
                margin:0 -20px;
            }
    
            .contentupgrade {
                padding:0;
            }
        }
    }*/
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .episode_share {
        
        .social {
            flex:0 0 calc(50% - 20px);
            margin:0 20px 20px 0;
        }
    }
    
    #access_gate,
    #access_gate_outer {
            
        .inner {
            padding:60px 40px 50px;
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** WORK LANDING ************************************************************************/
/**********************************************************************************************/


@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** CASE STUDY DETAIL **********************************************************************/
/**********************************************************************************************/
.all_services_cta_wrap {
    
}

.all_services_cta {
    margin:0 0 0 -32px;
    padding:100px 0 0;
    width:calc(100% + 32px);
    border-top:1px solid $gray_light2;
    list-style-type:none;
    
    * {
        box-sizing:border-box;
    }
    
    .inner {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        height:100%;
        text-decoration:none;
        
        &:hover {
            
            
            .bg_img > div {
                transform:scale(1.1);
            }
            
            span.btn.trans {
                color:$white !important;
                background:$green_dark !important;
                
                &:before {
                    filter:brightness(0) invert(1);
                }
            }
        }
    }
    
    .bg_img {
        align-self:stretch;
        flex:0 0 36.4%;
        overflow:hidden;
        
        > div {       
            height:100%;     
            background-color:$gray_mid;
            background-repeat:no-repeat;
            background-position:center top;
            background-size:cover;
            transition:all 0.3s;
        }
    }
    
    .inner_content {
        flex:0 0 63.6%;
        align-self:stretch;
        background:$gray_light;
        padding:40px 60px 70px;
    }
    
    .crumb {
        position:static;
        margin:0 0 0.5em;
        font-family:$roboto_cond;
        font-size:1.05em;
        font-weight:$normal;
        color:$green;
        text-transform:none;
    }
    
    p {
        font-family:$roboto;
        color:$gray !important;
        
        &.heading_large {
            margin:0 0 0.5em;
            font-family:$roboto_cond;
            font-size:2.47em;
            font-weight:$normal;
        }
    }
    
    span.btn.trans {
        margin:0 0 0 -18px;
        color:$green !important;
        background:none !important;
            
            &:before {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%237d9c91' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e") !important;
            }
            
            &:hover {
                color:$green !important;
                background:none !important;
            }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    .all_services_cta {
        margin:0 auto;
        padding:60px 0 0;
        width:calc(100% - 40px);
    
        .inner {
            display:block;
        
            &:hover {
                
                span.btn.trans {
                    color:$white !important;
                    background:$green !important;
                
                    &:before {
                        filter:none;
                    }
                }
            }
        }
    
        .bg_img {
            flex:0 0 auto;
        
            > div {       
                height:0;
                padding-bottom:60%;
            }
        }
    
        .inner_content {
            padding:30px 30px 40px;
        }
    
        span.btn.trans {
            margin:0;
            color:$white !important;
            background:$green !important;
            
            &:before {
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 13'%3e%3cg fill='%23ffffff' %3e%3cpolygon points='10.5 12.5 9.4 11.4 13.6 7.2 0.5 7.2 0.5 5.8 13.6 5.8 9.4 1.5 10.5 0.4 16.5 6.4'%3e%3c/polygon%3e%3c/g%3e%3c/svg%3e") !important;
            }
        
            &:hover {
                color:$white !important;
                background:$green !important;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .all_services_cta {
        margin:0;
        padding:60px 0 0;
        width:100%;
    
        .inner_content {
            padding:40px 40px 50px;
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}


/**********************************************************************************************/
/***** CONTACT PAGE ***************************************************************************/
/**********************************************************************************************/


@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** CAREERS/JOBS ***************************************************************************/
/**********************************************************************************************/


@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** NEWFANGLED CONTENT UPGRADES, LANDING PAGES *********************************************/
/**********************************************************************************************/
.landingpage {
    
    
    .featured {
        display:block;
        width:calc(100% + 100px);
        margin:0 0 3em -50px;
        max-width:none;
    }
}


.contentupgrade-wrapper {
    margin:3em -50px;
    background:$gray_mid;
    border:none;
    
    #secondary & {
        margin:1.67em 0;
    }
    
    .single-landingpage & {
        margin:3em -50px;
    }
    
    .contentupgrade {
        position:relative;
        padding:60px 90px 70px;
        z-index:10;
        background:$gray_mid;
        
        .cu_thumb {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            background-color:transparent;
            background-repeat:no-repeat;
            background-position:center center;
            background-size:cover;
            z-index:-2;
            filter:grayscale(100%);
        }
        
        .overlay {
            position:absolute;
            left:0;
            top:0;
            right:0;
            bottom:0;
            background:rgba(50,62,72,0.94);
            z-index:-1;
        }
        
        * {
            color: $white !important;
        }
        
        #secondary & {
            padding:0;
        }
        
        .contentupgrade-title {
            margin:0 0 0.75em;
            font-family:$roboto_cond;
            font-weight:$bold;
            font-size:1.26em;
        }
        
        .contentupgrade-text {
            p {
                font-size:1em;
            }
        }
        
        #content & {
            
            .contentupgrade-toggle-button,
            .contentupgrade-download-button {
                padding:14px 74px 14px 38px;
                font-size:0.76em;
                color:$white !important;
                background:$green !important;
                
                &:hover {
                    color:$white !important;
                    background:$green_dark !important;
                }
            }
    
            .gform_wrapper {
                margin:0 !important;
                padding:0 !important;
        
                li {
                    margin:2em 0 0 !important;
            
                    &:first-of-type {
                        margin:0 !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    .landingpage {
        
        .featured {
            margin-bottom:2em;
        }
    }
    
    .contentupgrade-wrapper {
        margin:1.67em -20px;
    
        .single-landingpage & {
            margin:1.67em -20px;
        }
    
        .contentupgrade {
            position:relative;
            padding:60px 40px;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}