/**********************************************************************************************/
/***** CUSTOM GUTENBERG BLOCK: VIDEO IMAGE GRID ***********************************************/
/**********************************************************************************************/
.block_vid_img_grid {
    width:calc(100% + 100px);
    margin:2em -50px;
    
    &.slider {
        
        .block_inner {
            display:block;
        }
    }
    
    .block_inner {
        position: relative;
        padding:0;
        display: grid;
        grid-column-gap: $gutter;
        grid-row-gap: $gutter;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: 1fr;
        grid-auto-rows: auto;
        
        .video_embed {
            grid-column: 1 / span 2;
        }

        .img {
            display:block;
            position: relative;
            object-fit: cover;
            overflow:hidden;
            
            .img_wrap {
                overflow:hidden;
                
                > div {
                    height:0;
                    padding-bottom:100%;
                    background-color:$gray_mid;
                    background-repeat:no-repeat;
                    background-position:center top;
                    background-size:cover;
                    transition:all 0.3s;
                }
            }
    
            .caption {
                margin-top:5px;
                text-align:left;
                color:$white;
                font-size:0.74em;
            }
            
            &.fw {
                grid-column: 1 / span 2;
                
                .img_wrap {
                    
                    > div {
                        padding-bottom:50%;
                    }
                }
            }
            
            &:hover {
                .img_wrap > div {
                    transform:scale(1.1);
                }
            }
        }
    }
    
    .video_embed {
        
        .vid_wrap {
            position:relative;
            background-color:transparent;
            background-repeat:no-repeat;
            background-size:cover;
            background-position:center center;
            z-index:10;
        }
    }
}

@media screen and (max-width: #{$bp_small}) {

    .block_vid_img_grid {
        width:100%;
        margin:2em 0;
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}