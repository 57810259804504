//HEADER HEIGHT
$header_h:  120px;
$header_h_mobile:  90px;

// COLOR
$black: #000000;
$white: #ffffff;
//$gray: #323e48; //RGB: 50 62 72
$gray: #29343d; //RGB: 41 52 61
//$gray_mid: #4a5661; //RGB: 74 86 97
$gray_mid: #323e48; //RGB: 50 62 72
$gray_light: #f5f5f5; //RGB: 245 245 245 (header bg)
$gray_light2: #d9d9d9; //RGB: 217 217 217 (footer copyright)
$green: #7d9c91; //RGB: 125 156 145

//slightly different $green for accessibility contrast on darkest gray BG
$green_on_slate: #93AEA6;

$green_dark: #608477; //RGB: 96 132 119 (subnav bg)
$yellow: #eed282; //this is only on the pluggedin podcast page

$gray_dark: #23272f; //NEW AUG 2024 - Portfolio page bg

@mixin background-opacity($color, $opacity: 0.3) {
    background-color: $color; /* The Fallback */
    background-color: rgba($color, $opacity);
}

// Gutenberg color options
// -- see editor-color-palette in functions.php
$colors: (
    'black'             : #000000,
    'white'             : #ffffff,
    'gray'              : #29343d,
    'gray_mid'          : #323e48,
    'gray_light'        : #f5f5f5,
    'gray_light2'       : #d9d9d9,
    'green'             : #7d9c91,
    'green_dark'        : #608477,
    'green_on_slate'    : #93AEA6,
    'yellow'            : #eed282,
);

@each $name, $color in $colors {

	.has-#{$name}-color {
		color: $color;
	}

	.has-#{$name}-background-color {
		background-color: $color;
	}
}

/*@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}
}*/

/**** SITE FONTS ARE GOOGLE FONTS **********************************************/

$roboto: 'Roboto', sans-serif;
$roboto_cond: 'Roboto Condensed', sans-serif;
$roboto_slab: 'Roboto Slab', sans-serif;

$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;

/*
  Simple Grid
  Project Page - http://thisisdallas.github.com/Simple-Grid/
  Author - Dallas Bass
  Site - http://dallasbass.com
*/

$gutter: 20px; //col width is 60 at full width
$bp_full: 1420px;
$bp_mid: 1023px;
$bp_small: 767px;

[class*='grid'],
[class*='col-'],
[class*='mobile-'],
.grid:after {
	box-sizing: border-box;	
}

[class*='col-'] {
	float: left;
  	min-height: 1px;
	padding-right: $gutter; /* column-space */
}

[class*='col-'] [class*='col-']:last-child {
	padding-right: 0;
}

.grid {
	width: 100%;
	max-width:1420px;
	min-width: 708px; /* when using padded grid on ipad in portrait mode, width should be viewport-width - padding = (768 - 20) = 748. actually, it should be even smaller to allow for padding of grid containing element */
	margin: 0 auto;
	/*overflow: hidden;*/
	
	&.extra_wide {
	    max-width:1420px;
	}
	&.full {
	    max-width:none;
	}
}

.grid:after {
	content: "";
	display: table;
	clear: both;
}

.grid-pad {
	padding-top: 0;
	padding-left: $gutter; /* grid-space to left */
	padding-right: 0; /* grid-space to right: (grid-space-left - column-space) e.g. 20px-20px=0 */
}

.push-right {
	float: right;
}

@media screen and (max-width: #{$bp_full}) {
    
    
    
}

/* Content Columns - 18 COLUMN */

.col-1-1 {
	width: 100%;
}

.col-2-3 {
	width: 66.66%;
}

.col-1-2, .col-9-18 {
	width: 50%;
}

.col-1-3, .col-6-18 {
	width: 33.33%;
}

.col-1-4 {
	width: 25%;
}

.col-1-5 {
	width: 20%;
}

.col-1-6 {
	width: 16.667%;
}

.col-1-7 {
	width: 14.28%;
}

.col-1-8 {
	width: 12.5%;
}

.col-1-9 {
	width: 11.1%;
}

.col-1-10 {
	width: 10%;
}

.col-1-11 {
	width: 9.09%;
}

.col-1-12 {
	width: 8.33%
}

.col-1-13 {
	width: 7.69%
}

.col-1-14 {
	width: 7.14%
}

.col-1-15 {
	width: 6.67%
}

.col-1-16 {
	width: 6.25%
}

.col-1-17 {
	width: 5.88%
}

.col-1-18 {
	width: 5.56%
}

/* Layout Columns */

.col-17-18 {
	width: 94.44%
}

.col-8-9, .col-16-18 {
	width: 88.89%
}

.col-5-6, .col-15-18 {
	width: 83.33%
}

.col-7-9, .col-14-18 {
	width: 77.78%
}

.col-13-18 {
    width: 72.22%
}

.col-2-3, .col-12-18 {
	width: 66.67%;
}

.col-11-18 {
    width: 61.11%
}

.col-5-9, .col-10-18 {
    width: 55.56%
}

.col-1-2, .col-9-18 {
    width: 50%
}

.col-4-9, .col-8-18 {
    width: 44.44%
}

.col-7-18 {
    width: 38.89%
}

.col-1-3, .col-6-18 {
    width: 33.33%
}

.col-5-18 {
    width: 27.78%
}

.col-2-9, .col-4-18 {
    width: 22.22%
}

.col-1-6, .col-3-18 {
    width: 16.67%
}

.col-1-9, .col-2-18 {
    width: 11.11%
}

/* Pushing blocks */
.push-1-18 {
    margin-left: 5.5%;
}

.push-1-9, .push-2-18 {
    margin-left: 11.11%;
}

.push-1-6, .push-3-18 {
    margin-left: 16.67%;
}

.push-4-18 {
    margin-left: 22.22%;
}

.push-5-18 {
    margin-left: 27.78%;
}

.push-1-3, .push-6-18 {
    margin-left: 33.33%;
}

.push-7-18 {
    margin-left: 38.89%;
}

.push-8-18 {
    margin-left: 44.44%;
}

.push-1-2, .push-9-18 {
    margin-left: 50%;
}

.push-10-18 {
    margin-left: 55.56%;
}

.push-11-18 {
    margin-left: 61.11%;
}

.push-2-3, .push-12-18 {
    margin-left: 66.67%;
}

.push-13-18 {
    margin-left: 72.22%;
}

.push-14-18 {
    margin-left: 77.78%;
}

.push-15-18 {
    margin-left: 83.33%;
}

.push-8-9, .push-16-18 {
    margin-left: 88.89%;
}

.push-17-18 {
    margin-left: 94.44%;
}


/* Pulling blocks */
.pull-1-16 {
    margin-left: -6.25%;
}

.pull-1-8, .pull-2-16 {
    margin-left: -12.5%;
}

.pull-3-16 {
    margin-left: -18.75%;
}

.pull-1-4, .pull-4-16 {
    margin-left: -25%;
}

.pull-5-16 {
    margin-left: -31.25%;
}

.pull-3-8, .pull-6-16 {
    margin-left: -37.5%;
}

.pull-7-16 {
    margin-left: -43.75%;
}

.pull-1-2, .pull-8-16 {
    margin-left: -50%;
}

.pull-9-16 {
    margin-left: -56.25%;
}

.pull-5-8, .pull-10-16 {
    margin-left: -62.5%;
}

.pull-11-16 {
    margin-left: -68.75%;
}

.pull-3-4, .pull-12-16 {
    margin-left: -75%;
}

.pull-13-16 {
    margin-left: -81.25%;
}

.pull-7-8, .pull-14-16 {
    margin-left: -87.5%;
}

.pull-15-16 {
    margin-left: -93.75%;
}

@media handheld, only screen and (max-width: #{$bp_small}) {
	.grid {
		width: 100%;
		min-width: 0;
		margin-left: 0;
		margin-right: 0;
		/*padding-left: 20px;*/ /* grid-space to left */
		/*padding-right: 10px;*/ /* grid-space to right: (grid-space-left - column-space) e.g. 20px-10px=10px */
		padding-left: 0;
		padding-right: 0;
		
		.site-header & {
		    
		}
		
		.site-footer & {
		    padding-left:20px;
		    padding-right:20px;
		    
		    .grid {
		        padding:0 20px;
		    }
		}
		
		&.extra_wide {
            max-width:none;
            padding:0 20px;
            
            .site-header & {
                padding:0 20px;
            }
            
            .site-footer & {
                padding:0;
            }
        }
        
        &.full {
	        max-width:none;
	        padding:0;
	    }
	}

	[class*='col-'] {
		width: auto;
		float: none;
		margin: 0;
		padding-left: 0;
		/*padding-right: 10px;*/ /* column-space */
		padding-right: 0;
	}

	[class*='col-'] [class*='col-'] {
		padding-right: 0;
	}

	/* Mobile Layout */

	[class*='mobile-col-'] {
		float: left;
		margin: 0;
		padding-left: 0;
		padding-right: 10px; /* column-space */
		padding-bottom: 0;
	}
	
	.mobile-col-1-1 {
        width: 100%;
    }

    .mobile-col-2-3 {
        width: 66.66%;
    }

    .mobile-col-1-2, .mobile-col-9-18 {
        width: 50%;
    }

    .mobile-col-1-3, .mobile-col-6-18 {
        width: 33.33%;
    }

    .mobile-col-1-4 {
        width: 25%;
    }

    .mobile-col-1-5 {
        width: 20%;
    }

    .mobile-col-1-6 {
        width: 16.667%;
    }

    .mobile-col-1-7 {
        width: 14.28%;
    }

    .mobile-col-1-8 {
        width: 12.5%;
    }

    .mobile-col-1-9 {
        width: 11.1%;
    }

    .mobile-col-1-10 {
        width: 10%;
    }

    .mobile-col-1-11 {
        width: 9.09%;
    }

    .mobile-col-1-12 {
        width: 8.33%
    }

    .mobile-col-1-13 {
        width: 7.69%
    }

    .mobile-col-1-14 {
        width: 7.14%
    }

    .mobile-col-1-15 {
        width: 6.67%
    }

    .mobile-col-1-16 {
        width: 6.25%
    }

    .mobile-col-1-17 {
        width: 5.88%
    }

    .mobile-col-1-18 {
        width: 5.56%
    }

    /* Layout Columns */

    .mobile-col-17-18 {
        width: 94.44%
    }

    .mobile-col-8-9, .mobile-col-16-18 {
        width: 88.89%
    }

    .mobile-col-5-6, .mobile-col-15-18 {
        width: 83.33%
    }

    .mobile-col-7-9, .mobile-col-14-18 {
        width: 77.78%
    }

    .mobile-col-13-18 {
        width: 72.22%
    }

    .mobile-col-2-3, .mobile-col-12-18 {
        width: 66.67%;
    }

    .mobile-col-11-18 {
        width: 61.11%
    }

    .mobile-col-5-9, .mobile-col-10-18 {
        width: 55.56%
    }

    .mobile-col-1-2, .mobile-col-9-18 {
        width: 50%
    }

    .mobile-col-4-9, .mobile-col-8-18 {
        width: 44.44%
    }

    .mobile-col-7-18 {
        width: 38.89%
    }

    .mobile-col-1-3, .mobile-col-6-18 {
        width: 33.33%
    }

    .mobile-col-5-18 {
        width: 27.78%
    }

    .mobile-col-2-9, .mobile-col-4-18 {
        width: 22.22%
    }

    .mobile-col-1-6, .mobile-col-3-18 {
        width: 16.67%
    }

    .mobile-col-1-9, .mobile-col-2-18 {
        width: 11.11%
    }
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .grid {
		width: 100%;
		min-width: 0;
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px; /* grid-space to left */
		padding-right: 10px; /* grid-space to right: (grid-space-left - column-space) e.g. 20px-10px=10px */
		
		&.even_pad {
	        padding-right: 20px;
	    }
	    
	    &.full {
	        max-width:none;
	        padding:0;
	    }
	    
	    .site-header & {
	        padding:0;
	    }
		
		.site-footer & {
		    		    
		    padding:0;
		}
	}
    
    [class*='tablet-col-'] {
		float: left;
		margin: 0;
		padding-left: 0;
		padding-right: 10px; /* column-space */
		padding-bottom: 0;
		
		.even_pad & {
		    padding-right: 0;
		}
		
		#footer_cta & {
		    float:none;
		    margin:0 auto;
		}
		
		.hero & {
		    float:none;
		    margin:0 auto;
		}
		
	}
	
	.tablet-col-1-1 {
        width: 100%;
    }

    .tablet-col-2-3 {
        width: 66.66%;
    }

    .tablet-col-1-2, .tablet-col-9-18 {
        width: 50%;
    }

    .tablet-col-1-3, .tablet-col-6-18 {
        width: 33.33%;
    }

    .tablet-col-1-4 {
        width: 25%;
    }

    .tablet-col-1-5 {
        width: 20%;
    }

    .tablet-col-1-6 {
        width: 16.667%;
    }

    .tablet-col-1-7 {
        width: 14.28%;
    }

    .tablet-col-1-8 {
        width: 12.5%;
    }

    .tablet-col-1-9 {
        width: 11.1%;
    }

    .tablet-col-1-10 {
        width: 10%;
    }

    .tablet-col-1-11 {
        width: 9.09%;
    }

    .tablet-col-1-12 {
        width: 8.33%
    }

    .tablet-col-1-13 {
        width: 7.69%
    }

    .tablet-col-1-14 {
        width: 7.14%
    }

    .tablet-col-1-15 {
        width: 6.67%
    }

    .tablet-col-1-16 {
        width: 6.25%
    }

    .tablet-col-1-17 {
        width: 5.88%
    }

    .tablet-col-1-18 {
        width: 5.56%
    }

    /* Layout Columns */

    .tablet-col-17-18 {
        width: 94.44%
    }

    .tablet-col-8-9, .tablet-col-16-18 {
        width: 88.89%
    }

    .tablet-col-5-6, .tablet-col-15-18 {
        width: 83.33%
    }

    .tablet-col-7-9, .tablet-col-14-18 {
        width: 77.78%
    }

    .tablet-col-13-18 {
        width: 72.22%
    }

    .tablet-col-2-3, .tablet-col-12-18 {
        width: 66.67%;
    }

    .tablet-col-11-18 {
        width: 61.11%
    }

    .tablet-col-5-9, .tablet-col-10-18 {
        width: 55.56%
    }

    .tablet-col-1-2, .tablet-col-9-18 {
        width: 50%
    }

    .tablet-col-4-9, .tablet-col-8-18 {
        width: 44.44%
    }

    .tablet-col-7-18 {
        width: 38.89%
    }

    .tablet-col-1-3, .tablet-col-6-18 {
        width: 33.33%
    }

    .tablet-col-5-18 {
        width: 27.78%
    }

    .tablet-col-2-9, .tablet-col-4-18 {
        width: 22.22%
    }

    .tablet-col-1-6, .tablet-col-3-18 {
        width: 16.67%
    }

    .tablet-col-1-9, .tablet-col-2-18 {
        width: 11.11%
    }

    /* Pushing blocks */
    .tablet-push-1-18 {
        margin-left: 5.56%;
    }

    .tablet-push-1-9, .tablet-push-2-18 {
        margin-left: 11.11%;
    }

    .tablet-push-1-6, .tablet-push-3-18 {
        margin-left: 16.67%;
    }

    .tablet-push-4-18 {
        margin-left: 22.22%;
    }

    .tablet-push-5-18 {
        margin-left: 27.78%;
    }

    .tablet-push-1-3, .tablet-push-6-18 {
        margin-left: 33.33%;
    }

    .tablet-push-7-18 {
        margin-left: 38.89%;
    }

    .tablet-push-8-18 {
        margin-left: 44.44%;
    }

    .tablet-push-1-2, .tablet-push-9-18 {
        margin-left: 50%;
    }

    .tablet-push-10-18 {
        margin-left: 55.56%;
    }

    .tablet-push-11-18 {
        margin-left: 61.11%;
    }

    .tablet-push-2-3, .tablet-push-12-18 {
        margin-left: 66.67%;
    }

    .tablet-push-13-18 {
        margin-left: 72.22%;
    }

    .tablet-push-14-18 {
        margin-left: 77.78%;
    }

    .tablet-push-15-18 {
        margin-left: 83.33%;
    }

    .tablet-push-8-9, .tablet-push-16-18 {
        margin-left: 88.89%;
    }

    .tablet-push-17-18 {
        margin-left: 94.44%;
    }
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    .grid {
		width: 100%;
		min-width: 0;
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px; /* grid-space to left */
		padding-right: 10px; /* grid-space to right: (grid-space-left - column-space) e.g. 20px-10px=10px */
		
		&.full {
	        max-width:none;
	        padding:0;
	    }
	
		&.even_pad {
	        padding-right: 20px;
	    }
	    
	    .site-header & {
	        padding-left: 0;
		    padding-right: 0;
		    margin:0 auto;
	    }
	}
}

@media only screen and (min-width: 1421px) {
    
    
    
}