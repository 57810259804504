/**********************************************************************************************/
/***** HERO ***********************************************************************************/
/**********************************************************************************************/
.single-casestudy .title_block {
    
    h1 {
        margin:0 0 0.75em;
    }
    
    .meta {
        margin:0 0 4em;
        color:$green;
        
        a {
            color:$green;
            text-decoration:underline;
            text-decoration-color:rgba(125,156,145,0);
            text-underline-offset:0.3em;
            transition:all 0.3s;
            
            &:hover {
                color:$green;
                text-decoration-color:$green;
                text-underline-offset:0.15em;
            }
        }
    }
}

#hero {
    position:relative;
    z-index:10;
    background:$gray;
    
    &:before {
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        content: '';
        background-color:transparent;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;
        z-index:-1;
    }
    
    * {
        color:$white;
    }
    
    #vid_wrap {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        overflow:hidden;
        z-index:-1;
    }
    
    iframe {
        width: 100vw !important;
        height: 56.25vw;
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    &.pluggedin {
        
        .content_section_inner {
            height:auto;
        }
                        
        .overlay {
            background: -moz-linear-gradient(bottom,  rgba(32,41,55,1) 0%, rgba(32,41,55,0.5) 100%);
            background: -webkit-linear-gradient(bottom,  rgba(32,41,55,1) 0%,rgba(32,41,55,0.5) 100%);
            background: linear-gradient(to top,  rgba(32,41,55,1) 0%,rgba(32,41,55,0.5) 100%);
        }
        
        .inner {
            display:block;
        }
        
        .inner_content {
            padding:3em 0;
            height:100%;
        }
        
        .flex_wrap {
            display:flex;
            justify-content:flex-start;
            align-items:center;
            height:100%;
        }
        
        .cover {
            flex:0 0 25%;
            
            img {
                display:block;
                width:100%;
            }
        }
        
        .info {
            flex:0 0 75%;
            padding:0 0 0 80px;
            box-sizing:border-box;
        }
        
        .cur_ep {
            display:flex;
            justify-content:flex-start;
            align-items:center;
            margin:2.5em 0 2em;
            text-decoration:none;
            
            .icon {
                flex:0 0 64px;
                width:64px;
                height:64px;
                border-radius:50%;
                overflow:hidden;
                background:$yellow url('../../images/soundcloud-play.svg') no-repeat 55% center;
                background-size:18px auto;
            }
            
            .text {
                flex:0 0 calc(100% - 94px);
                margin:0 0 0 30px;
            }
            
            span {
                display:block;
                font-family:$roboto_slab;
                font-size:1.47em;
                line-height:1.1em;
                color:$white;
                transition:all 0.3s;
                
                &.eyebrow {
                    margin:0 0 5px;
                    font-family:$roboto_cond;
                    font-size:1em;
                    letter-spacing:2px;
                    font-weight:$bold;
                    text-transform:uppercase;
                    color:$yellow;
                }
            }
            
            &:hover {
                
                span {
                    color:$yellow;
                    
                    &.eyebrow {
                        color:$yellow;
                    }
                }
            }
        }
        
        .social_follow {
            
        }
    }
    
    &.is_tall {
        
        .content_section_inner {
            height:590px;
            min-height:590px;
        }
    }
    
    &.no_img {
        background:none;
    
        .single-post &,
        .single-landingpage &,
        .search-results & {
            
            .content_section_inner {
                height:auto;
                min-height:0;
            }
            
            .overlay {
                display:none;
            }
            
            .inner_content {
                padding:5em 0 3em;
            }
            
            h1 {
                color:$gray;
            }
            
            .meta {
                color:$green;
    
                a {
                    color:$green;
                    text-decoration-color:rgba(125,156,145,0);
                    
                    &:hover {
                        color:$green;
                        text-decoration-color:$green;
                    }
                }
            }
        }
        
        .single-landingpage & {
            
            .inner_content {
                padding:5em 0 0;
            }
            
            h1 {
                margin:0;
            }
        }
    }
    
    .content_section_inner {
        position:relative; 
        padding:0;
        height:400px;
        min-height:400px;
        
        .single-post & {
            height:auto;
        }
    }
    
    .inner {
        display:flex;
        justify-content:flex-start;
        align-items:flex-end;
        height:100%;
    }
    
    .inner_content {
        flex:0 0 auto;
        padding:10em 0 2em;
        
        .single-post & {
            padding:15em 0 3em;
        }
    }
    
    .rider_wrap {
        position:absolute;
        right:0;
        top:260px;
        padding:0 0 0 20px;
        width:calc(33.3333% + ((100vw - 1420px) / 2));
        margin-right:calc(-1 * ((100vw - 1420px) / 2));
        
        img {
            display:block;
            max-width:100%;
        }
    }
    
    .eyebrow {
        display:block;
        margin:0 0 1em;
        color:$green;
        font-family:$roboto_cond;
        font-size:0.95em;
        text-transform:uppercase;
        -webkit-font-smoothing: auto;
    }
    
    h1 {
        
    }
    
    .overlay {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background: -moz-linear-gradient(bottom,  rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.25) 100%);
        background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.25) 100%);
        background: linear-gradient(to top,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.25) 100%);
        
        .single-post & {
            background: -moz-linear-gradient(bottom,  rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.25) 100%);
            background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0.25) 100%);
            background: linear-gradient(to top,  rgba(0,0,0,0.75) 0%,rgba(0,0,0,0.25) 100%);
        }
        
        .single-services & {
            background: -moz-linear-gradient(bottom,  rgba(0,0,0,0.90) 0%, rgba(0,0,0,0.25) 100%);
            background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0.90) 0%,rgba(0,0,0,0.25) 100%);
            background: linear-gradient(to top,  rgba(0,0,0,0.90) 0%,rgba(0,0,0,0.25) 100%);
        }
    }
    
    .meta {
        margin:0;
        
        a {
            color:$white;
            text-decoration:underline;
            text-decoration-color:rgba(255,255,255,0);
            text-underline-offset:0.3em;
            transition:all 0.3s;
            
            &:hover {
                color:$white;
                text-decoration-color:$white;
                text-underline-offset:0.15em;
            }
        }
    }
    
    .home & {
        
        &:before {
            display:none;
        }
        
        .content_section_inner {
            width:100%;
            max-width:none;
            height:auto;
            min-height:0;
            margin:0;
            padding:0;
            z-index:11;
        }
        
        .bg_img {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            
            img {
                object-fit:contain;
                min-width:100%;
                max-width:100%;
            }
        }
        
        .overlay {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            background: -moz-linear-gradient(bottom,  rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.15) 100%);
            background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.15) 100%);
            background: linear-gradient(to top,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.15) 100%);
        }
        
        .inner_content {
            position:relative;
            padding-top:15em;
            padding-bottom:6em;
            z-index:12;
        }
        
        .heading {
            font-family:$roboto_cond;
            font-size:2.89em;
            line-height:1.15em;
            color:$white;
            font-weight:$bold;
        }
        
        .cta_group {
            
            .btn {
                margin:0 20px 20px 0;
                text-transform:uppercase;
                font-family:$roboto_cond;
                
                &.trans {
                    text-transform:none;
                    font-weight:$normal;
                    color:$white !important;
                    
                    &:before {
                        filter:brightness(0) invert(1);
                    }
                }
            }
        }
    }
}

.hero_simple {
    
    .content_section_inner {
        
    }

    .eyebrow {
        display:block;
        margin:0 0 1em;
        color:$green;
        font-family:$roboto_cond;
        font-size:0.95em;
        text-transform:uppercase;
        -webkit-font-smoothing: auto;
    }
    
    &.project {
        background:$gray;
    
        .content_section_inner {
            position:relative;
            padding-top:120px;
            padding-bottom:0;
        }
        
        span {
            display:inline-block;
            vertical-align:middle;
        }
        
        .crumb {
            left:11.11%;
        }
        
        .project_meta {
            margin:0 0 0.75em;
            font-family: $roboto_cond;
            font-weight: $normal;
            font-size:1.1em;
            color: $green_on_slate;
            
            .client {
                margin-right:20px;
                font-weight: $bold;
            }
            
            .types {
                
                
                span {
                    
                }
            }
        }
        
        
        
        h1 {
            font-size:2.21em;
            color:$white;
        }
    }
    
    &.job {
    
        .content_section_inner {
            padding-bottom:30px;
        }
    }
    
    &.podcast {
        
        .content_section_inner {
            padding-bottom:30px;
        }
        
        .eyebrow {
            color:$green;
            font-size:1.16em;
            letter-spacing:2px;
            font-weight:$light;
            
            > span {
                font-weight:$bold;
            }
        }
        
        h1 {
            color:$green;
        }
    }
    
    &.ins_landing {
        background:$gray_mid;
        
        h1,
        .crumb {
            margin-left:-20px;
        }
        
        h1 {
            margin:0 0 0 -20px;
            color:$white;
        }
    }
    
    .single-staff & {
        
        .content_section_inner {
            padding:4em 0 0;
        }
        
        h1 {
            margin-bottom:0.25em;
        }
        
        .meta {
            color:$green;
            font-family:$roboto_cond;
            -webkit-font-smoothing: auto;
        }
    }
    
    .page-template-page-results & {
        background:$gray;
        
        h1 {
            color:$white;
        }
    }
}


@media screen and (max-width: #{$bp_small}) {
    
    #hero {
        
        .home & {
            .content_section_inner {
                padding:0 0 4em;
            }
        }
        
        &.pluggedin {
        
            .inner_content {
                padding:2em 0;
            }
            
            .cover {
                flex:0 0 40%;
            }
        
            .info {
                flex:0 0 60%;
                padding:0 0 0 50px;
            }
        
            .cur_ep {
                margin:1.5em 0 1em;
            
                span {
                    font-size:1.2em;
                
                    &.eyebrow {
                        font-size:0.84em;
                    }
                }
            }
        }
        
        &.is_tall {
        
            .content_section_inner {
                height:auto;
                min-height:0;
            }
        }
        
        iframe {
            height: 100%;
            min-height: 0;
        }
    
        .content_section_inner {
            height:auto;
            min-height:0;
            padding:0 20px;
        }
        
        .inner {
            width:100%;
        }
    
        .inner_content {
            flex:0 0 100%;
        }
    
        .rider_wrap {
            display:none;
        }
        
        
    }
    
    .hero_simple {
        
        .tax-project-type &,
        .tax-project-industry & {
            
            .crumb {
                left:20px;
            }
        }
        
        &.project {
            
            .crumb {
                left:20px;
            }
        }
        
        &.ins_landing {
                    
            h1,
            .crumb {
                margin-left:0;
            }
        
            h1 {
                margin:0;
            }
        }
        
        .single-staff & {
            
            .content_section_inner {
                padding:4em 20px 0;
            }
        }
    }
    
}

@media screen and (max-width: 540px) {
    
    #hero {
        
        &.pluggedin {
        
            .inner_content {
                padding:2em 50px;
            }
            
            .flex_wrap {
                display:block;
            }
            
            .info {
                padding:0;
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    #hero {
        
        &.pluggedin {
        
            .inner_content {
                padding:2em 0;
            }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #hero {
        
        .single-casestudy & {
        
            &.is_tall {
        
                .content_section_inner {
                    height:400px;
                    min-height:400px;
                }
            }
        }
    }
    
    .hero_simple {
        
        &.project {
            
            .crumb {
                left:0;
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    #hero {
        
        .home & {
            
            .cta_group {
            
                .btn {
                    margin-right:14px;
                    padding:14px 36px;
                    
                    &.arrowed.trans {
                        padding:14px 40px 14px 18px;
                    }
                    
                    &:last-of-type {
                        margin-right:0;
                    }
                }
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
    
    #hero {
        
        .home & {
            
            .inner_content {
                margin-left:28.333%;
                width:55%;
            }
        }
    }
    
}